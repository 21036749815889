import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { ListableActions, ListableState } from '@models/filter';
import { countPages, getPage, listPages, pagesListConfig } from '@apis/pages';
import { Page } from '@models/pages';

// TODO make current item part of listableState
type PagesState = ListableState<Page, typeof pagesListConfig> & {
  currentItem: Page | null;
};

const initialState: PagesState = {
  sortBy: 'relevanceScore',
  sortOrder: 'desc',
  filters: {
    optimizationStatus: 'all',
    status: 'all',
  },
  searchText: '',
  page: 0,
  items: null,
  totalItems: 0,
  currentItem: null,
};

type ListActions = ListableActions<typeof pagesListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      state.filters[payload.filter] = payload.value as any;
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
      state.currentItem = null;
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: Page[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters;
      caseReducers.resetResults(state);
    },
    updatePageDetails: (
      state,
      {
        payload,
      }: {
        payload: Pick<Page, 'pageId'> & Partial<Page>;
      },
    ) => {
      const page = state.items?.find(({ pageId }) => pageId === payload.pageId);
      if (page) Object.assign(page, payload);
    },
    setCurrentItem: (state, { payload }: { payload: Page | null }) => {
      state.currentItem = payload;
    },
  },
});

const thunks = {
  loadPage:
    (pageId: string): AppThunk =>
    async (dispatch) => {
      dispatch(RPages.setCurrentItem(null));
      const page = await getPage(pageId);
      dispatch(RPages.setCurrentItem(page));
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects({
  name: 'Fetch next pages',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
  ],
  execute: async ({ dispatch, state }) => {
    try {
      const pagesPromise = listPages(state.pages);
      const countPromise =
        state.pages.page === 0 ? countPages(state.pages) : null;

      const pages = await pagesPromise;
      dispatch(RPages.addItems(pages));

      if (countPromise) {
        const count = await countPromise;
        dispatch(RPages.setItemCount(count));
      }
    } catch {
      dispatch(RPages.setErrorState());
    }
  },
});

export const RPages = Object.assign(actions, thunks);

export default reducer;
