import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { ListableActions, ListableState } from '@models/filter';
import {
  createPageVersion,
  getPageVersion,
  listPageVersions,
  pageVersionsListConfig,
} from '@apis/pageVersions';
import { LegacyVersion, PageVersion } from '@models/productVersion';
import { RPages } from './pages';
import { getProductTemplate } from '@apis/productTemplate';
import { toast } from '@components/ToastNotification/ToastManager';
import { migrateTemplate } from '@utils/migrate';
import { RVersion } from './version';

type PageVersionState = ListableState<
  PageVersion,
  typeof pageVersionsListConfig
> & {
  currentItem: PageVersion | null;
};

const initialState: PageVersionState = {
  sortBy: 'updatedAt',
  sortOrder: 'desc',
  filters: {
    audienceType: undefined,
    published: undefined,
  },
  searchText: '',
  page: 0,
  items: null,
  totalItems: 0,
  currentItem: null,
};

type ListActions = ListableActions<typeof pageVersionsListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'pageVersions',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      state.filters[payload.filter] = payload.value as any;
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: PageVersion[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters;
      caseReducers.resetResults(state);
    },
    updatePageDetails: (
      state,
      {
        payload,
      }: {
        payload: Pick<PageVersion, 'id'> & Partial<PageVersion>;
      },
    ) => {
      const page = state.items?.find(({ id }) => id === payload.id);
      if (page) Object.assign(page, payload);
    },
    setCurrentItem: (state, { payload }: { payload: PageVersion | null }) => {
      state.currentItem = payload;
    },
    removeItem: (state, { payload }: { payload: PageVersion }) => {
      state.items = state.items?.filter(({ id }) => id !== payload.id) || null;
      if (state.currentItem?.id === payload.id) state.currentItem = null;
      state.totalItems--;
    },
  },
});

const thunks = {
  loadPageVersion:
    (pageId: string, versionId: string): AppThunk =>
    async (dispatch, getStore) => {
      const isCorrectPage = getStore().pages.currentItem?.pageId === pageId;
      dispatch(RPageVersions.setCurrentItem(null));
      if (!isCorrectPage) dispatch(RPages.loadPage(pageId));
      const pageVersion = await getPageVersion(pageId, versionId);
      dispatch(RPageVersions.setCurrentItem(pageVersion));
      dispatch(RVersion.setContent(pageVersion.attributes));
    },
  createPageVersion:
    ({
      pageId,
      version,
      successCallback,
      failureCallback,
    }: {
      pageId: string;
      version: LegacyVersion.UpdateBody;
      successCallback?: (version: PageVersion) => any;
      failureCallback?: (error: any) => any;
    }): AppThunk =>
    async (dispatch) => {
      try {
        const versionResult = await createPageVersion(pageId, version);
        dispatch(RPageVersions.setCurrentItem(versionResult));
        dispatch(
          RPages.updatePageDetails({
            pageId,
            updatedAt: new Date().toISOString(),
          }),
        );

        if (successCallback) successCallback(versionResult);
      } catch (errorObj: any) {
        if (failureCallback) failureCallback(errorObj);
      }
    },
  fetchPageTemplate:
    (pageId: string): AppThunk =>
    async (dispatch) => {
      try {
        const legacyTemplate = await getProductTemplate(pageId);
        const template = migrateTemplate.toUpdated(legacyTemplate);
        dispatch(RVersion.setTemplate(template));
      } catch (errorObj: any) {
        const { errors } = errorObj;
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects({
  name: 'Fetch next versions',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
    RPages.setCurrentItem,
  ],
  execute: async ({ dispatch, state }) => {
    if (!state.pages.currentItem)
      return void dispatch(RPageVersions.resetResults());

    try {
      const { items, count } = await listPageVersions(
        state.pages.currentItem.pageId,
        state.pageVersions,
      );
      dispatch(RPageVersions.addItems(items));
      dispatch(RPageVersions.setItemCount(count));
    } catch {
      dispatch(RPageVersions.setErrorState());
    }
  },
});

export const RPageVersions = Object.assign(actions, thunks);

export default reducer;
