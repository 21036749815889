import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DigitalAssetBrief } from '@models/digital-asset';
import { AllFilesType } from '@models/files';
import { ModalProps } from '@models/modal';
import { RModal } from '@redux/slices/modal';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { ALL_TYPES_TO_EXTENSIONS_MAP } from '@utils/files';

import DigitalAssetCard from '../../pages/digital-assets/+page/DigitalAssetCard';
import useLegacyModal from '../ModalLayout/useModal';
import FileUploadModal from '../Modals/FileUploadModal';
import HeaderFooterModalLayout from '../Modals/HeaderFooterModalLayout';
import ListingCard from '../ListingCard';
import LoadingCard from '../LoadingCard';
import UpdatedInfiniteScrolling from '../InfiniteScrolling/UpdatedInfiniteScrolling';
import PrimaryButton from '../PrimaryButton';
import SearchBar from '../SearchBar';
import FilterSidebar from '@components/FilterSidebar';
import { damListConfig } from '@apis/digitalAssets';
import { RAsset } from '@redux/slices/asset';
import { useDeepCompareMemoize } from '../../hooks/useDeepMemoize';

import './style.scss';

/**
 * Interface Prop type for AssetPreviewModal
 * @author Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 *
 * @interface
 */
interface DigitalAssetsModalProps extends ModalProps {
  allowedFiles: AllFilesType[];
  maxFiles: number;
  setFinalFiles: (assets: DeepReadonly<DigitalAssetBrief[]>) => void;
}

const DigitalAssetsModal: React.FC<DigitalAssetsModalProps> = ({
  showModal,
  setShowModal,
  onClose,
  allowedFiles,
  maxFiles,
  setFinalFiles,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState) => rootState.assets);
  const { openModal, closeModal, showModalId } = useLegacyModal();

  useEffect(() => {
    if (!showModal || showModalId !== 'dam-modal')
      dispatch(RAsset.resetResults());
  }, [showModal, showModalId]);

  const stableAllowedFiles = useDeepCompareMemoize(allowedFiles);

  useEffect(() => {
    dispatch(
      RAsset.updateFilter({
        filter: 'fileType',
        value: allowedFiles.map((v) => ALL_TYPES_TO_EXTENSIONS_MAP[v]),
      }),
    );
  }, [stableAllowedFiles]);

  const fileUploadModal = {
    modalId: 'fileuploadModalId',
    openModal: () => {
      openModal('fileuploadModalId');
    },
  };

  const onModalClose = () => {
    dispatch(RAsset.resetResults());
    onClose();
  };

  const onPressDone = () => {
    setFinalFiles(
      Object.values(state.selectedAssets).sort(
        (a, b) => a.selectionOrder - b.selectionOrder,
      ),
    );
    onModalClose();
  };

  const handleNext = () => {
    dispatch(RAsset.incrementPage());
  };

  const handleCardClick = (asset: DeepReadonly<DigitalAssetBrief>) => {
    if (
      state.selectedAssets[asset.id] ||
      Object.keys(state.selectedAssets).length < maxFiles
    ) {
      dispatch(RAsset.toggleSelected(asset));
    } else {
      dispatch(
        RModal.open({
          type: 'typeA',
          heading: t('global.limitExceeded'),
          supportingText: `${capitalizeFirstLetter(
            t('digitalAssets.canNotSelectMoreImagesStmt1', {
              count: maxFiles,
              image:
                maxFiles > 1 ? t('global.image_plural') : t('global.image'),
            }),
          )} ${capitalizeFirstLetter(
            t('digitalAssets.canNotSelectMoreImagesStmt2'),
          )}`,
        }),
      );
    }
  };

  return (
    <>
      <HeaderFooterModalLayout
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onModalClose}
        header={
          <header className="digitalAssetsModal__header">
            <h2 className="digitalAssetsModal__header--title">
              {capitalizeFirstLetter(t('digitalAssets.title'))}
            </h2>
          </header>
        }
        body={
          <div className="digitalAssetsModal__main">
            <FilterSidebar
              filterNamespace={'digitalAssets'}
              listableConfiguration={damListConfig}
              listableState={state}
              listableAction={RAsset}
              fixedFilters={['fileType']}
            />

            <div
              className="digitalAssetsModal__container"
              id="damScrollableDiv"
            >
              <div className="digitalAssetsModal__container--searchBar">
                <SearchBar
                  id="modal-digital-asset-search-bar"
                  value={state.searchText}
                  placeholder={`${capitalizeFirstLetter(
                    t('digitalAssets.searchPlaceholder'),
                  )}...`}
                  enterkeyHandler={() => {}}
                  updateSearch={(value) =>
                    dispatch(RAsset.setSearchText(value))
                  }
                  onClearHandler={() => dispatch(RAsset.setSearchText(''))}
                  count={state.totalItems}
                  showShowingResult
                  title={t('global.results')}
                />
              </div>
              <UpdatedInfiniteScrolling
                className="digitalAssetsModal__card-list"
                dataLength={state.items?.length ?? 0}
                endMessage={t('digitalAssets.noMoreDigitalAssets')}
                hasMore={!!state.items && state.items.length < state.totalItems}
                loader={<LoadingCard />}
                scrollableTarget={'damScrollableDiv'}
                isLoading={
                  !state.items || state.totalItems > state.items.length
                }
                numberOfLoaders={3}
                next={handleNext}
              >
                <>
                  <ListingCard
                    id="modal-digital-asset-create-card"
                    isCreateCard
                    module="digitalAsset"
                    createButtonText={t('digitalAssets.createNew')}
                    onCreateClick={fileUploadModal.openModal}
                  />
                  {state.items?.map((asset) => (
                    <DigitalAssetCard
                      id={`modal-digital-asset-card-${asset.id}`}
                      key={asset.id}
                      asset={asset}
                      isSelected={!!state.selectedAssets[asset.id]}
                      showDeleteIcon
                      showEditOption
                      disableRedirect
                      onChange={() => handleCardClick(asset)}
                    />
                  ))}
                </>
              </UpdatedInfiniteScrolling>
            </div>
          </div>
        }
        footer={
          <footer className="digitalAssetsModal__footer">
            <PrimaryButton
              disabled={Object.keys(state.selectedAssets).length === 0}
              label={capitalizeFirstLetter(t('global.done'))}
              onClick={
                Object.keys(state.selectedAssets).length === 0
                  ? () => null
                  : onPressDone
              }
              variant="green"
              size="small"
            />
          </footer>
        }
      />

      <FileUploadModal
        showModal={fileUploadModal.modalId === showModalId}
        setShowModal={closeModal}
        onClose={closeModal}
        allowedFiles={allowedFiles}
        onUpload={() => dispatch(RAsset.resetResults())}
        sendConfig
      />
    </>
  );
};

export default DigitalAssetsModal;
