import React from 'react';

import './style.scss';

/**
 * Component to display checkbox with label, takes all default checkbox props
 *
 * @author Ritam Bandyopdahyay <ritam.bandyopadhyay@corevaluetech.com>
 * @component
 * @param {string} [label]
 * @param [field]
 * @param [form]
 * @param {"primary" | "light"} [color = "primary"]
 */

interface ICheckBox extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  field?: any;
  form?: any;
  color?: 'primary' | 'light';
  checked?: boolean;
  partiallyChecked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showReverse?: boolean;
}

const CheckBox: React.FC<ICheckBox> = ({
  className,
  disabled,
  readOnly,
  id,
  value,
  color = 'primary',
  field,
  label,
  checked,
  partiallyChecked = false,
  showReverse = false,
  onChange,
}) => {
  return (
    <div
      className={`CheckBox ${className || ''} ${showReverse ? 'reverse' : ''}`}
    >
      <input
        {...field}
        type="checkbox"
        className={`CheckBox__input CheckBox__input--${color} ${
          partiallyChecked ? 'partiallyChecked' : ''
        } ${disabled ? 'disabled' : ''}`}
        id={id}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label && (
        <label htmlFor={id} className="CheckBox__label">
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
