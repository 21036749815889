import React from 'react';

export const LeftArrow = ({
  height = '16px',
  width = '16px',
  fill = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9999 8.00028C13.9999 8.13289 13.9472 8.26006 13.8535 8.35383C13.7597 8.4476 13.6325 8.50028 13.4999 8.50028H3.70678L7.35366 12.1465C7.40011 12.193 7.43696 12.2481 7.4621 12.3088C7.48724 12.3695 7.50018 12.4346 7.50018 12.5003C7.50018 12.566 7.48724 12.631 7.4621 12.6917C7.43696 12.7524 7.40011 12.8076 7.35366 12.854C7.3072 12.9005 7.25205 12.9373 7.19135 12.9625C7.13066 12.9876 7.0656 13.0006 6.99991 13.0006C6.93421 13.0006 6.86915 12.9876 6.80846 12.9625C6.74776 12.9373 6.69261 12.9005 6.64615 12.854L2.14615 8.35403C2.09967 8.30759 2.06279 8.25245 2.03763 8.19175C2.01246 8.13105 1.99951 8.06599 1.99951 8.00028C1.99951 7.93457 2.01246 7.86951 2.03763 7.80881C2.06279 7.74811 2.09967 7.69296 2.14615 7.64653L6.64615 3.14653C6.73997 3.05271 6.86722 3 6.99991 3C7.13259 3 7.25984 3.05271 7.35366 3.14653C7.44748 3.24035 7.50018 3.3676 7.50018 3.50028C7.50018 3.63296 7.44748 3.76021 7.35366 3.85403L3.70678 7.50028H13.4999C13.6325 7.50028 13.7597 7.55296 13.8535 7.64672C13.9472 7.74049 13.9999 7.86767 13.9999 8.00028Z"
      fill="white"
    />
  </svg>
);
