import ReactDOM from 'react-dom';
import { ToastNotification, ToastNotificationProps } from './index';

// Using this Toast Manager we can invoke and destroy toast in any component
// How to use Toast ?
/**
 *  toast.show({
        message: t('test.text'),
        position: "bottom-center"
    })
    toast.destroy()  <---- Mandatory to use
 */

export class ToastManager {
  // private containerRef: HTMLDivElement;
  private toasts: ToastNotificationProps[] = [];
  //? Element is placed in Parent
  // constructor() {
  // const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
  // const toastContainer = document.createElement("div") as HTMLDivElement;
  // body.insertAdjacentElement("beforeend", toastContainer);
  // this.containerRef = document.getElementById('toast-container-div') as HTMLDivElement
  // }

  /**
   * show
   */
  public show(options: ToastNotificationProps): void {
    const toastId = Math.random()
      .toString(36)
      .substr(
        2,
        9,
      ); /* Using Id to create difference between toast message other wise React Dom will not re-render next toast*/
    const toast: ToastNotificationProps = {
      id: toastId,
      ...options,
    };
    this.toasts = [toast, ...this.toasts];
    this.render();
  }
  /**
   * rendering multiple Notification props if given
   */
  public render(): void {
    const toastList = this.toasts.map((toastProps: ToastNotificationProps) => (
      <ToastNotification
        message={toastProps.message}
        position={toastProps.position}
        key={toastProps.id}
        error={toastProps.error}
      />
    ));
    ReactDOM.render(toastList, document.getElementById('toast-container-div'));
  }

  /**
   * destroy
   */
  public destroy(): void {
    this.toasts = [];
  }
}

export const toast = new ToastManager();
