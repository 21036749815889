import React, { Suspense, useLayoutEffect, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { RCurrentUser } from '@redux/slices/currentUser';
import { RTenant } from '@redux/slices/tenant';
import { RQuickStartGuide } from '@redux/slices/quickStartGuide';

import { ROUTES as ROUTES_LEGACY } from '@constants/routes';

import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { setUpForbiddenInterceptor } from '../apis';

import Loading from '@components/Loading';
import ApplicationLayout from '../layouts/ApplicationLayout';

import { ROUTES } from './routes';
import { RKeyCloak } from '@redux/slices/keyCloak';
import TestFlow from './test-flow/+page';

function InjectForbiddenAccessInterceptor() {
  const history = useHistory();

  useEffect(() => {
    setUpForbiddenInterceptor(history);
  }, [history]);

  return null;
}

interface ApplicationRouterProps {
  realm: string;
  clientId: string;
}

const ApplicationRouter: React.FC<ApplicationRouterProps> = ({
  realm,
  clientId,
}) => {
  const state = useSelector((state) => state.keyCloak);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { onboarded } = useSelector((state) => state.tenant);

  useLayoutEffect(() => {
    const url = process.env.REACT_APP_KC_SERVER_URL;
    if (realm !== '' && clientId !== '') {
      const keycloak = Keycloak({
        clientId,
        realm,
        url: url,
      });

      keycloak
        .init({ onLoad: 'login-required', checkLoginIframe: false })
        .then((authenticated) => {
          dispatch(RKeyCloak.set(keycloak));
          if (authenticated) {
            dispatch(RKeyCloak.setToken(keycloak.token!));
            dispatch(RCurrentUser.fetchUserOnBoardStatusSaga());
            dispatch(RQuickStartGuide.fetchGuideStatusSaga());
            dispatch(RCurrentUser.getUserSaga());
            dispatch(RTenant.fetchTenantLogoSaga());
            dispatch(RTenant.fetchTenantCurrencyAndOnboardDateSaga());
            dispatch(RTenant.fetchGalleryStatus());
            if (onboarded === 'unknown')
              dispatch(RTenant.checkOnboardingComplete());
            setIsAuthenticated(authenticated);
          }
        });

      keycloak.onTokenExpired = () => {
        keycloak
          .updateToken(50)
          .then((refreshed) =>
            console.log((refreshed ? '' : 'not ') + 'refreshed ' + new Date()),
          )
          .catch(() => console.error('Failed to refresh token ' + new Date()));
      };
    }
  }, [realm]);

  const showOnboardingFlow = onboarded === false;

  if (!state.keyCloak) return <Loading />;
  else if (!isAuthenticated)
    return (
      <h1>{capitalizeEveryFirstLetter(t('global.unable_to_authenticate'))}</h1>
    );
  else
    return (
      <BrowserRouter>
        <InjectForbiddenAccessInterceptor />
        <Route
          render={(props) => (
            <ApplicationLayout
              {...props}
              showSidebar={
                props.location.pathname !== '/404' && !showOnboardingFlow
              }
            >
              <Switch>
                {showOnboardingFlow ? (
                  <TestFlow />
                ) : (
                  ROUTES.map(({ path, Component }) => (
                    <Route key={path} path={path} exact>
                      <Suspense fallback={<Loading />}>
                        <Component />
                      </Suspense>
                    </Route>
                  ))
                )}
                <Route path="/" exact>
                  <Redirect to={ROUTES_LEGACY.dashboard.base} />
                </Route>
                <Route path="*" exact>
                  <Redirect to="/404" />
                </Route>
              </Switch>
            </ApplicationLayout>
          )}
        />
      </BrowserRouter>
    );
};

export default ApplicationRouter;
