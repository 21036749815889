import React from 'react';

export const TranscriptIcon = ({
  height = '24px',
  width = '24px',
  color = '#54C1AC',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66667 1.33325C7.65 1.33325 6.01833 2.98325 6.01833 4.99992L6 34.3333C6 36.3499 7.63167 37.9999 9.64833 37.9999H31.6667C33.6833 37.9999 35.3333 36.3499 35.3333 34.3333V12.3333L24.3333 1.33325H9.66667ZM22.5 14.1666V4.08325L32.5833 14.1666H22.5Z"
      fill={color}
    />
    <path
      d="M16.1196 21.3369H17.446C17.4268 20.1704 16.468 19.365 15.0106 19.365C13.5756 19.365 12.5241 20.1576 12.5305 21.3465C12.5273 22.3117 13.2081 22.8646 14.3139 23.1299L15.0266 23.3089C15.7393 23.4815 16.1356 23.686 16.1388 24.1271C16.1356 24.6065 15.6818 24.9325 14.9786 24.9325C14.2595 24.9325 13.7418 24.6001 13.697 23.9449H12.3579C12.3931 25.3607 13.4062 26.0926 14.9946 26.0926C16.5926 26.0926 17.5323 25.3288 17.5355 24.1303C17.5323 23.0404 16.7109 22.4619 15.5731 22.2063L14.985 22.0656C14.4161 21.9346 13.9399 21.7237 13.9495 21.2538C13.9495 20.832 14.3235 20.522 15.001 20.522C15.6626 20.522 16.0685 20.8224 16.1196 21.3369Z"
      fill="white"
    />
    <path
      d="M18.4663 25.9999H19.8502V23.6796H20.8601L22.1002 25.9999H23.6279L22.2376 23.4559C22.9823 23.1363 23.3946 22.4875 23.3946 21.5926C23.3946 20.2918 22.5348 19.4545 21.0487 19.4545H18.4663V25.9999ZM19.8502 22.5674V20.5859H20.7834C21.5824 20.5859 21.9691 20.9406 21.9691 21.5926C21.9691 22.2414 21.5824 22.5674 20.7898 22.5674H19.8502Z"
      fill="white"
    />
    <path
      d="M24.1233 20.5955H26.1272V25.9999H27.4951V20.5955H29.499V19.4545H24.1233V20.5955Z"
      fill="white"
    />
  </svg>
);
