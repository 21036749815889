import { isAssetVideo } from '@utils/digitalAssets';
import { AssetCache } from '..';
import fallbackImage from '@assets/illustrations/57_NoImage.svg';
import { HTMLAttributes } from 'react';

export const Asset = ({
  assetId,
  assetCache,
  ...other
}: {
  assetId: string | undefined;
  assetCache: AssetCache;
} & HTMLAttributes<HTMLElement>) => {
  // Loading state
  if (!assetId) return <img />;
  const asset = assetCache[assetId];
  if (!asset) return <img />;
  // Rendering state
  if (isAssetVideo(asset)) return <video src={asset.url} {...other} />;
  else
    return (
      <img src={asset?.url || fallbackImage} alt={asset?.altText} {...other} />
    );
};
