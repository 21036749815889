import React from 'react';
import { useTranslation } from 'react-i18next';

import { UrlIcon } from '@components/Icons/UrlIcon';
import SectionHeader from '../RichContentSectionHeader';
import SectionFieldsFactory from '../../[id]/template/SectionFieldsFactory';

import { IRichContentSection } from '@models/rich-content';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import './style.scss';

const RichContentURL: React.FC<IRichContentSection> = ({
  index,
  length,
  moveSection,
  permissions,
  attributes,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`RichContentURL ${
          permissions.AUTHOR_CONTENT ? '' : 'RichContentTemplate__readOnly'
        }`}
      >
        <SectionHeader
          title={t('global.url')}
          moveSection={moveSection}
          showDropdown={permissions.AUTHOR_CONTENT}
          index={index}
          length={length}
          icon={<UrlIcon fill="var(--emerald)" />}
        />
        <div className="RichContentURL__container">
          <div className="RichContentURL__container--html">
            <SectionFieldsFactory
              id={`url${index}`}
              key={'url'}
              attributeKey={'url'}
              sectionOrder={index}
              attribute={attributes['url']}
              permissions={permissions}
              handleChange={handleChange}
              characterLimits={500}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(RichContentURL);
