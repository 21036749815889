import './StatusTag.scss';

interface IStatusTag {
  id?: string;
  backgroundColor?:
    | 'green'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'gray'
    | 'blue'
    | string;
  className?: string;
  filled?: boolean;
  label: string;
  showDot?: boolean;
  variant?: 'rectangular' | 'rounded';
  isTextUppercase?: boolean;
}

const StatusTag = ({
  id,
  backgroundColor = 'gray',
  filled = false,
  className = '',
  label,
  showDot = false,
  variant = 'rectangular',
  isTextUppercase = true,
}: IStatusTag) => {
  return (
    <div
      id={id}
      className={`StatusTag ${`StatusTag-${variant}`} ${`StatusTag-${backgroundColor}`} ${`StatusTag-${
        filled ? 'filled' : ''
      }`} ${className}`}
    >
      {showDot && <div className="StatusTag-dot" />}
      <span
        className={`StatusTag-label ${
          isTextUppercase ? 'text-uppercase' : 'text-capitalize'
        }`}
      >
        {label}
      </span>
    </div>
  );
};

export default StatusTag;
