import { AIGeneration } from '@apis/aiService';
import {
  createNewProductVersion,
  patchProductVersion,
  createUpdateProductVersion,
} from '@apis/productVersions';
import ContentEditor from '@components/ContentEditor';
import { CreatingTestIcon } from '@components/Icons/CreatingTestIcon';
import PrimaryButton from '@components/PrimaryButton';
import { toast } from '@components/ToastNotification/ToastManager';
import {
  CREATE_VERSION_STEP,
  CREATION_TYPE,
  DUE_TYPE,
  VersionTargetingOption,
} from '@constants/productVersion';
import { ROUTES } from '@constants/routes';
import { Experience } from '@models/experience';
import { Template } from '@models/productTemplate';
import { RSpinner } from '@redux/slices/spinner';
import { toContentList } from '@utils/productTemplate';
import { showError } from '@utils/productVersion';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { publishProductVerison } from '@apis/productVersions';
import { RTenant } from '@redux/slices/tenant';
import { migrateProduct } from '@utils/migrate';
import { getAsset } from '@apis/digitalAssets';
import store from '@redux/store';

import './style.scss';
import { RVersion } from '@redux/slices/version';

const defaultTemplate: Template = {
  attributes: [],
  createdAt: Date.now(),
  createdBy: '',
  updatedAt: Date.now(),
  updatedBy: '',
  id: '',
  tenantId: '',
};

const VersionView = ({
  experience,
  version,
  template,
}: {
  experience: Experience;
  version: AIGeneration | undefined;
  template: Template | undefined;
}) => {
  const { t } = useTranslation();
  const { product, variant } = useSelector((state) => {
    const p = state.productVersion.baseProduct;
    return p ? migrateProduct.toUpdated(p) : { product: null, variant: null };
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (version) dispatch(RVersion.setContent(version));
  }, [version]);

  if (!template) template = defaultTemplate;

  const saveVersion = async (andPublish: boolean) => {
    const { content } = store.getState().version;
    try {
      if (!(product && template && content)) return;
      dispatch(RSpinner.show(true));

      const { productId } = product;

      const contentList = await toContentList(content, {}, template, variant);

      const thumbnailId =
        Array.isArray(content.assets) && content.assets.length > 0
          ? content.assets[0]
          : variant.attributes.assets?.at(0);
      const thumbnail = thumbnailId
        ? await getAsset(thumbnailId).then((r) => r.url)
        : null;

      const contentReference = `xp::${experience.id};`;

      const response = await createNewProductVersion(
        productId,
        CREATION_TYPE.next,
        {
          title: experience.title,
          audienceType: VersionTargetingOption.anonymous,
          contextIds: [],
          experimentIds: [],
          isTestVersion: true,
          completedSteps: CREATE_VERSION_STEP.overview,
          controlGroupValue: 0,
          workflowEnabled: false,
          description: experience.description || void 0,
          dueType: DUE_TYPE.asap,
          contentReference,
          taskList: [],
          highPriority: false,
        },
      ).catch(({ errors }) => {
        toast.show({
          error: true,
          message: errors?.[0]?.message || 'Error creating version',
        });
        dispatch(RSpinner.show(false));
        return null;
      });
      if (response === null) return;

      const patch = await patchProductVersion(
        productId,
        response.id,
        CREATION_TYPE.next,
        {
          title: response.title,
          description: response.description,
          audienceType: VersionTargetingOption.anonymous,
          contextIds: [],
          experimentIds: [],
          isTestVersion: true,
          dueType: DUE_TYPE.asap,
          controlGroupValue: 0,
          completedSteps: CREATE_VERSION_STEP.publishing,
          contentReference,
          thumbnail,
          workflowEnabled: false,
          taskList: [],
          highPriority: false,
        },
      );

      if (!patch.isSuccess) {
        dispatch(RSpinner.show(false));
        return toast.show({
          error: true,
          message: "Couldn't update product version",
        });
      }

      await createUpdateProductVersion(
        product.productId,
        patch.data.id,
        CREATION_TYPE.saveAndExit,
        {
          title: patch.data.title,
          description: '',
          publishDate: null,
          expiryDate: null,
          dueType: DUE_TYPE.asap,
          audienceType: VersionTargetingOption.anonymous,
          controlGroupValue: 0,
          contextIds: [],
          experimentIds: [],
          published: false,
          contentList,
          taskList: [],
          contentReference,
          isTestVersion: true,
          isActive: true,
          completedSteps: CREATE_VERSION_STEP.content,
          workflowEnabled: false,
          highPriority: false,
          thumbnail,
        },
      ).catch(() => {
        showError(t('strategyBoard.unable_to_create_test_version'));
        history.push(ROUTES.products.base);
      });

      if (andPublish) {
        await publishProductVerison({
          productId: productId,
          newVersionId: patch.data.id,
          eventType: 'NEW_MAKEUP',
          contentReference: contentReference,
          noSuccessToast: true,
        });
      }

      dispatch(RVersion.setContentChanged(false));
      dispatch(RTenant.setOnboarded(true));
      setTimeout(() => {
        history.push(`${ROUTES.products.base}/${productId}`);
      }, 500);
    } catch (error) {
      console.error(error);
    }
    dispatch(RSpinner.show(false));
  };

  return (
    <div className="version-view">
      <div className={'content-editor' + (version ? '' : ' disabled')}>
        {
          <ContentEditor
            base={product}
            variant={variant}
            experience={experience}
            template={template}
          />
        }
      </div>
      {!version && (
        <div className="overlay">
          <div className="inner">
            <h1>
              {capitalizeEveryFirstLetter(
                t('testFlow.step2.creatingTests.title'),
              )}
            </h1>
            <p>
              {capitalizeFirstLetter(
                t('testFlow.step2.creatingTests.description'),
              )}
            </p>
            <CreatingTestIcon />
          </div>
        </div>
      )}
      {version && (
        <footer className="action-bar">
          <PrimaryButton
            label={capitalizeEveryFirstLetter(t('global.publish'))}
            onClick={() => saveVersion(true)}
          />
          <PrimaryButton
            label={capitalizeFirstLetter(t('testFlow.step2.saveAsDraft'))}
            onClick={() => saveVersion(false)}
            variant="gray"
          />
        </footer>
      )}
    </div>
  );
};

export default VersionView;
