import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { ListableActions, ListableState } from '@models/filter';
import { Experiment } from '@models/experiment';
import { listExperiments, experimentListConfig } from '@apis/experiments';

type ExperimentState = ListableState<
  Experiment,
  typeof experimentListConfig
> & {
  selectedItems: {
    [key: string]: Experiment;
  };
  selectedItem: Experiment | null;
  showBottomMenu: boolean;
};

const initialState: DeepReadonly<ExperimentState> = {
  items: null,
  selectedItems: {},
  selectedItem: null,
  showBottomMenu: false,
  page: 0,
  totalItems: 0,
  sortBy: 'name',
  sortOrder: 'asc',
  searchText: '',
  filters: {},
};

type ListActions = ListableActions<typeof experimentListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      { payload }: { payload: Parameters<ListActions['updateFilter']>[0] },
    ) => {
      state.filters[payload.filter] = payload.value as never;
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
      state.selectedItems = {};
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: Experiment[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    removeItems: (
      state,
      { payload }: { payload: DeepReadonly<Experiment[]> },
    ) => {
      const { items } = state;
      if (!items) return;
      for (const item of payload) {
        const index = items.findIndex(({ id }) => id === item.id);
        if (index !== -1) items.splice(index, 1);
      }
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters as DeepWritable<
        typeof initialState.filters
      >;
      caseReducers.resetResults(state);
    },
    toggleSelected: (
      state,
      { payload }: { payload: DeepReadonly<Experiment> },
    ) => {
      const { selectedItems } = state;
      if (selectedItems[payload.id]) delete selectedItems[payload.id];
      // Assert mutable as it is likely from our state and we're currently in a writable mode
      else selectedItems[payload.id] = payload as DeepWritable<typeof payload>;
    },
    resetSelected: (state) => {
      state.selectedItems = {};
    },
    setCurrent: (state, { payload }: { payload: Experiment | null }) => {
      state.selectedItem = payload;
    },
    updateCurrent: (state, { payload }: { payload: Partial<Experiment> }) => {
      if (!state.selectedItem)
        throw new Error('Cannot update experiment if none selected');
      state.selectedItem = {
        ...state.selectedItem,
        ...payload,
      };
    },
    setBottomMenuVisible: (state, { payload }: { payload: boolean }) => {
      state.showBottomMenu = payload;
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

const currentFetch: {
  id: number;
  type: (typeof actions)[keyof typeof actions]['type'];
} = { id: 0, type: actions.incrementPage.type };

registerSideEffects({
  name: 'Fetch next version tags',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
    actions.removeItems,
  ],
  execute: async ({ dispatch, state, action }) => {
    try {
      const thisFetch = (currentFetch.id += 1);
      currentFetch.type =
        action.type as (typeof actions)[keyof typeof actions]['type'];

      const { items, totalCount } = await listExperiments(state.experiments);

      // A new request was sent so abort
      if (
        thisFetch !== currentFetch.id &&
        currentFetch.type !== actions.incrementPage.type
      )
        return;

      // If it's a refetch because experiments were removed, deduplicate
      if (action.type === 'experiments/removeItems') {
        const updated = items.filter(
          // Only keep results which are not already in items
          (a1) => !state.experiments.items?.find((a2) => a2.id === a1.id),
        );
        items.splice(0, items.length, ...updated);
      }
      dispatch(RExperiment.addItems(items));
      dispatch(RExperiment.setItemCount(totalCount));
    } catch (e) {
      dispatch(RExperiment.setErrorState());
    }
  },
});

export const RExperiment = Object.assign(actions, thunks);

export default reducer;
