import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';

import { ModalProps } from '@models/modal';
import { DigitalAssetBrief } from '@models/digital-asset';
import { downloadAsset } from '@utils/digitalAssets';

import HeaderFooterModalLayout from '../Modals/HeaderFooterModalLayout';
import PrimaryButton from '../PrimaryButton';

import { ReactComponent as LeftChevronIcon } from '@assets/icons/left-chevron-big.svg';
import { ReactComponent as RightChevronIcon } from '@assets/icons/right-chevron-big.svg';
import { ReactComponent as ZoomInIcon } from '@assets/icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from '@assets/icons/zoom-out.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/download.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-pen-white.svg';

import './style.scss';

/**
 * Displays media in HTML based on media type.
 * @author Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 *
 * @component
 * @param {DigitalAsset} asset render markup with respect to asset type.
 * @param {Boolean} zoomInAndOut enable zoom in or zoom out
 */
const AssetPreview: React.FC<{
  asset: DeepReadonly<DigitalAssetBrief>;
  zoomInAndOut?: boolean;
  zoom: number;
  setZoom: (zoom: number) => void;
}> = ({ asset, zoomInAndOut, zoom, setZoom }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  switch (asset.format) {
    case 'mp4':
    case 'mov':
    case 'mvp':
      return <video src={asset.url} className="assetPreview" controls></video>;

    case 'pdf':
      return (
        <object
          data={asset.url}
          type="application/pdf"
          className="assetPreview"
        ></object>
      );

    default:
      return zoomInAndOut ? (
        <Cropper
          crop={crop}
          onCropChange={setCrop}
          image={asset.url}
          zoom={zoom}
          onZoomChange={setZoom}
          showGrid={false}
          aspect={1 / 1}
        />
      ) : (
        <img src={asset.url} alt={asset.title} className="assetPreview" />
      );
  }
};

/**
 * Interface Prop type for AssetPreviewModal
 * @author Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 *
 * @interface
 */
interface AssetPreviewModalProps extends ModalProps {
  assets: DeepReadonly<DigitalAssetBrief[]>;
  callToAction?: {
    text: string;
    callback: Function;
  };
  zoomInAndOut?: boolean;
  download?: boolean;
  showAssetIndex?: boolean;
  hasUpdatePermission?: boolean;
  edit?: () => void;
}

/**
 * Renders HeaderFooterModalLayout with slider.
 * @author Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 *
 * @component
 * @param {DigitalAssetBrief[]} assets list of all digital assets to display.
 * @param showModal boolean to open modal for ModalLayout.
 * @param setShowModal function to open modal for ModalLayout.
 * @param portalId protal id to open modal for ModalLayout.
 * @param onClose function to close modal for ModalLayout.
 * @param callToAction object containing title and onClick action of CTA button.
 * @param zoomInAndOut boolean value to render zoom in and zoom out buttons.
 * @param edit boolean value to render edit button.
 * @param download boolean value to render download button.
 * @param showAssetIndex boolean value to render asset index.
 */
const AssetPreviewModal: React.FC<AssetPreviewModalProps> = ({
  assets,
  showModal,
  setShowModal,
  onClose,
  callToAction,
  zoomInAndOut,
  download,
  showAssetIndex,
  hasUpdatePermission = true,
  edit,
}) => {
  const [assetIndex, setAssetIndex] = useState<number>(0);
  const previewDiv = useRef<any>(null);
  const [translateX, setTranslateX] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);

  const zoomIn = () => {
    if (zoom < 8) {
      setZoom((z) => z * 2);
    }
  };

  const zoomOut = () => {
    if (zoom > 1) {
      setZoom((z) => z / 2);
    }
  };

  const onModalClose = () => {
    setAssetIndex(0);
    setTranslateX(0);
    onClose();
  };

  const handleDownloadIconClick = () => {
    const currentAsset = assets[assetIndex];
    downloadAsset(currentAsset.url, currentAsset.fileName, currentAsset.format);
  };

  useEffect(() => {
    if (previewDiv && previewDiv.current) {
      setTranslateX(assetIndex * previewDiv.current?.offsetWidth);
    }
  }, [previewDiv, assetIndex]);

  return (
    <HeaderFooterModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={onModalClose}
      isFullScreen
      header={
        <div className="assetPreviewModal__header">
          {assets[assetIndex]?.title}
        </div>
      }
      body={
        <div className="assetPreviewModal">
          {assets.length > 1 && (
            <>
              {assetIndex > 0 && (
                <button
                  className="assetPreviewModal__button assetPreviewModal__button--prev"
                  onClick={() =>
                    setAssetIndex((prev) => (prev === 0 ? prev : prev - 1))
                  }
                >
                  <LeftChevronIcon fill="var(--manatte-shade-5)" />
                </button>
              )}
              {assetIndex < assets.length - 1 && (
                <button
                  className="assetPreviewModal__button assetPreviewModal__button--next"
                  onClick={() =>
                    setAssetIndex((prev) =>
                      prev === assets.length - 1 ? prev : prev + 1,
                    )
                  }
                >
                  <RightChevronIcon fill="var(--manatte-shade-5)" />
                </button>
              )}
            </>
          )}

          <div
            className="assetPreviewModal__preview"
            ref={previewDiv}
            style={{
              transform: `translateX(-${translateX}px)`,
            }}
          >
            {assets.map((asset) => (
              <div
                className="assetPreviewModal__preview--container"
                key={asset.id}
              >
                <AssetPreview
                  asset={asset}
                  zoomInAndOut={zoomInAndOut}
                  zoom={zoom}
                  setZoom={setZoom}
                />
              </div>
            ))}
          </div>
        </div>
      }
      footer={
        <div className="assetPreviewModal__footer">
          <div className="assetPreviewModal__footer--cta">
            {hasUpdatePermission && callToAction && (
              <PrimaryButton
                label={callToAction.text}
                onClick={() => {
                  callToAction.callback();
                }}
              />
            )}
          </div>
          <div className="assetPreviewModal__footer--index-count">
            {showAssetIndex && (
              <>
                Asset {assetIndex + 1} of {assets.length}
              </>
            )}
          </div>
          <div className="assetPreviewModal__footer--actions">
            {zoomInAndOut && (
              <>
                <button
                  className="assetPreviewModal__footer--action-button"
                  onClick={zoomIn}
                >
                  <ZoomInIcon />
                </button>
                <button
                  className="assetPreviewModal__footer--action-button"
                  onClick={zoomOut}
                >
                  <ZoomOutIcon />
                </button>
              </>
            )}
            {hasUpdatePermission && edit && (
              <button
                className="assetPreviewModal__footer--action-button"
                onClick={edit}
              >
                <EditIcon />
              </button>
            )}
            {download && (
              <button
                className="assetPreviewModal__footer--action-button"
                onClick={handleDownloadIconClick}
              >
                <DownloadIcon />
              </button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default AssetPreviewModal;
