import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { CloseIcon } from '../Icons/CloseIcon';

import './style.scss';

interface ModalLayoutProps {
  id?: string;
  showModal: boolean;
  setShowModal: any;
  children?: ReactNode;
  defaultClose?: boolean;
  style?: any;
  className?: string;
  onClose?: Function;
}

export default function ModalLayout(
  props: ModalLayoutProps,
): JSX.Element | null {
  const {
    id,
    showModal,
    setShowModal,
    children,
    defaultClose,
    style,
    className,
    onClose,
  } = props;
  if (!showModal) return null;
  const divPortal = document.getElementById('full-screen-model');
  return (
    divPortal &&
    ReactDOM.createPortal(
      <div className="modal__backdrop" id={id}>
        <div className={`modal__body ${className || ''}`} style={style}>
          {defaultClose && (
            <button
              className="modal__close-button"
              onClick={() => (onClose ? onClose() : setShowModal(!showModal))}
              id={`${id}-close-button`}
            >
              <CloseIcon />
            </button>
          )}
          {children}
        </div>
      </div>,
      divPortal,
    )
  );
}
