import React from 'react';
import ReactTooltip, { Effect, Offset, Place } from 'react-tooltip';

import './style.scss';

interface ICustomToolTip {
  id: string;
  tipText: string | JSX.Element | JSX.Element[];
  children: any;
  hideToolTip?: boolean;
  placement?: Place;
  customPosition?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  overridePosition?: (
    position: { left: number; top: number },
    currentEvent: Event,
    currentTarget: EventTarget,
    // node is the ref argument, and the wrapper
    // is restricted to: div | span
    refNode: null | HTMLDivElement | HTMLSpanElement,
    place: Place,
    desiredPlace: Place,
    effect: Effect,
    offset: Offset,
  ) => { left: number; top: number };
}

const CustomToolTip = ({
  id,
  tipText,
  children,
  hideToolTip = false,
  placement = 'bottom',
  customPosition = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}: ICustomToolTip) => {
  // This function override tooltip width
  // To remove overflowing from  Window
  const maxScreenPosition: ICustomToolTip['overridePosition'] = (
    { left, top },
    _e,
    _t,
    node,
  ) => {
    return {
      top,
      left: typeof node === 'string' ? left : Math.max(left, 0),
    };
  };
  return (
    <>
      <ReactTooltip
        id={id}
        effect="solid"
        offset={customPosition}
        place={placement}
        disable={hideToolTip}
        overridePosition={maxScreenPosition}
      >
        <span className="text-break">{tipText}</span>
      </ReactTooltip>
      {React.cloneElement(children, {
        'data-tip': true,
        'data-for': id,
      })}
    </>
  );
};

export default CustomToolTip;
