import React from 'react';
import { useState } from 'react';

import RadioButton from '../../RadioButton';
import { useTranslation } from 'react-i18next';
import {
  ListConfiguration,
  ListableActions,
  ListableState,
} from '@models/filter';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { useDispatch } from 'react-redux';

import './style.scss';

interface SortMenuItemProps<T extends ListConfiguration> {
  /** Used to get the translation of the values */
  filterNamespace: string;
  listableConfiguration: DeepReadonly<T>;
  listableState: DeepReadonly<ListableState<unknown, T>>;
  listableAction: ListableActions<T>;
}

/**
 * Component to display sorts items in sidebar, like in products. Automatically renders a "show more" option if there are more than 9 options in sort.
 */
const SortMenuItem = <T extends ListConfiguration>({
  filterNamespace,
  listableState,
  listableConfiguration,
  listableAction,
}: SortMenuItemProps<T>): React.ReactElement<SortMenuItemProps<T>> => {
  const [showMore, setShowMore] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sortBy, sortOrder } = listableState;
  const { sort } = listableConfiguration;

  const filterName = t(`filters._sortTitle`).toUpperCase();

  const toOptionElement = (option: (typeof sort)[number]) => {
    const isOptionActive =
      sortOrder === option.sortOrder && sortBy == option.sortBy;
    const name = capitalizeEveryFirstLetter(
      t(
        `filters.${filterNamespace}._sort.${option.sortBy}.${option.sortOrder}`,
      ),
    );

    const id = option.sortBy + '.' + option.sortOrder;

    const onChange = () => {
      // Only change if not yet selected
      if (!isOptionActive) dispatch(listableAction.setSort(option));
    };

    return (
      <label className="filter-item__label" key={id}>
        <RadioButton
          name={name}
          value={id}
          checked={isOptionActive}
          onChange={onChange}
        />
        <span className={isOptionActive ? 'text-oxford-blue' : ''}>{name}</span>
      </label>
    );
  };

  return (
    <details className="filter-item">
      <summary className="filter-item__summary">{filterName}</summary>
      <div>
        {sort.slice(0, showMore ? 9 : sort.length).map(toOptionElement)}
      </div>

      {sort.length > 9 && (
        <p
          className="filter-item__show-more"
          onClick={() => setShowMore((prev) => !prev)}
        >
          {showMore ? t('global.showMore') : t('global.showLess')}
        </p>
      )}
    </details>
  );
};

export default SortMenuItem;
