/** Exports functionality to migrate legacy models into new models */
import { getAsset } from '@apis/digitalAssets';
import { DUE_TYPE, VersionTargetingOption } from '@constants/productVersion';
import { DigitalAssetBrief } from '@models/digital-asset';
import type {
  AttributeValueMap,
  LegacyProduct,
  Product,
  Variant,
} from '@models/product';
import {
  AttributeTypes,
  LegacyTemplate,
  Template,
  TemplateAttribute,
  TemplateValidator,
} from '@models/productTemplate';
import {
  LegacyPageVersion,
  LegacyProductVersion,
  LegacyVersion,
  PageVersion,
  ProductVersion,
  Version,
} from '@models/productVersion';
import { AssetCache } from '@components/ContentEditor';
import { ALL_EXTENSIONS_TO_TYPES_MAP, ALL_IMAGES, ALL_VIDEOS } from './files';

export const toAsset = async (
  id: string,
  assetMap: DeepReadonly<{ [id: string]: DigitalAssetBrief }> = {},
): Promise<DigitalAssetBrief> => {
  if (assetMap[id])
    return structuredClone(assetMap[id]) as DeepWritable<DigitalAssetBrief>;
  return getAsset(id);
};

export const migrateProduct = {
  toUpdated: (
    legacyProduct: DeepReadonly<LegacyProduct>,
  ): { product: Product; variant: Variant } => {
    const { images, price, name, description } = legacyProduct.attributes;

    const attributes: AttributeValueMap = {
      assets: images.map((img) => img.assetId),
      title: name as string,
      description: description as string,
    };
    /** These attributes shouldn't be included */
    const omitAttributes = [
      'description',
      'giftCard',
      'images',
      'name',
      'price',
      'productDescription',
      'productId',
      'productName',
      'published',
      'sku',
      'type',
      'vendor',
    ];
    // Add all other legacy attributes beyond the standard ones
    for (const attribute in legacyProduct.attributes) {
      if (omitAttributes.includes(attribute)) continue;
      attributes[attribute] = legacyProduct.attributes[attribute] as string;
    }

    const product: Product = {
      productId: legacyProduct.productId,
      tenantId: legacyProduct.tenantId,
      title: name as string,
      description: description as string,
      handle: legacyProduct.productHandle,
      activeVersions: NaN, // We don't have information on this
      sourceTemplateId: legacyProduct.productTemplateId,
      taxonomies: [...legacyProduct.taxonomies],
      price: price as number,
      status: 'active', // We don't have information on this
      thumbnail: images.at(0)?.damUrl || null,
      createdAt: legacyProduct.createdAt,
      updatedAt: legacyProduct.updatedAt,
      versions: {
        // We don't have information on these
        total: NaN,
        live: NaN,
        draft: NaN,
        disabled: NaN,
      },
      options: [], // We don't have information on this
      defaultVariantId: legacyProduct.productId, // Variants are not onboarded so using productId
      fullyOnboarded: true,
      variantIds: [legacyProduct.productId],
    };

    const variant: Variant = {
      id: legacyProduct.productId, // Variants are not onboarded so using productId
      optionValues: {}, // We don't have information on this
      attributes,
      sku: legacyProduct.productId, // SKUs are not onboarded anyway

      productId: product.productId,
      price: price as number,
      tenantId: product.tenantId,
    };
    return { product, variant };
  },
  toLegacy: async ({
    product,
    variant,
    assetMap = {},
  }: {
    product: DeepReadonly<Product>;
    variant: DeepReadonly<Variant>;
    assetMap?: DeepReadonly<{ [id: string]: DigitalAssetBrief }>;
  }): Promise<LegacyProduct> => {
    const { assets, title, description, ...otherAttributes } =
      variant.attributes;
    const images = await Promise.all(
      (assets as string[]).map(
        async (assetId, position): Promise<LegacyProduct.Asset> => {
          const asset = await toAsset(assetId, assetMap);
          return {
            assetId: asset.id,
            damUrl: asset.url,
            shopUrl: asset.storeUrl || '',
            altText: asset.altText,
            position,
          };
        },
      ),
    );

    const attributes: LegacyProduct['attributes'] = {
      giftCard: false as boolean & string, // Otherwise we get a stupid TS error
      images,
      published: true as boolean & string, // Otherwise we get a stupid TS error
      sku: variant.sku,
      type: '',
      vendor: 'N/A',
      name: title as string,
      productName: title as string,
      description: description as string,
      productDescription: description as string,
      ...otherAttributes,
    };
    return {
      attributes,
      createdAt: product.createdAt,
      createdBy: 'System',
      productHandle: product.handle,
      productTemplateId: product.sourceTemplateId,
      productId: product.productId,
      status: product.status,
      taxonomies: [...product.taxonomies],
      tenantId: product.tenantId,
      updatedAt: product.updatedAt,
      updatedBy: 'System',
    };
  },
};

export const migrateVersion = {
  toUpdated: <T extends LegacyVersion>(
    legacyVersion: DeepReadonly<T>,
  ): T extends LegacyProductVersion ? ProductVersion : PageVersion => {
    const attributes: AttributeValueMap = {};

    for (const list of legacyVersion.contentList) {
      if (list.sectionName.startsWith('asset'))
        attributes[list.sectionName] = list.personalisedContent.map(
          (c) => c.assetId as string,
        );
      else if (list.sectionName.startsWith('richContent'))
        attributes[list.sectionName] = list.personalisedContent.map((c) => {
          if (c.propertyName === 'html')
            return `data:text/html,${c.personalisedText}`;
          else if (c.propertyName === 'url')
            return `data:text/uri-list,${c.personalisedText}`;
          else return c.personalisedText;
        });
      else
        for (const content of list.personalisedContent) {
          let name = content.propertyName;
          if (name === 'productDescription') name = 'description';
          else if (name === 'productName') name = 'title';
          attributes[name] = content.personalisedText;
        }
    }

    let status = legacyVersion.prescriptiveStatus as Version['status'];
    if (['LIVE', 'IN_TEST'].includes(status))
      status = legacyVersion.isActive ? 'published' : 'paused';

    return {
      attributes,
      experienceId: legacyVersion.contextIds[0],
      tenantId: '__CANNOT_MIGRATE__',

      id: legacyVersion.id,
      title: legacyVersion.title,
      experimentIds: legacyVersion.experimentIds,

      ...('productId' in legacyVersion
        ? { productId: legacyVersion.productId }
        : { pageId: legacyVersion.pageId }),
      type: legacyVersion.isTestVersion ? 'test' : 'personalize',
      contentReference: legacyVersion.contentReference,
      thumbnail: legacyVersion.thumbnail,
      publishDate: legacyVersion.publishDate,
      updatedAt: legacyVersion.updatedAtTime,
      status,
      expiryDate: legacyVersion.expiryDate,
      trafficPercentage: 100 - legacyVersion.controlGroupValue,
      variantOverrides: {},
      workflow: legacyVersion.workflowEnabled
        ? {
            due:
              legacyVersion.dueType !== 'ASAP'
                ? legacyVersion.dueDate
                  ? legacyVersion.dueDate
                  : 'toBeConfirmed'
                : 'asap',
            taskList: legacyVersion.taskList?.map((l) => ({ ...l })) || [],
            completedSteps: legacyVersion.completedSteps,
            priority: legacyVersion.highPriority ? 'high' : 'low',
          }
        : undefined,
    } as T extends LegacyProductVersion ? ProductVersion : PageVersion;
  },
  toLegacy: async <T extends Version>(
    version: DeepReadonly<T>,
    assetMap: AssetCache,
  ): Promise<
    T extends ProductVersion ? LegacyProductVersion : LegacyPageVersion
  > => {
    const due = version.workflow?.due;

    const contentList: LegacyVersion.PersonalizedSection[] = [];
    const master: LegacyVersion.PersonalizedSection = {
      sectionName: 'Master',
      personalisedContent: [],
    };

    for (const attribute in version.attributes) {
      const value = version.attributes[attribute];

      if (value === undefined) continue;
      if (typeof value === 'string') {
        master.personalisedContent.push({
          propertyName: attribute,
          personalisedText: value,
          position: master.personalisedContent.length,
          standardText: value,
        });
      } else if (attribute.startsWith('asset')) {
        contentList.push({
          sectionName: attribute,
          personalisedContent: await Promise.all(
            value.map(async (entry, position) => {
              const asset = await toAsset(entry, assetMap);
              const assetMime = ALL_EXTENSIONS_TO_TYPES_MAP[asset.format];

              return {
                propertyName: 'assetUrl',
                personalisedText: asset.url,
                standardText: asset.url,
                assetId: asset.id,
                assetTitle: asset.title,
                altText: asset.altText,
                type: ALL_VIDEOS.includes(assetMime)
                  ? 'video'
                  : ALL_IMAGES.includes(assetMime)
                  ? 'image'
                  : 'doc',
                position,
              };
            }),
          ),
        });
      } else {
        contentList.push({
          sectionName: attribute,
          personalisedContent: value.map((entry, position) => {
            const type = entry.startsWith('data:text/html,')
              ? 'html'
              : entry.startsWith('data:text/uri-list,')
              ? 'url'
              : 'id';
            const content =
              type === 'id' ? entry : entry.substring(entry.indexOf(',') + 1);
            return {
              propertyName: type,
              personalisedText: content,
              standardText: content,
              position,
            };
          }),
        });
      }
    }

    if (master.personalisedContent.length) contentList.push(master);

    let prescriptiveStatus: string = version.status;
    if (prescriptiveStatus === 'published')
      prescriptiveStatus = version.type === 'test' ? 'IN_TEST' : 'LIVE';

    return {
      id: version.id,
      ...('productId' in version
        ? { productId: version.productId }
        : { pageId: version.pageId }),
      templateId: '__CANNOT_MIGRATE__',
      prescriptiveStatus,
      experiments: version.experimentIds,
      dueDate: typeof due === 'number' ? due : void 0,
      title: version.title,
      description: '', // Destructive
      publishDate: version.publishDate,
      expiryDate: version.expiryDate,
      dueType:
        typeof due === 'number'
          ? DUE_TYPE.onAdate
          : due === 'toBeConfirmed'
          ? DUE_TYPE.toBeConfirmed
          : DUE_TYPE.asap,
      audienceType:
        version.type === 'test'
          ? VersionTargetingOption.anonymous
          : VersionTargetingOption.context,
      controlGroupValue: 100 - (version.trafficPercentage || 0),
      contextIds: version.experienceId ? [version.experienceId] : [],
      experimentIds: version.experimentIds ?? [],
      published: version.status === 'published',
      contentList,
      taskList: version.workflow?.taskList?.map((l) => ({ ...l })),
      contentReference: version.contentReference,
      isTestVersion: version.type === 'test',
      isActive: version.status !== 'paused',
      completedSteps: version.workflow?.completedSteps || 'CONTENT',
      thumbnail: version.thumbnail as string,
      workflowEnabled: !!version.workflow,
      highPriority: version.workflow?.priority === 'high',
      updatedAtTime: version.updatedAt,
    } as unknown as T extends ProductVersion
      ? LegacyProductVersion
      : LegacyPageVersion;
  },
};

export const migrateTemplate = {
  toUpdated: (legacyTemplate: DeepReadonly<LegacyTemplate>): Template => {
    const attributes: TemplateAttribute<AttributeTypes>[] = [];

    const typeMap: { [key in LegacyTemplate.EditorField]: AttributeTypes } = {
      Popup_Lookup_Checkbox: 'html',
      Image_Picker: 'assets',
      Text_Box: 'text',
      Text_Box_Html: 'html',
      Popup: 'richContent',
    };

    for (const group of legacyTemplate.attributeGroups) {
      const isGallery = group.groupName.startsWith('Media Gallery');
      // Some gallery groups have an `images` attribute which doesn't really exist
      if (isGallery) group.attributes.slice(0, 1);

      for (const attribute of group.attributes) {
        const name = attribute.attributeName;
        let displayName = attribute.placeholder;
        const validator: TemplateValidator | undefined = attribute
          .attributeValidators.length
          ? {}
          : undefined;

        // Variant galleries require special displayName
        if (isGallery && name.startsWith('assets_'))
          displayName = group.groupName;
        if (validator) {
          for (const { name, value } of attribute.attributeValidators) {
            // Omit Regex since template regex is improper
            switch (name) {
              case 'MAX_LENGTH':
                validator.max = Number(value);
                break;
              case 'MAX_MIN_SIZE':
                {
                  const [max, min] = (value || '').split('_').map(Number);
                  validator.max = max;
                  validator.min = min;
                }
                break;
              default:
                // NOT_IN_USE |  The others are used by no production template so far (validated 2024-03-20)
                throw new Error('Cannot translate validator of type ' + name);
            }
          }
        }

        attributes.push({
          name,
          displayName,
          validator,
          type: typeMap[attribute.visualTag],
          customizable: attribute.metaTags.includes('Contextualizable'),
          sourceKey: attribute.nameInSourceSystem || '__MISSING__',
        });
      }
    }

    return {
      attributes,

      id: legacyTemplate.templateId,
      tenantId: legacyTemplate.tenantId,
      createdAt: legacyTemplate.createdAtTime,
      createdBy: legacyTemplate.createdBy,
      updatedAt: legacyTemplate.updatedAtTime,
      updatedBy: legacyTemplate.updatedBy,
    };
  },
  toLegacy: (template: DeepReadonly<Template>): LegacyTemplate => {
    const createGroup = (
      name: string,
      attribute?: LegacyTemplate.Attribute,
    ): LegacyTemplate.AttributeGroup => ({
      groupName: name,
      attributes: attribute ? [attribute] : [],
    });

    const attributeGroups: LegacyTemplate.AttributeGroup[] = [];
    const contextualGroup = createGroup('Contextualized Product Content');
    const staticGroup = createGroup('Product Content');

    const typeMap: {
      [key in AttributeTypes]: LegacyTemplate.EditorField;
    } = {
      assets: LegacyTemplate.EditorField.Image_Picker,
      text: LegacyTemplate.EditorField.Text_Box,
      html: LegacyTemplate.EditorField.Text_Box_Html,
      richContent: LegacyTemplate.EditorField.Popup,
    };

    for (const attribute of template.attributes) {
      const attributeValidators: LegacyTemplate.IAttributeValidator[] = [];
      if (attribute.validator) {
        const { min, max, regex } = attribute.validator;
        attributeValidators.push({
          // See comment above starting with "NOT_IN_USE"
          name: min !== undefined ? 'MAX_MIN_SIZE' : 'MAX_LENGTH',
          value: min !== undefined ? `${max}_${min}` : `${max}`,
          regex: regex || null,
          // Destructive
          errorMessage: 'An error ocurred',
        });
      }

      const legacyAttribute: LegacyTemplate.Attribute = {
        attributeName: attribute.name,
        placeholder: attribute.displayName,
        visualTag: typeMap[attribute.type],
        metaTags: attribute.customizable ? ['Contextualizable'] : [],
        nameInSourceSystem:
          attribute.sourceKey === '__MISSING__' ? null : attribute.sourceKey,
        dataType: null,
        attributeValidators,
      };

      if (attribute.name.startsWith('assets')) {
        attributeGroups.push(
          createGroup(attribute.displayName, legacyAttribute),
        );
      } else if (attribute.name.startsWith('richContent')) {
        attributeGroups.push(
          createGroup(attribute.displayName, legacyAttribute),
        );
      } else {
        const group = attribute.customizable ? contextualGroup : staticGroup;
        group.attributes.push(legacyAttribute);
      }
    }

    // Insert contextual content as first if populated
    if (contextualGroup.attributes.length)
      attributeGroups.splice(0, 0, contextualGroup);
    // Push static content as first if populated
    if (staticGroup.attributes.length) attributeGroups.push(staticGroup);

    return {
      attributeGroups,
      taxonomies: [], // Destructively lost information

      templateId: template.id,
      tenantId: template.tenantId,
      createdAtTime: template.createdAt,
      createdBy: template.createdBy,
      updatedAtTime: template.updatedAt,
      updatedBy: template.updatedBy,
    };
  },
};
