import React from 'react';
import { useHistory } from 'react-router-dom';
import { BackIcon } from '@components/Icons/BackIcon';

import './WorkflowHeader.scss';

interface IWorkflowHeader {
  centered?: boolean;
  leftSection?: JSX.Element;
  centerSection?: JSX.Element;
  rightSection?: JSX.Element;
  backButtonLink?: string;
  className?: string;
}

const WorkflowOverviewHeader: React.FC<IWorkflowHeader> = ({
  centered = false,
  leftSection,
  backButtonLink,
  centerSection,
  rightSection,
  className,
}) => {
  const history = useHistory();
  return (
    <div className={`WorkflowHeader ${className ? className : ''}`}>
      {backButtonLink && (
        <button
          className="WorkflowHeader-backButton"
          onClick={() => history.push(backButtonLink)}
        >
          <BackIcon fill="black" />
        </button>
      )}
      <div className={`WorkflowHeader__${centered ? 'centered' : 'separated'}`}>
        <div className="WorkflowHeader-leftSection">{leftSection}</div>
        {centered && (
          <div className="WorkflowHeader-centerSection">{centerSection}</div>
        )}
        <div className="WorkflowHeader-rightSection">{rightSection}</div>
      </div>
    </div>
  );
};

export default WorkflowOverviewHeader;
