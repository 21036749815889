import React from 'react';

import './style.scss';

const RoundCheckBox: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  ...props
}) => {
  return (
    <input
      type="checkbox"
      {...props}
      className={`round-checkbox ${className || ''}`}
    />
  );
};

export default RoundCheckBox;
