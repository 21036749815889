import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageTextInclusionIcon } from '@components/Icons/ImageTextInclusionIcon';

import { capitalizeFirstLetter } from '@utils/textTransform';
import menuIcon from '@assets/icons/menu.svg';

import './style.scss';

interface IDrodownMenu {
  index: number;
  length: number;
  moveSection: Function;
  openDropDown: boolean;
}

const DrodownMenu: React.FC<IDrodownMenu> = ({
  index,
  length,
  moveSection,
  openDropDown,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`RichContentSectionHeader__menu--options ${
        openDropDown ? 'RichContentSectionHeader__menu--open' : ''
      }`}
    >
      <p
        className={`${index == 0 ? 'disabled' : ''}`}
        onClick={() => moveSection('up', index)}
      >
        {capitalizeFirstLetter(t('rich_content.move_section_up'))}
      </p>
      <p
        className={`${index == length - 1 ? 'disabled' : ''}`}
        onClick={() => moveSection('down', index)}
      >
        {capitalizeFirstLetter(t('rich_content.move_section_down'))}
      </p>
      <p onClick={() => moveSection('delete', index)}>
        {capitalizeFirstLetter(t('rich_content.delete_section'))}
      </p>
    </div>
  );
};

interface ISectionHeader {
  icon?: JSX.Element;
  title: string;
  moveSection: Function;
  index: number;
  length: number;
  showDropdown: boolean;
}

const SectionHeader: React.FC<ISectionHeader> = ({
  icon = <ImageTextInclusionIcon />,
  title,
  index,
  length,
  showDropdown,
  moveSection,
}) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const moveSectionHandler = (type: string, index: number) => {
    setOpenDropDown(false);
    moveSection(type, index);
  };

  return (
    <div className="RichContentSectionHeader">
      <div className="RichContentSectionHeader__header">
        <div className="RichContentSectionHeader__header-icon">{icon}</div>
        <p className="RichContentSectionHeader__header-title">{title}</p>
      </div>
      <div className="RichContentSectionHeader__menu">
        {showDropdown && (
          <>
            <img
              src={menuIcon}
              alt="menu icon"
              onClick={() => {
                setOpenDropDown(!openDropDown);
              }}
            />
            <DrodownMenu
              moveSection={moveSectionHandler}
              openDropDown={openDropDown}
              index={index}
              length={length}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
