import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '@constants/colors';
import { typography } from '@constants/typography';

import Button from '../Button';
import Text from '../Text';

interface PredefinedDatesProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  selectedPredefined?: string;
  setRange?: any;
  predefinedOptions?: string[];
}
const PredefinedDates = ({
  selectedPredefined,
  setRange,
  predefinedOptions,
}: PredefinedDatesProps) => {
  const { t } = useTranslation();
  return (
    <div className="RangeDatePickerNoBorder-picker--predefined">
      <Text
        weight={typography.fontWeight.semi_bold}
        size={typography.fontSize.md}
        className="mb-1_5"
        color={colors.dardOxfordBlue}
      >
        {t('dashboard.predefined_periods')}
      </Text>
      {predefinedOptions?.map((option, index) => (
        <Button
          key={index}
          background="white"
          padding="4px 0px"
          action={() => setRange(option)}
          className="mb-1 d-block px-0"
        >
          <Text
            color={
              option === selectedPredefined
                ? colors.emerald
                : colors.oxfordBlue5
            }
            size={typography.fontSize.sm}
            weight={typography.fontWeight.normal}
          >
            {option}
          </Text>
        </Button>
      ))}
    </div>
  );
};
export default PredefinedDates;
