import { FC } from 'react';

import { OutlinedPlayIcon } from '../Icons/OutlinedPlayIcon';

import './Video.scss';
/**
 * Adds overlay over asset to indicate that media is video type
 */
const VideoOverlay: FC = ({ children }) => {
  return (
    <div className="VideoOverlay">
      {children}
      <div className="VideoOverlay__overlay">
        <OutlinedPlayIcon className="VideoOverlay_playlogo" />
      </div>
    </div>
  );
};

export default VideoOverlay;
