import React from 'react';

export const TickMarkIcon = ({
  height = '16px',
  width = '16px',
  fill = 'var(--white)',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05289 8.48251C2.29946 8.23594 2.69922 8.23594 2.94579 8.48251L6.07091 11.6076L13.6605 4.01804C13.9071 3.77148 14.3068 3.77148 14.5534 4.01804C14.8 4.26461 14.8 4.66437 14.5534 4.91094L6.51736 12.947C6.27079 13.1935 5.87103 13.1935 5.62447 12.947L2.05289 9.3754C1.80633 9.12884 1.80633 8.72907 2.05289 8.48251Z"
      fill={fill}
    />
  </svg>
);
