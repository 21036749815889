import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { AnyAttributeValue, AttributeValueMap } from '@models/product';
import { Template } from '@models/productTemplate';
import { toast } from '@components/ToastNotification/ToastManager';
import { getProductTemplate } from '@apis/productTemplate';
import { Experience } from '@models/experience';
import { migrateTemplate } from '@utils/migrate';
import { Version } from '@models/productVersion';

interface ProductVersionState {
  template: Template | null;
  content: AttributeValueMap;
  isContentChanged: boolean;
  experience: Experience | null;
  referenceVersion: Version | null;
}

const initialState: ProductVersionState = {
  template: null,
  content: {},
  isContentChanged: false,
  experience: null,
  referenceVersion: null,
};

const { actions, reducer } = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    setContent: (state, { payload }: { payload: AttributeValueMap }) => {
      state.content = payload;
    },
    updateContentAttribute: (
      state,
      { payload }: { payload: { name: string; value: AnyAttributeValue } },
    ) => {
      state.content[payload.name] = payload.value;
      state.isContentChanged = true;
    },
    setContentChanged: (state, { payload }: { payload: boolean }) => {
      state.isContentChanged = payload;
    },
    reset: (state) => {
      state.template = null;
      state.content = {};
      state.experience = null;
      state.isContentChanged = false;
    },
    setExperience: (state, { payload }: { payload: Experience | null }) => {
      state.experience = payload;
    },
    setTemplate: (state, { payload }: { payload: Template | null }) => {
      state.template = payload;
    },
    setReferenceVersion: (state, { payload }: { payload: Version | null }) => {
      state.referenceVersion = payload;
    },
  },
});

const thunks = {
  fetchTemplate:
    ({ objectId }: { objectId: string }): AppThunk =>
    async (dispatch) => {
      try {
        const legacyTemplate = await getProductTemplate(objectId);
        const template = migrateTemplate.toUpdated(legacyTemplate);
        // We filter any non-customizable attributes as they are not relevant here
        template.attributes = template.attributes.filter(
          (att) => att.customizable,
        );
        dispatch(RVersion.setTemplate(template));
      } catch (errorObj: any) {
        const { errors } = errorObj;
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RVersion = Object.assign(actions, thunks);

export default reducer;
