import React from 'react';

export const ImageTextInclusionIcon = ({
  height = '24px',
  width = '24px',
  fill = 'var(----emerald-shade-7)',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 0H0V20H20V0Z" fill="#B3F5DB" />
    <path
      d="M4.08734 8.25673L8.20134 2.17073C8.23145 2.1177 8.27508 2.07361 8.32778 2.04293C8.38047 2.01225 8.44036 1.99609 8.50134 1.99609C8.56231 1.99609 8.6222 2.01225 8.6749 2.04293C8.7276 2.07361 8.77123 2.1177 8.80134 2.17073L12.9153 8.25673C13.1153 8.55673 12.9393 9.00073 12.6153 9.00073H4.38434C4.06234 9.00073 3.88434 8.55573 4.08434 8.25673"
      fill="#80EEC3"
    />
    <path
      d="M8.12408 8.19982L11.7101 4.13682C11.7457 4.094 11.7904 4.05955 11.8408 4.0359C11.8913 4.01226 11.9464 4 12.0021 4C12.0578 4 12.1128 4.01226 12.1633 4.0359C12.2138 4.05955 12.2584 4.094 12.2941 4.13682L15.8801 8.19982C15.9401 8.26435 15.9811 8.34421 15.9986 8.43058C16.016 8.51695 16.0093 8.60647 15.9791 8.68925C15.9489 8.77203 15.8964 8.84484 15.8274 8.89965C15.7584 8.95446 15.6755 8.98913 15.5881 8.99982H8.41608C8.32861 8.98913 8.2458 8.95446 8.1768 8.89965C8.10779 8.84484 8.05528 8.77203 8.02507 8.68925C7.99486 8.60647 7.98811 8.51695 8.00558 8.43058C8.02306 8.34421 8.06406 8.26435 8.12408 8.19982Z"
      fill="#34E49E"
    />
    <path
      d="M16.002 2.5C16.002 2.79667 15.914 3.08668 15.7492 3.33336C15.5843 3.58003 15.3501 3.77229 15.076 3.88582C14.8019 3.99935 14.5003 4.02906 14.2093 3.97118C13.9183 3.9133 13.6511 3.77044 13.4413 3.56066C13.2315 3.35088 13.0887 3.08361 13.0308 2.79264C12.9729 2.50166 13.0026 2.20006 13.1161 1.92597C13.2297 1.65189 13.4219 1.41762 13.6686 1.2528C13.9153 1.08797 14.2053 1 14.502 1C14.8998 1 15.2813 1.15804 15.5626 1.43934C15.8439 1.72064 16.002 2.10218 16.002 2.5Z"
      fill="white"
    />
    <path
      d="M14.002 10H6.00195C5.44967 10 5.00195 10.4477 5.00195 11C5.00195 11.5523 5.44967 12 6.00195 12H14.002C14.5542 12 15.002 11.5523 15.002 11C15.002 10.4477 14.5542 10 14.002 10Z"
      fill="#34E49E"
    />
    <path
      d="M14.002 13H6.00195C5.44967 13 5.00195 13.4477 5.00195 14C5.00195 14.5523 5.44967 15 6.00195 15H14.002C14.5542 15 15.002 14.5523 15.002 14C15.002 13.4477 14.5542 13 14.002 13Z"
      fill="#34E49E"
    />
    <path
      d="M14.002 16H6.00195C5.44967 16 5.00195 16.4477 5.00195 17C5.00195 17.5523 5.44967 18 6.00195 18H14.002C14.5542 18 15.002 17.5523 15.002 17C15.002 16.4477 14.5542 16 14.002 16Z"
      fill="#34E49E"
    />
  </svg>
);
