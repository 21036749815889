import { AIGeneration, AIService } from '@apis/aiService';
import { Connector } from '@apis/connector';
import { getProductTemplate } from '@apis/productTemplate';
import { Experience } from '@models/experience';
import { DirectoryProduct } from '@models/product';
import { Template } from '@models/productTemplate';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectExperienceView from './+page/SelectExperienceView';
import SelectProductView from './+page/SelectProductView';
import VersionView from './+page/VersionView';
import { useDispatch, useSelector } from 'react-redux';
import { RProductVersion } from '@redux/slices/productVersion';

import { ReactComponent as HeuristicLogo } from '@assets/logos/logo_horizontal.svg';
import { ReactComponent as LeftChevron } from '@assets/icons/left-chevron.svg';
import './style.scss';
import { migrateTemplate } from '@utils/migrate';
import { toast } from '@components/ToastNotification/ToastManager';

const TestFlow = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onboarded } = useSelector((state) => state.tenant);
  const [experience, setExperience] = useState<Experience>();
  const [product, setProduct] = useState<DirectoryProduct>();
  const [version, setVersion] = useState<AIGeneration>();
  const [template, setTemplate] = useState<Template>();

  // Pre-cache recommended products
  useEffect(() => void Connector.getRecommendedProducts(0), []);

  useEffect(() => {
    if (!(product && experience)) return;
    const { productId } = product;
    const { id: experienceId } = experience;

    (async () => {
      if (product.status !== 'onboarded') {
        const { errors } = await Connector.onboardProduct(productId);
        if (errors && errors.length > 0) throw new Error('Onboarding failed');
      }

      const generationPromise = AIService.generate({ experienceId, productId });
      dispatch(RProductVersion.fetchProductSaga(productId));
      const template = await getProductTemplate(productId).catch(
        ({ errors }: any) => {
          toast.show({
            message: errors?.[0]?.message,
            error: true,
          });
          return undefined;
        },
      );
      setTemplate(template ? migrateTemplate.toUpdated(template) : template);
      const aiContent = await generationPromise;
      setVersion(aiContent);
    })();
  }, [product]);

  const step = {
    index: !experience ? 0 : !product ? 1 : 2,
    component: !experience ? (
      <SelectExperienceView setExperience={setExperience} />
    ) : !product ? (
      <SelectProductView setProduct={setProduct} />
    ) : (
      <VersionView
        version={version}
        experience={experience}
        template={template}
      />
    ),
  };

  const back = () => {
    if (product) {
      setProduct(void 0);
      setVersion(void 0);
      setTemplate(void 0);
    } else if (experience) setExperience(void 0);
    else history.back();
  };

  return (
    <>
      <nav className="test-flow--nav">
        {onboarded === false && <HeuristicLogo />}
        <ol>
          {[0, 1, 2].map((i) => (
            <li key={i} className={i === step.index ? 'active' : ''}>
              {capitalizeEveryFirstLetter(t(`testFlow.step${i}.name`))}
            </li>
          ))}
        </ol>
      </nav>
      <main className="test-flow--main">
        {(onboarded !== false || experience) && (
          <button className="back-button" onClick={back}>
            <LeftChevron fill="var(--manatte)" />
          </button>
        )}
        <h1>
          {capitalizeFirstLetter(t(`testFlow.step${step.index}.heading`))}
        </h1>
        <h2>
          {capitalizeFirstLetter(t(`testFlow.step${step.index}.subheading`))}
        </h2>
        {step.component}
      </main>
    </>
  );
};

export default TestFlow;
