import React from 'react';

import './EndOfSearch.scss';

interface IEndOfSearch {
  text: string;
}

const EndOfSearch: React.FC<IEndOfSearch> = ({ text }) => {
  return (
    <div className="EndOfSearch">
      <hr />
      <p>{text}</p>
    </div>
  );
};

export default EndOfSearch;
