import React from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsGrid } from '@components/OptionsGrid';

import { sections } from '../defaultData';

const AddSectionList: React.FC<{
  addNewSection: (name: keyof typeof sections) => unknown;
}> = ({ addNewSection }) => {
  const { t } = useTranslation();

  const sectionList = Object.entries(sections);

  return (
    <OptionsGrid
      options={sectionList.map(([id, { preview }]) => ({
        id,
        icon: preview.icon,
        label: t(preview.textKey),
      }))}
      onSelect={(option) => addNewSection(option.id as keyof typeof sections)}
      size={'8.75rem'}
      layout={[4, 3]}
    />
  );
};
export default AddSectionList;
