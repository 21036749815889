import IIcon from '@models/icon';

import './Icon.scss';

export const OutlinedPlayIcon = ({
  fill = 'var(--emerald)',
  height = '24px',
  width = '24px',
  ...props
}: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="30" cy="30" r="28.5" stroke="white" strokeWidth="3" />
    <path d="M43 31L23.5 42.2583L23.5 19.7417L43 31Z" fill="white" />
  </svg>
);
