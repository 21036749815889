import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right.svg';
import './style.scss';
import { RSidebar } from '@redux/slices/sidebar';
import { LeftArrow } from '@components/Icons/LeftArrow';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { Link, useHistory } from 'react-router-dom';
import { getPath } from '@utils/routing';

const SecondarySidebar: React.FC<
  {
    sidebarText: string;
    id: string;
    hideCollapseIcon?: boolean;
  } & (
    | { links: Array<{ label: string; link: string }> }
    | { children: JSX.Element }
  )
> = ({ id, sidebarText, hideCollapseIcon, ...params }) => {
  const history = useHistory();

  const getActiveIndex = () => {
    if (!('links' in params)) return 0;

    // Return the longest matching link string to allow for sub-menu URLs
    let matchLength = 0;
    let matchIndex = 0;
    const path = getPath(history.location.pathname);
    for (let i = 0; i < params.links.length; ++i) {
      const { link } = params.links[i];
      const linkPath = getPath(link);
      if (linkPath.length > matchLength && path.startsWith(linkPath)) {
        matchIndex = i;
        matchLength = linkPath.length;
      }
    }
    return matchIndex;
  };

  const [activeIndex, setActiveIndex] = useState(getActiveIndex());

  useEffect(() => {
    setActiveIndex(getActiveIndex());
  }, [history.location]);

  const toggleSideBar = useSelector(
    (state) => state.sidebar.secondarySidebars[id],
  );

  const setToggleSideBar = (collapsed: boolean | null = null) => {
    dispatch(RSidebar.setSecondarySidebar({ id, collapsed }));
  };
  const sidebarCollapsed = useSelector((state) => state.sidebar.collapsed);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toggleSideBar === undefined) {
      setToggleSideBar();
    }
    if (!sidebarCollapsed) setToggleSideBar(false);
  }, [sidebarCollapsed]);

  useEffect(() => {
    // toggle on mount to show filter sidebar in open state by default
    if (id === 'digitalAssetsModal') setToggleSideBar(false);
  }, [id]);

  const onToggle = () => {
    if (toggleSideBar && sidebarCollapsed) {
      dispatch(RSidebar.setCollapsed(false));
    }
    setToggleSideBar();
  };

  return (
    <div
      className={`filter-sidebar ${
        toggleSideBar ? 'filter-sidebar--toggled' : ''
      } NavigationSidebar`}
    >
      {!hideCollapseIcon && (
        <div className="filter-sidebar__toggler" onClick={onToggle}>
          <span aria-hidden>{capitalizeFirstLetter(sidebarText)}</span>
          {toggleSideBar ? <ArrowRight /> : <LeftArrow />}
        </div>
      )}
      {'links' in params ? (
        <div className="filter-sidebar__links" aria-hidden>
          {params.links.map((link, index) => (
            <Link
              className={`filter-sidebar__link${
                index === activeIndex ? ' active' : ''
              }${index !== params.links.length - 1 ? ' separator' : ''}`}
              to={link.link}
              key={link.label}
            >
              {capitalizeFirstLetter(link.label)}
            </Link>
          ))}
        </div>
      ) : (
        params.children
      )}
    </div>
  );
};

export default SecondarySidebar;
