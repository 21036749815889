import i18n from 'i18next';
import { ENDPOINTS } from '@constants/endpoints';
import {
  ContextDetailsType,
  IContext,
  IContextUsage,
  IFetchContextUsageBody,
  ILocation,
  IUpdateContextBody,
} from '@models/context';
import { IUtm, IUtmUpdateBody, IUTMUsageUpdateBody } from '@models/utm';

import { clientWithToken, PAGE_SIZE } from '.';

import { toast } from '@components/ToastNotification/ToastManager';

const SORT_BY = 'updatedAt';
const CONTEXT_SORT_ORDER = 'DESC';

export const listContexts = async ({
  searchText,
  page,
  status = [],
}: {
  searchText: string;
  page: number;
  status?: ReadonlyArray<'DRAFT' | 'NEW' | 'UPDATED'>;
}) => {
  const url = ENDPOINTS.context.contextList;
  const res = await clientWithToken.post<{
    body: {
      content: IContext[];
      totalElements: number;
    };
  }>(url, {
    contextName: searchText,
    sortBy: SORT_BY,
    sortOrder: CONTEXT_SORT_ORDER,
    pageNumber: page,
    pageSize: PAGE_SIZE,
    status,
  });
  return res.data.body;
};

export const getContextByIds = async (ids: string[]) => {
  const url = ENDPOINTS.context.contexts;
  const response = await clientWithToken.post<{ body: { list: IContext[] } }>(
    url,
    { ids },
  );
  return response.data.body.list;
};

export const deleteContexts = async (contextIds: string[]) => {
  const url =
    contextIds.length === 1
      ? `${ENDPOINTS.context.context}/${contextIds[0]}`
      : ENDPOINTS.context.contextBulkDelete;
  try {
    await clientWithToken.delete<unknown>(url, {
      ...(contextIds.length > 1 && { data: contextIds }),
    });
    toast.show({
      message: `${i18n.t(
        `global.context${contextIds.length > 1 ? 's' : ''}`,
      )} ${i18n.t('global.deleted_successfully')}`,
      error: false,
    });
    return {
      message: '',
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
    return {
      message: errors?.[0]?.message,
      body,
      isSuccess: false,
    };
  }
};

export const getContext = async (id: string) => {
  const url = `${ENDPOINTS.context.context}/${id}`;
  try {
    const response = await clientWithToken.get<{ body: IContext }>(url);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const updateContext = async (data: IUpdateContextBody) => {
  try {
    const response = await clientWithToken.put<{ body: IContext }>(
      ENDPOINTS.context.context,
      data,
    );
    toast.show({
      message: i18n.t('global.changes_updated_successfully'),
      error: false,
    });
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const getLocations = async (query: string) => {
  const url = ENDPOINTS.metadata.locationsFilter;
  try {
    const response = await clientWithToken.post<{
      body: { items: ILocation[] };
    }>(url, {
      hierarchyToExclude: [],
      title: query,
    });
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: false,
    });
  }
};

export const getLocationsByIds = async (ids: string[]) => {
  const url = `${ENDPOINTS.metadata.locations}/${ids}`;
  try {
    const response = await clientWithToken.get<{
      body: { items: ILocation[] };
    }>(url);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: false,
    });
  }
};

export const getContextUsagesById = async (
  requestBody: IFetchContextUsageBody,
) => {
  const { contextualizedProducts, contexts, queryParams } =
    ENDPOINTS.contextualizedProducts;
  const url = `${contextualizedProducts}/${contexts}/${requestBody.id}?${queryParams.title}=${requestBody.title}&${queryParams.page}=${requestBody.page}&${queryParams.size}=${requestBody.size}`;
  try {
    const response = await clientWithToken.get<{
      body: {
        number: number;
        totalElements: number;
        content: IContextUsage[];
      };
    }>(url);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const getTargetGroupWeights = async () => {
  const url = ENDPOINTS.context.targetGroupsWeight;
  try {
    const response = await clientWithToken.get<{
      body: { [key: string]: string | number };
    }>(url);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: false,
    });
  }
};

export const createContext = async (payload: {
  [key: string]: ContextDetailsType;
}) => {
  const url = ENDPOINTS.context.context;
  const response = await clientWithToken.post<{ body: IContext }>(url, payload);
  return response.data.body;
};

export const deleteUtm = async (utmId: string) => {
  const url = `${ENDPOINTS.context.utm.utm}/${utmId}`;
  try {
    const response = await clientWithToken.delete<{ body: unknown }>(url);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: i18n.t('context.utm.can_not_delete_single'),
      error: true,
    });
    return {
      message: errors?.[0]?.message,
      body,
      isSuccess: false,
    };
  }
};

export const deleteUtms = async (utmIds: string[]) => {
  const url = `${ENDPOINTS.context.utm.bulk}`;
  try {
    const response = await clientWithToken.delete<{ body: unknown }>(url, {
      data: utmIds,
    });
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: i18n.t('context.utm.can_not_delete_multiple'),
      error: true,
    });
    return {
      message: errors?.[0]?.message,
      body,
      isSuccess: false,
    };
  }
};

export const removeUtmUsage = async (utmId: string, usage: string[]) => {
  const url = `${ENDPOINTS.context.utm.utm}/${utmId}`;
  try {
    const response = await clientWithToken.put<{ body: unknown }>(url, {
      usage,
    });
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const fetchUtmsByIds = async (payload: string[]) => {
  const url = ENDPOINTS.context.utm.bulk;
  try {
    const response = await clientWithToken.post<{ body: { list: IUtm[] } }>(
      url,
      payload,
    );
    return {
      data: response?.data?.body?.list,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const createUtm = async (payload: IUtmUpdateBody) => {
  const url = ENDPOINTS.context.utm.utm;
  try {
    const response = await clientWithToken.post<{ body: IUtm }>(url, payload);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const updateUtm = async (payload: IUtmUpdateBody) => {
  const url = ENDPOINTS.context.utm.utm;
  try {
    const response = await clientWithToken.put<{ body: IUtm }>(url, payload);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const updateUtmUsage = async (payload: IUTMUsageUpdateBody) => {
  const { utmId, usages } = payload;

  const url = `${ENDPOINTS.context.utm.unlink}/${utmId}`;
  try {
    const response = await clientWithToken.put<{ body: IUtm }>(url, usages);
    return {
      data: response?.data?.body,
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};
