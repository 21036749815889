import {
  LegacyPageVersion,
  LegacyVersion,
  PageDistributionSettings,
  PageVersion,
  Version,
} from '@models/productVersion';
import { clientWithToken, PAGE_SIZE } from '.';
import {
  ListablePayload,
  ListableState,
  ListConfiguration,
} from '@models/filter';
import { migrateVersion } from '@utils/migrate';
import { VersionTargetingOption } from '@constants/productVersion';
import { DetailPageVersion } from '@models/pages';
import {
  createExperiment,
  tagVersionToExperiment,
  updateVersionExperimentTags,
} from './experiments';

export namespace PageVersionsApi {
  export namespace List {
    export type State = ListableState<Version, typeof pageVersionsListConfig>;
    export type Query = ListablePayload<typeof pageVersionsListConfig>;
  }
}

// TODO `/directory/pages`
const BASE_URL = '/contextualized-products/pages';

export const pageVersionsListConfig = {
  sort: [
    { sortBy: 'relevanceScore', sortOrder: 'desc' },
    { sortBy: 'title', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'price', sortOrder: 'asc' },
    { sortBy: 'price', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'audienceType',
      multiple: false,
      hidden: false,
      options: [
        VersionTargetingOption.anonymous,
        VersionTargetingOption.context,
      ],
    },
    {
      name: 'published',
      multiple: false,
      hidden: true,
      options: ['true', 'false'],
    },
  ],
} as const satisfies ListConfiguration;

export const listPageVersions = async (
  pageId: string,
  {
    page,
    searchText,
    sortOrder,
    sortBy,
    filters,
  }: Partial<PageVersionsApi.List.Query>,
): Promise<{ items: PageVersion[]; count: number }> => {
  const query = new URLSearchParams({
    page: page + '',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('searchText', searchText);
  if (sortOrder) query.append('sortOrder', sortOrder);
  if (sortBy) query.append('sortBy', sortBy);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) query.append(filter, value);
  }

  const { data } = await clientWithToken.get<{
    body: {
      content: LegacyPageVersion[];
      totalElements: number;
    };
  }>(`${BASE_URL}/${pageId}/versions?${query}`);
  const { content, totalElements } = data.body;
  const items = content.map(migrateVersion.toUpdated);
  return { items, count: totalElements };
};

export const getPageVersion = async (
  pageId: string,
  versionId: string,
): Promise<PageVersion> => {
  const { data } = await clientWithToken.get<{ body: LegacyPageVersion }>(
    `${BASE_URL}/${pageId}/versions/${versionId}`,
  );
  return migrateVersion.toUpdated(data.body);
};

export const getDetailPageVersion = async (
  pageId: string,
  versionId: string,
): Promise<any> => {
  const data = await clientWithToken.get<{ body: DetailPageVersion }>(
    `${BASE_URL}/${pageId}/versions/${versionId}`,
  );
  return data;
};

export const getPageVersionSettings = async (
  pageId: string,
): Promise<PageDistributionSettings> => {
  const response = await clientWithToken.get<{
    body: PageDistributionSettings;
  }>(`${BASE_URL}/${pageId}/delivery-ratio`);
  return response.data.body;
};

export const getPageVersionSettingsData = async (
  pageId: string,
): Promise<PageDistributionSettings> => {
  const response = await clientWithToken.get<{
    body: PageDistributionSettings;
  }>(`${BASE_URL}/${pageId}/versions/settings`);
  return response.data.body;
};

export const updatePageDeliveryRatio = async (
  pageId: string,
  deliveryRatios: {
    deliveryRatio: number;
    makeupId: string;
    distributeEqually: boolean;
    pageId: string;
    isBaseVersion: boolean;
    isTestVersion: boolean;
    isActive: boolean;
  }[],
): Promise<PageDistributionSettings> => {
  const response = await clientWithToken.patch<{
    body: PageDistributionSettings;
  }>(`${BASE_URL}/${pageId}/delivery-ratio`, deliveryRatios);
  return response.data.body;
};

export const updatePageVersionSettings = async (
  pageId: string,
  payload: Partial<PageDistributionSettings>,
): Promise<
  | { isSuccess: true; data: PageDistributionSettings }
  | { isSuccess: false; message: string; isBilling: boolean }
> => {
  try {
    const response = await clientWithToken.patch<{
      body: PageDistributionSettings;
    }>(`${BASE_URL}/${pageId}/versions/settings`, payload);
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, status } = errorObj;
    return {
      message: errors?.[0]?.message,
      isBilling: status === 426,
      isSuccess: false,
    };
  }
};

export const toggleVersionActive = async (
  version: PageVersion,
): Promise<{ isSuccess: true } | { isSuccess: false; isBilling: boolean }> => {
  const payload = {
    versionRatioList: [
      {
        makeupId: version.id,
        pageId: version.pageId,
        isActive: version.status !== 'published',
      } as any,
    ],
  };

  return await updatePageVersionSettings(version.pageId, payload);
};

export const deletePageVersion = async (version: PageVersion) => {
  const prevExperimentIds = await getPageVersion(
    version.pageId,
    version.id,
  ).then((r) => r.experimentIds);

  const url = `${BASE_URL}/${version.pageId}/versions/${version.id}`;
  const result = await clientWithToken.delete<{ body: unknown }>(url, {});

  updateVersionExperimentTags({
    type: 'page',
    parentId: version.pageId,
    id: version.id,
    experimentIds: [],
    prevExperimentIds: prevExperimentIds,
  }).catch(console.error);
  return result;
};

export const createPageVersion = async (
  pageId: string,
  version: LegacyVersion.UpdateBody,
) => {
  const url = `${BASE_URL}/${pageId}/versions?creationType=NEXT`;
  const response = await clientWithToken.post<{ body: LegacyPageVersion }>(
    url,
    version,
  );
  if (response.data.body) {
    const { experimentIds, id: versionId } = response.data.body;

    updateVersionExperimentTags({
      type: 'page',
      parentId: pageId,
      id: versionId,
      experimentIds,
      prevExperimentIds: [],
    }).catch(console.error);
  }
  return migrateVersion.toUpdated(response.data.body);
};

export const updatePageVersion = async (
  pageId: string,
  versionId: string,
  body: LegacyVersion.UpdateBody,
) => {
  const prevExperimentIds = await getPageVersion(pageId, versionId).then(
    (r) => r.experimentIds,
  );

  const url = `${BASE_URL}/${pageId}/versions/${versionId}?creationType=NEXT`;
  const response = await clientWithToken.put<{ body: LegacyPageVersion }>(
    url,
    body,
  );

  const { experimentIds } = response.data.body;

  updateVersionExperimentTags({
    type: 'page',
    parentId: pageId,
    id: versionId,
    experimentIds,
    prevExperimentIds: prevExperimentIds,
  }).catch(console.error);

  return migrateVersion.toUpdated(response.data.body);
};

export const createPageVersionPreview = async (payload: {
  pageId: string;
  body: {
    id: string;
    templateId?: string;
    // TODO some functions call this with `IPersonalisedSection` but that's not accepted by the API. Someone just magically transformed anys and I don't want to fix it right now
    contentList: (
      | LegacyVersion.PostPersonalizedSection
      | LegacyVersion.PersonalizedSection
    )[];
    pathname: string;
  };
  eventType: 'AD_HOC' | 'MAKEUP';
}) => {
  try {
    const url = `${BASE_URL}/${payload.pageId}/versions/preview?eventType=${payload.eventType}`;
    const response = await clientWithToken.post<{ body: unknown }>(
      url,
      payload.body,
    );
    await new Promise((resolve) => setTimeout(resolve, 4000)); // Wait for 4 seconds
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (error: any) {
    const { errors } = error;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const publishPageVersion = async ({
  pageId,
  versionId,
  eventType,
  contentReference,
}: {
  pageId: string;
  versionId: string;
  eventType: string;
  contentReference: string;
}) => {
  const url = `${BASE_URL}/${pageId}/versions/${versionId}/publish?eventType=${eventType}&contentReference=${contentReference}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, status } = errorObj;
    return {
      message: errors?.[0]?.message,
      isBilling: status === 426,
      isSuccess: false,
    };
  }
};

export const countPageVersions = async (pageId: string) => {
  const url = `${BASE_URL}/${pageId}/versions/count`;
  const response = await clientWithToken.get<{
    body: {
      totalCount: number;
      contextualizeVersions: number;
      testVersion: number;
    };
  }>(url);
  return response.data.body;
};

export const updatePageVersionMetadata = async (
  pageId: string,
  versionId: string,
  body: Partial<LegacyPageVersion>,
) => {
  const prevExperimentIds = await getPageVersion(pageId, versionId).then(
    (r) => r.experimentIds,
  );
  const url = `${BASE_URL}/${pageId}/versions/${versionId}/contextualised-version?creationType=NEXT`;
  const response = await clientWithToken.patch<{ body: LegacyPageVersion }>(
    url,
    body,
  );

  updateVersionExperimentTags({
    type: 'page',
    parentId: pageId,
    id: versionId,
    experimentIds: response.data.body.experimentIds,
    prevExperimentIds: prevExperimentIds,
  }).catch(console.error);

  return migrateVersion.toUpdated(response.data.body);
};
