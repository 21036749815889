import React from 'react';

export const BackIcon = ({
  height = '32px',
  width = '32px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.7073 5.29292C20.8003 5.38579 20.874 5.49608 20.9244 5.61748C20.9747 5.73888 21.0006 5.869 21.0006 6.00042C21.0006 6.13184 20.9747 6.26196 20.9244 6.38336C20.874 6.50476 20.8003 6.61505 20.7073 6.70792L11.4136 16.0004L20.7073 25.2929C20.895 25.4806 21.0004 25.7351 21.0004 26.0004C21.0004 26.2658 20.895 26.5203 20.7073 26.7079C20.5197 26.8956 20.2652 27.001 19.9998 27.001C19.7344 27.001 19.4799 26.8956 19.2923 26.7079L9.29231 16.7079C9.19933 16.615 9.12557 16.5048 9.07525 16.3834C9.02493 16.262 8.99902 16.1318 8.99902 16.0004C8.99902 15.869 9.02493 15.7389 9.07525 15.6175C9.12557 15.4961 9.19933 15.3858 9.29231 15.2929L19.2923 5.29292C19.3852 5.19994 19.4955 5.12618 19.6169 5.07586C19.7383 5.02554 19.8684 4.99963 19.9998 4.99963C20.1312 4.99963 20.2614 5.02554 20.3827 5.07586C20.5041 5.12618 20.6144 5.19994 20.7073 5.29292Z"
      fill={color}
    />
  </svg>
);
