import { cloneElement, FC, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';

interface IPromptComponent {
  whenToActive: boolean;
  modal: JSX.Element;
}

const PromptComponent: FC<IPromptComponent> = ({ whenToActive, modal }) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const onNotNowClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(false);
  };

  const goBack = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  const promptProps = {
    goBack,
    onNotNowClick,
  };

  return (
    <>
      <Prompt
        when={whenToActive}
        message={(location: Location, action) => {
          if (!confirmedNavigation && whenToActive) {
            setModalVisible(true);
            setLastLocation(location);
            return false;
          }
          return true;
        }}
      />
      {modalVisible &&
        cloneElement(modal, {
          ...promptProps,
        })}
    </>
  );
};

export default PromptComponent;
