import { Page } from '@models/pages';
import { AttributeValueMap, Product, Variant } from '@models/product';
import { Mixpanel } from './mixpanel';
import { toContentList } from './productTemplate';
import { Template } from '@models/productTemplate';
import { createPageVersionPreview } from '@apis/pageVersions';
import { createVersionPreview } from '@apis/productVersions';
import { AssetCache } from '@components/ContentEditor';

export const generatePreview = async ({
  content,
  template,
  base,
  variant,
  storeUrl,
  versionId,
  assetCache,
}: {
  content: AttributeValueMap;
  template: Template;
  base: Product | Page;
  variant: Variant | Page;
  versionId: string;
  storeUrl: string;
  assetCache: AssetCache;
}) => {
  if (!(base && variant)) return;

  // Send mixpanel event
  Mixpanel.track('version_operation', {
    eventName: 'Generate version preview',
    kpi: 'Usage Grows',
    propertyType: 'event_property',
    versionId,
    ...('pageId' in base
      ? { pageId: base.pageId }
      : { productId: base.productId }),
  });

  const contentList = await toContentList(content, assetCache, template);
  contentList
    .find((l) => l.section_name === 'Master')
    ?.personalised_content.forEach((att) => {
      if (att.property_name === 'productName') att.property_name = 'title';
      else if (att.property_name === 'productDescription')
        att.property_name = 'description';
    });
  if ('pageId' in base) {
    await createPageVersionPreview({
      pageId: base.pageId,
      body: {
        id: versionId,
        templateId: template.id,
        contentList,
        pathname: base.pathname,
      },
      eventType: 'AD_HOC',
    }).then(() => {
      const previewUrl = `${storeUrl}${base.pathname}?versionId=${versionId}&prodport-preview=true`;
      window.open(previewUrl, '_blank');
    });
  } else {
    const response = await createVersionPreview({
      productId: base.productId,
      body: {
        id: versionId,
        templateId: template.id,
        contentList,
      },
      eventType: 'AD_HOC',
    });
    if (response?.isSuccess) {
      const previewUrl = `${storeUrl}/products/${
        base.handle || base.productId
      }?versionId=${versionId}&prodport-preview=true`;
      window.open(previewUrl, '_blank');
    }
  }
};
