import { CreateIcon } from '@components/Icons/CreateIcon';

import './CreateButton.scss';

interface ICreateButton {
  isDisabled?: boolean;
  onClick: () => void;
}

const CreateButton = ({ isDisabled, onClick }: ICreateButton) => {
  return (
    <button
      className={`CreateButton ${isDisabled ? 'disabled' : ''}`}
      onClick={onClick}
    >
      <CreateIcon />
    </button>
  );
};

export default CreateButton;
