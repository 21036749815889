import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { progressMap } from '@constants/progressMap';

import Card from '../Card';
import StatusTag from '../StatusTag';
import PrimaryButton from '../PrimaryButton';
import RoundCheckBox from '../RoundCheckbox';
import VideoOverlay from '../VideoOverlay';

import createContextualizeImage from '@assets/icons/createContextualizedVersion.svg';
import createContextCardImage from '@assets/icons/createContext.svg';
import createRichContent from '@assets/icons/createRichContent.svg';
import createDigitalAsset from '@assets/icons/createDigitalAsset.svg';

import './ListingCard.scss';

const moduleCreateImageMapping = {
  context: createContextCardImage,
  contextualizedProduct: createContextualizeImage,
  digitalAsset: createDigitalAsset,
  richContent: createRichContent,
  pageVersion: createContextualizeImage,
};

interface ListingCardProps {
  id: string;
  className?: string;
  data?: any;
  assetType?: string;
  assetUrl?: string;
  cardImageComponent?: JSX.Element;
  module:
    | 'context'
    | 'contextualizedProduct'
    | 'digitalAsset'
    | 'richContent'
    | 'pageVersion';
  icons?: any;
  type?: string;
  title?: string;
  isSelected?: boolean;
  showDeleteIcon?: boolean;
  showEditIcon?: boolean;
  disableRedirect?: boolean;
  redirectTo?: string;
  isSelectable?: boolean;
  isCreateCard?: boolean; // REMOVE THIS PROP LATER AS WE CAN USE createButtonText PROP FOR THIS
  bottomLabelText?: string;
  disabledText?: string;
  createButtonText?: string;
  showDot?: boolean;
  status?: string;
  secondarStatus?: {
    label: string;
    backgroundColor: string;
  };
  onCreateClick?: () => void;
  onDeleteClick?: () => void;
  onTitleClick?: () => void;
  onChange?: (item: any) => void;
}

const ListingCard: React.FC<ListingCardProps> = ({
  id,
  className,
  data,
  assetType = 'image',
  assetUrl,
  cardImageComponent,
  module,
  icons,
  type,
  title,
  isSelected,
  showDeleteIcon,
  showEditIcon,
  disableRedirect = false,
  redirectTo = '',
  isSelectable = false,
  isCreateCard = false,
  bottomLabelText = '',
  disabledText,
  createButtonText,
  showDot = true,
  status,
  secondarStatus,
  onCreateClick = () => null,
  onDeleteClick = () => null,
  onTitleClick = () => null,
  onChange,
}) => {
  const history = useHistory();

  const CardDetails = () => (
    <>
      {status && (
        <StatusTag
          id={`${id}-status`}
          showDot={showDot}
          backgroundColor={progressMap[status]?.backgroundColor ?? 'gray'}
          label={progressMap[status]?.label ?? ''}
          className="ListingCard__status"
        />
      )}
      {secondarStatus && (
        <StatusTag
          id={`${id}-secondarStatus`}
          backgroundColor={secondarStatus?.backgroundColor ?? 'gray'}
          label={secondarStatus?.label ?? ''}
          isTextUppercase={false}
          className="ListingCard__status ListingCard__status--position_right"
        />
      )}
      <label
        className={`ListingCard-label ${isCreateCard ? 'createCard' : ''}`}
      >
        {isSelectable && onChange && (
          <RoundCheckBox
            name="listing-card"
            checked={isSelected}
            onChange={() => onChange(data)}
          />
        )}

        {assetType === 'video' ? (
          <VideoOverlay>
            <img src={assetUrl} className={`ListingCard-${assetType}`} />
          </VideoOverlay>
        ) : (
          assetUrl && (
            <img
              className={`ListingCard-${assetType}`}
              src={
                assetUrl.indexOf('.mp4') != -1
                  ? assetUrl.replace('.mp4', '.jpg')
                  : assetUrl
              }
              alt={title}
            />
          )
        )}
        {isCreateCard && (
          <img
            src={moduleCreateImageMapping[module]}
            alt={`create-${module}`}
          />
        )}
        {cardImageComponent && cardImageComponent}
        {disabledText && (
          <div className="ListingCard-disabledLayer">
            <h3>{disabledText}</h3>
          </div>
        )}
        {bottomLabelText && (
          <div className="ListingCard-bottomLabel">{bottomLabelText}</div>
        )}
      </label>

      {isCreateCard && createButtonText ? (
        <PrimaryButton
          className="ListingCard-createNewButton"
          size="medium"
          label={createButtonText}
          id={`${id}-button`}
        />
      ) : (
        <>
          {!!icons && icons}
          <span className="ListingCard-assetType">{type}</span>
          {!disableRedirect && redirectTo ? (
            <Link
              to={redirectTo}
              className="ListingCard-title"
              id={`${id}-title`}
            >
              {title}
            </Link>
          ) : (
            <div
              onClick={onTitleClick}
              className="ListingCard-title"
              id={`${id}-title`}
            >
              {title}
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <Card
      id={id}
      className={`ListingCard ${disableRedirect ? 'no-pointer' : ''} ${
        className || ''
      }`}
      showShadowOnHover
      showDeleteIcon={showDeleteIcon}
      showEditIcon={showEditIcon}
      onDeleteClick={onDeleteClick}
      onEditClick={() => redirectTo && history.push(redirectTo)}
      {...(isCreateCard && onCreateClick && { onClick: onCreateClick })}
    >
      {isSelectable || createButtonText ? (
        <CardDetails />
      ) : (
        <Link to={redirectTo}>
          <CardDetails />
        </Link>
      )}
    </Card>
  );
};

export default ListingCard;
