import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import EndOfSearch from '../EndOfSearch';
import LoadingGroup from '../LoadingGroup';

interface UpdatedInfiniteScrollingProps {
  dataLength: number;
  scrollableTarget: string;
  hasMore: boolean;
  loader: React.ReactNode;
  endMessage: string;
  next: () => any;
  className?: string;
  numberOfLoaders?: number;
  isLoading?: boolean;
}

const UpdatedInfiniteScrolling: React.FC<UpdatedInfiniteScrollingProps> = ({
  dataLength,
  endMessage,
  hasMore,
  loader,
  next,
  scrollableTarget,
  children,
  className,
  numberOfLoaders = 4,
  isLoading,
}) => {
  return (
    <InfiniteScroll
      next={next}
      hasMore={hasMore}
      loader={<></>}
      dataLength={dataLength}
      endMessage={<EndOfSearch text={endMessage} />}
      scrollableTarget={scrollableTarget}
    >
      <div className={className}>
        {children}
        {(hasMore || isLoading) && (
          <LoadingGroup component={loader} numberOfCards={numberOfLoaders} />
        )}
      </div>
    </InfiniteScroll>
  );
};

export default UpdatedInfiniteScrolling;
