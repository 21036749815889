import { generatePreview } from '@utils/version';
import fallbackImage from '@assets/illustrations/57_NoImage.svg';
import ExperienceTargets from '@components/ExperienceTargets';
import useLegacyModal from '@components/ModalLayout/useModal';
import PrimaryButton from '@components/PrimaryButton';
import { MODAL_METADATA } from '@constants/productVersionModals';
import { Experience } from '@models/experience';
import {
  AnyAttributeValue,
  AttributeValueMap,
  Product,
  Variant,
} from '@models/product';
import { Template } from '@models/productTemplate';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RModal } from '@redux/slices/modal';
import AttributeField from './AttributeField';
import AttributeView from './AttributeView';
import { DigitalAssetBrief } from '@models/digital-asset';
import { getAsset } from '@apis/digitalAssets';
import PromptComponent from '@components/PromptComponent';
import DiscardChangesModal from './DiscardChangesModal';
import { Page } from '@models/pages';
import { RVersion } from '@redux/slices/version';

import './style.scss';

export type AssetCache = { [id: string]: DigitalAssetBrief };

const ContentEditor = ({
  experience,
  base,
  template,
  variant,
  referenceContent = {},
  baseFill,
  assetCacheRef,
}: {
  experience: Experience;
  base: Product | Page | null;
  template: Template;
  variant: Variant | Page | null;
  referenceContent?: AttributeValueMap;
  /** If attributes not on the version should be filled by the base */
  baseFill?: boolean;
  assetCacheRef?: MutableRefObject<AssetCache>;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fallbackVersionId = useMemo(
    () =>
      crypto.randomUUID() ??
      Math.round(Math.random() * Number.MAX_SAFE_INTEGER) + '',
    [],
  );
  const { storeUrl } = useSelector((state) => state.tenant);

  const { isContentChanged, content } = useSelector((s) => s.version);

  /** A cache for full assets from asset IDs */
  const [assetCache, setAssetCache] = useState<AssetCache>({});
  const caching = useMemo(() => new Set<string>(), []);
  useEffect(
    () => void (assetCacheRef && (assetCacheRef.current = assetCache)),
    [assetCache],
  );

  const cacheAssets = (attributes: AttributeValueMap) => {
    const assetIds = new Set<string>();
    for (const attribute in attributes) {
      const value = attributes[attribute];
      if (typeof value !== 'string' && attribute !== 'richContent')
        value.forEach((id) => assetIds.add(id));
    }
    // No assets found
    if (assetIds.size === 0) return;
    setAssetCache((prev) => {
      for (const assetId of Array.from(assetIds)) {
        if (caching.has(assetId)) continue;
        caching.add(assetId);
        getAsset(assetId).then((asset) => {
          setAssetCache((prev) => {
            prev[assetId] = asset;
            return { ...prev };
          });
        });
      }
      return prev;
    });
  };

  useEffect(() => cacheAssets(content), [content]);
  useEffect(
    () => (variant ? cacheAssets(variant.attributes) : void 0),
    [variant],
  );

  const customizableAttributes = template.attributes.filter(
    (attribute) => attribute.customizable,
  );
  const experienceAttributes = customizableAttributes.filter((attribute) => {
    const list = experience.attributes;
    const name = attribute.name;
    const nonVariantName = name.substring(0, name.indexOf('_'));
    return list.includes(name) || list.includes(nonVariantName);
  });

  const { pageVersions, productVersion } = useSelector((s) => s);

  const versionId =
    productVersion.version?.id ||
    pageVersions.currentItem?.id ||
    fallbackVersionId;

  return (
    <>
      <div className="content-editor">
        <div className="top-box">
          <div className="info">
            <div className="experience">
              <ExperienceTargets experience={experience} />
              <h3>
                <span>
                  {capitalizeFirstLetter(t('contentEditor.testingFor'))}
                </span>
                <span>{experience.title}</span>
              </h3>
            </div>
            <div className="product">
              <img
                src={base?.thumbnail || fallbackImage}
                alt={'Product Thumbnail'}
              />
              <h3>
                <span>
                  1{' '}
                  {capitalizeEveryFirstLetter(
                    t(
                      'global.' +
                        (base && 'pageId' in base ? 'page' : 'product'),
                    ),
                  )}
                </span>
                <span>{base?.title}</span>
              </h3>
            </div>
          </div>
          <div className="content">
            <h4>
              {capitalizeEveryFirstLetter(t('contentEditor.versionContent'))}
            </h4>
            {experienceAttributes.map((attribute, i) => {
              let reference = referenceContent[attribute.name];
              // TODO Variants | This is only to bridge no variant assets being present on product
              if (!reference && attribute.name.startsWith('assets_'))
                reference = referenceContent['assets'];
              return (
                <AttributeField
                  attribute={attribute}
                  key={i}
                  value={content[attribute.name]}
                  assetCache={assetCache}
                  referenceContent={reference}
                />
              );
            })}
          </div>
        </div>
        <div className="Card compare-view">
          <div className="heading">{t('contentEditor.heuristic_vs_base')}</div>
          <div className="compare">
            <div className="version">
              <div className="info">
                <h4>
                  {capitalizeEveryFirstLetter(t('contentEditor.testVersion'))}
                </h4>
                <PrimaryButton
                  className="preview-button"
                  label={capitalizeEveryFirstLetter(t('global.preview'))}
                  size="small"
                  variant="green"
                  onClick={() => {
                    if (!(base && variant)) return;
                    dispatch(RModal.open({ type: 'VersionPreviewModal' }));
                    generatePreview({
                      content,
                      base,
                      variant,
                      versionId,
                      template,
                      storeUrl,
                      assetCache,
                    })
                      .catch(() => {})
                      .then(() => dispatch(RModal.close()));
                  }}
                />
              </div>
              <div className="content">
                {customizableAttributes.map((attribute, i) => {
                  const { name } = attribute;
                  let value: AnyAttributeValue | undefined = content[name];
                  if (
                    !value &&
                    baseFill !== false &&
                    !experienceAttributes.includes(attribute) &&
                    variant
                  ) {
                    value = variant.attributes[name];
                    if (!value && name.startsWith('assets_'))
                      value = variant.attributes['assets'];
                  }
                  return (
                    <AttributeView
                      attribute={attribute}
                      value={value}
                      assetCache={assetCache}
                      key={i}
                    />
                  );
                })}
              </div>
            </div>
            <div className="version">
              <div className="info">
                <h4>
                  {capitalizeEveryFirstLetter(t('contentEditor.baseVersion'))}
                </h4>
              </div>
              <div className="content">
                {variant ? (
                  customizableAttributes.map((attribute, i) => {
                    let value = variant.attributes[attribute.name];
                    // TODO Variants | This is only to bridge no variant assets being present on product
                    if (!value && attribute.name.startsWith('assets_'))
                      value = variant.attributes['assets'];
                    return (
                      <AttributeView
                        attribute={attribute}
                        value={value}
                        assetCache={assetCache}
                        key={i}
                      />
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PromptComponent
        whenToActive={isContentChanged}
        modal={
          <DiscardChangesModal
            discardChanges={() => dispatch(RVersion.setContent({}))}
          />
        }
      />
    </>
  );
};

export default ContentEditor;
