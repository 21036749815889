import React from 'react';

interface ActionButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  id?: string;
  link?: boolean;
  round?: number;
  background?: string;
  border?: string;
  padding?: string;
  disabled?: boolean;
  className?: string;
  action?: () => unknown;
}
export default function Button({
  id,
  round,
  background = 'linear-gradient(270deg, #0BC4FF 2.12%, #01DD86 96.61%)',
  border = '0',
  padding = '4px 24px',
  children,
  className,
  disabled,
  action,
}: ActionButtonProps) {
  return (
    <button
      style={{
        background,
        padding,
        outline: 'none',
        border,
        borderRadius: round,
        opacity: disabled ? 0.4 : 1,
      }}
      id={id}
      disabled={disabled}
      className={className}
      onClick={() => action && action()}
    >
      {children}
    </button>
  );
}
