import { useTranslation } from 'react-i18next';

import ModalLayout from '../ModalLayout';
import PrimaryButton from '../PrimaryButton';
import { capitalizeFirstLetter } from '@utils/textTransform';

import { AlertIcon } from '@components/Icons/AlertIcon';

import './ConfirmationModal.scss';
import { CrossIcon } from '@components/Icons/CrossIcon';

interface ConfirmationModalProps {
  title: string;
  statement: any;
  modalIcon?: JSX.Element;
  showModal: boolean;
  setShowModal: Function;
  buttons: Array<ActionButtonProps>;
  clickHandlerParams?: any;
  clickHandler: Function;
  className?: string;
  cancelHandler?: any;
  isDanger?: boolean;
  defaultClose?: boolean;
  customComponent?: JSX.Element;
}

export interface ActionButtonProps {
  color?: 'gradient' | 'gray' | 'green' | 'red';
  text: string;
  type?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalIcon,
  isDanger = false,
  showModal,
  setShowModal,
  title,
  statement,
  buttons,
  clickHandler,
  cancelHandler = () => setShowModal(!showModal),
  clickHandlerParams,
  className = '',
  defaultClose = false,
  customComponent,
}) => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      defaultClose={defaultClose}
      className={className}
    >
      <div className="cross">
        <button className="cross-btn" onClick={cancelHandler}>
          <CrossIcon />
        </button>
      </div>
      <div className="ConfirmationModal">
        <div className="ConfirmationModal_icon">
          {modalIcon ? (
            modalIcon
          ) : (
            <AlertIcon
              fill={`var(${isDanger ? '--sizzling-red' : '--emerald'})`}
            />
          )}
        </div>
        <h6 className="ConfirmationModal_title">{title}</h6>
        <p className="ConfirmationModal_description">{statement}</p>
        {customComponent || null}
        <div className="ConfirmationModal_actions">
          {buttons.map((button: ActionButtonProps) =>
            button.type === 'cancel' ? (
              <PrimaryButton
                key={button.text}
                label={
                  button.text
                    ? button.text
                    : capitalizeFirstLetter(`${t('global.no_cancel')}`)
                }
                size="small"
                variant={button.color || 'red'}
                onClick={cancelHandler}
              />
            ) : (
              <PrimaryButton
                key={button.text}
                label={button.text}
                size="small"
                variant={button.color || 'green'}
                onClick={() => clickHandler(clickHandlerParams)}
              />
            ),
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmationModal;
