import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../RichContentSectionHeader';
import { TestimonialIcon } from '@components/Icons/TestimonialIcon';
import SectionFiedsFactory from '../../[id]/template/SectionFieldsFactory';

import { Attribute, IRichContentSection } from '@models/rich-content';
import { characterLimits } from '../../../../heuristic.config';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import './style.scss';

const Testimonials: React.FC<IRichContentSection> = ({
  index,
  length,
  moveSection,
  permissions,
  attributes,
  handleChange,
}) => {
  const { t } = useTranslation();

  const { authorTextLimit, authorAttributionLimit } =
    characterLimits.richContent;

  const sectionFieldsFactory = (
    id: string,
    key: string,
    attribute: Attribute,
    characterLimits = 0,
  ) => (
    <SectionFiedsFactory
      id={id}
      key={key}
      attributeKey={key}
      sectionOrder={index}
      attribute={attribute}
      permissions={permissions}
      handleChange={handleChange}
      characterLimits={characterLimits}
    />
  );

  return (
    <>
      <div
        className={`RichContentTestimonials ${
          permissions.AUTHOR_CONTENT ? '' : 'RichContentTemplate__readOnly'
        }`}
      >
        <SectionHeader
          title={capitalizeEveryFirstLetter(t('rich_content.testimonials'))}
          moveSection={moveSection}
          showDropdown={permissions.AUTHOR_CONTENT}
          index={index}
          length={length}
          icon={<TestimonialIcon />}
        />
        <div className="RichContentTestimonials__container">
          <div className="RichContentTestimonials__container--attribution">
            {sectionFieldsFactory(
              `testimonialAuthor${index}`,
              'testimonialAuthor',
              attributes['testimonialAuthor'],
              authorAttributionLimit,
            )}
          </div>
          <div className="RichContentTestimonials__container--testimonial">
            {sectionFieldsFactory(
              `testimonialText${index}`,
              'testimonialText',
              attributes['testimonialText'],
              authorTextLimit,
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(Testimonials);
