import React from 'react';

import './ActionOverlay.scss';

export interface IAction {
  id: string;
  icon: JSX.Element;
  tipText: string;
  position?: 'top' | 'center' | 'bottom';
  handler?: any;
  showAction?: boolean;
}

interface IActionOverlay extends React.HTMLAttributes<HTMLDivElement> {
  actions: IAction[];
  className?: string;
  children: any;
  disabled?: boolean;
}

const Action: React.FC<IAction> = ({
  id,
  icon,
  tipText,
  position = '',
  handler = () => null,
  showAction = true,
}) => {
  return (
    <>
      {showAction && (
        <div
          role="button"
          className={`ActionOverlay__container___actions__action ${position}`}
          onClick={handler}
          title={tipText}
        >
          {icon}
        </div>
      )}
    </>
  );
};

const ActionOverlay: React.FC<IActionOverlay> = ({
  actions,
  children,
  className = '',
  disabled = false,
}) => {
  return (
    <div className={`ActionOverlay ${className}`}>
      {children}
      {!disabled && (
        <div className="ActionOverlay__container">
          <div className="ActionOverlay__container___actions">
            {actions.map((action: IAction) => (
              <Action
                key={action.id}
                id={action.id}
                icon={action.icon}
                tipText={action.tipText}
                position={action.position}
                handler={action.handler}
                showAction={action.showAction}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionOverlay;
