import React from 'react';
import Card from '../Card';
import SkeletonText from '../SkeletonText';

import './style.scss';

const LoadingCard: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <Card className={`LoadingCard ${className ?? ''}`} {...props}>
      <SkeletonText className="LoadingCard__image" />
      <SkeletonText className="LoadingCard__title" />
      <SkeletonText className="LoadingCard__subTitle" />
      <SkeletonText className="LoadingCard__description" />
    </Card>
  );
};

export default LoadingCard;
