import './style.scss';

const MultiToggle = <Options extends readonly string[]>({
  options,
  selected,
  selectedColor,
  setSelected,
}: {
  options: Options;
  selected: Options[number];
  selectedColor: string;
  setSelected: (newSelected: Options[number]) => any;
}) => {
  return (
    <span className="multi-toggle-option">
      {options.map((option) => {
        const active = selected === option;

        return (
          <label
            className={'multi-toggle-option-item' + (active ? ' active' : '')}
            htmlFor={'multi-toggle-option-' + option}
            style={active ? { backgroundColor: selectedColor } : {}}
            key={option}
          >
            {option}
            <input
              type="radio"
              id={'multi-toggle-option-' + option}
              name={'multi-toggle-options'}
              value={option}
              checked={active}
              onChange={() => setSelected(option)}
            ></input>
          </label>
        );
      })}
    </span>
  );
};

export default MultiToggle;
