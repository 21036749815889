import React from 'react';
import editPenIcon from '@assets/icons/edit-pen.svg';
import editPenWhiteIcon from '@assets/icons/edit-pen-white.svg';
import trashCanIcon from '@assets/icons/trash-can.svg';

import './Card.scss';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  showEditIcon?: boolean;
  showDeleteIcon?: boolean;
  showShadowOnHover?: boolean;
  noShadow?: boolean;
  showActiveState?: boolean;
  ref?: any;
  onDeleteClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onEditClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  ref,
  children,
  className,
  showEditIcon = false,
  showDeleteIcon = false,
  showShadowOnHover = false,
  noShadow = false,
  showActiveState = false,
  onDeleteClick = () => null,
  onEditClick = () => null,
  ...props
}) => {
  return (
    <div
      ref={ref}
      className={`Card ${className ? className : ''} ${
        showEditIcon || showDeleteIcon ? 'show-icons' : ''
      } ${showShadowOnHover ? 'show-shadow' : ''} ${
        showActiveState ? 'show-active' : ''
      } ${noShadow ? 'hide-shadow' : ''}`}
      {...props}
    >
      <>{children}</>
      {(showEditIcon || showDeleteIcon) && (
        <div className="Card-actions">
          {showEditIcon && (
            <div className="Card-actions__action" onClick={onEditClick}>
              <img alt="edit" src={editPenIcon} />
            </div>
          )}
          {showDeleteIcon && (
            <div className="Card-actions__action" onClick={onDeleteClick}>
              <img alt="delete" src={trashCanIcon} />
            </div>
          )}
        </div>
      )}
      {showActiveState && (
        <div className="Card-activeIcon">
          <div className="Card-activeIcon__container">
            <img alt="edit" src={editPenWhiteIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
