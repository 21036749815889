import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';

import './PrimaryButton.scss';

export type PrimaryButtonVariant =
  | 'gradient'
  | 'gray'
  | 'green'
  | 'red'
  | 'white';

interface IPrimaryButton
  extends React.DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'small' | 'medium' | 'large';
  label: string;
  className?: string;
  disabled?: boolean;
  variant?: PrimaryButtonVariant;
  children?: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PrimaryButton: React.FC<IPrimaryButton> = ({
  id,
  className = '',
  disabled = false,
  label,
  size = 'medium',
  variant = 'gradient',
  children,
  onClick = () => null,
}) => (
  <button
    className={`PrimaryButton-${size}__${variant} ${
      disabled ? 'disabled' : ''
    } ${className ? className : ''}`}
    disabled={disabled}
    onClick={onClick}
    id={id}
  >
    {label}
    {children}
  </button>
);

export default PrimaryButton;
