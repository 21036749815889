import { FC } from 'react';

import Spinner from './Spinner';

import './style.scss';

const FullScreenSpinner: FC = () => (
  <div className="FullScreenSpinner">
    <Spinner />
  </div>
);

export default FullScreenSpinner;
