import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { Modal, RModal } from '@redux/slices/modal';
import ReactHtmlParser from 'html-react-parser';
import { CloseIcon } from '../Icons/CloseIcon';
import { AlertIcon } from '../Icons/AlertIcon';
import PrimaryButton from '../PrimaryButton';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { SkeletonSpan } from '@components/SkeletonText';
import fiveSecondCountdown from '@assets/illustrations/fiveSecondCountdown.gif';

import './style.scss';

import './typeB.scss';
import './typeA.scss';
import './ConfirmModal.scss';
import './TextInputModal.scss';
import './UsageModal.scss';
import './VersionPreviewModal.scss';

const ModalComponents: {
  [type in Modal['type']]: (
    props: Extract<Modal, { type: type }>,
  ) => JSX.Element;
} = {
  typeA: (props) => {
    const { children } = props;
    const dispatch = useDispatch();

    const closeModalIconClick = () => {
      dispatch(RModal.close());
    };

    return (
      <div className="type-a-modal">
        <CloseIcon
          className="close-model-icon"
          onClick={closeModalIconClick}
          role="button"
        />
        {props.icon}
        <h4>{props.heading}</h4>
        <p>{ReactHtmlParser(props?.supportingText || '')}</p>
        {children}
      </div>
    );
  },
  typeB: (props) => {
    const { children } = props;

    return (
      <div className={`type-b-modal ${props?.className || ''}`}>
        {props?.icon ? (
          { icon: props.icon }
        ) : (
          <AlertIcon
            fill={`var(${props?.danger ? '--sizzling-red' : '--emerald'})`}
          />
        )}
        <div className="modal-text-container">
          <h6>{props?.heading}</h6>
          <p>{props?.supportingText} </p>
        </div>
        {children}
        <div className="modal-buttons-container">
          {props?.buttons.map((button, index) => (
            <PrimaryButton
              label={button.text}
              size="small"
              variant={button.variant || 'gray'}
              onClick={button.method}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  },
  ConfirmModal: (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const icon =
      props.icon === 'danger' ? (
        <AlertIcon width="2.6875rem" fill="var(--sizzling-red)" />
      ) : (
        props.icon
      );

    return (
      <div className="ConfirmModal ">
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
        {icon}
        <h6>{props.heading}</h6>
        <p>{props.description}</p>
        <div>
          <PrimaryButton
            label={props.cancelLabel || t('global.cancel')}
            variant="gray"
            size="small"
            onClick={() => dispatch(RModal.close())}
          />
          <PrimaryButton
            label={props.confirmLabel}
            variant={props.icon === 'danger' ? 'red' : 'green'}
            size="small"
            onClick={props.onConfirm}
          />
        </div>
      </div>
    );
  },
  TextInputModal: (props) => {
    const [text, setText] = useState('');
    const dispatch = useDispatch();

    return (
      <div className="TextInputModal">
        {props.icon}
        <input
          type="text"
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <PrimaryButton
          label={props.submitLabel}
          variant="green"
          size="small"
          onClick={() => props.onSubmit(text.trim())}
          disabled={text.length === 0}
        />
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
      </div>
    );
  },
  UsageModal: (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [items, setItems] = useState<
      Array<{ label: string; link: string } | ReactNode>
    >([]);

    useEffect(() => {
      const newItems: typeof items = [];
      for (let i = 0; i < props.usages.length; ++i) {
        const usage = props.usages[i];
        if (usage instanceof Promise) {
          newItems.push(<SkeletonSpan />);
          usage.then((res) => {
            newItems[i] = res;
            setItems([...newItems]);
          });
        } else newItems.push(usage);
      }
    }, [props.usages]);

    return (
      <div className="UsageModal">
        <button onClick={() => dispatch(RModal.close())}>
          <CloseIcon />
        </button>
        <h6>{props.heading}</h6>
        <p>{props.description}</p>
        <table>
          <thead>
            <tr>
              <td>{capitalizeEveryFirstLetter(t('global.usages'))}</td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  {item && typeof item === 'object' && 'label' in item ? (
                    <a href={item.link} target="_blank">
                      {item.label}
                    </a>
                  ) : (
                    item
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PrimaryButton
          label={capitalizeEveryFirstLetter(t('global.ok'))}
          variant="gray"
          size="small"
          onClick={() => dispatch(RModal.close())}
        />
      </div>
    );
  },
  VersionPreviewModal: () => {
    const { t } = useTranslation();
    return (
      <div className="VersionPreviewModal">
        <img src={fiveSecondCountdown} alt="Preview will we generated soon" />
        <h5>{t('product_version.generating_preview')}</h5>
        <p>
          {t('product_version.generating_preview_statement')}
          <strong>
            {' '}
            {t('product_version.generating_preview_pop_up_blocker')}
          </strong>
        </p>
      </div>
    );
  },

  custom: (props) => <>{props.children}</>,
};

const Modals: React.FC = () => {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.modals);
  const location = useLocation();

  useEffect(() => {
    // If the user navigates somewhere else close the modal
    if (location && current) {
      dispatch(RModal.close());
    }
  }, [location]);

  // If no modal is open or modalElement not found, return
  if (!current) return null;

  // TODO better ts type
  const ModalComponent = ModalComponents[current.type] as any;

  return (
    <div id="full-screen-model-with-redux">
      <div className="modal-with-redux" id="modal-with-redux-backdrop">
        <ModalComponent {...current} />
      </div>
    </div>
  );
};
export default Modals;
