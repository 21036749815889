import React from 'react';

export const AuthorContentIcon = ({
  height = '60px',
  width = '60px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5114 17.6719H5.16728C4.59252 17.672 4.04133 17.9004 3.63491 18.3068C3.22849 18.7132 3.00012 19.2644 3 19.8392H3C3 20.414 3.22832 20.9653 3.63475 21.3718C4.04119 21.7783 4.59244 22.0068 5.16728 22.0069H35.5114C35.796 22.0069 36.0779 21.9508 36.3408 21.8419C36.6038 21.7329 36.8427 21.5732 37.044 21.3719C37.2453 21.1706 37.4049 20.9317 37.5138 20.6687C37.6227 20.4057 37.6787 20.1238 37.6786 19.8392C37.6786 19.5545 37.6226 19.2727 37.5137 19.0098C37.4047 18.7468 37.2451 18.5079 37.0438 18.3067C36.8426 18.1054 36.6037 17.9458 36.3407 17.8368C36.0778 17.7279 35.796 17.6719 35.5114 17.6719"
      fill="#B3F5DB"
    />
    <path
      d="M35.5114 9H5.16728C4.59252 9.00012 4.04133 9.22849 3.63491 9.63491C3.22849 10.0413 3.00012 10.5925 3 11.1673C3 11.7421 3.22832 12.2934 3.63475 12.6999C4.04119 13.1064 4.59244 13.3349 5.16728 13.335H35.5114C35.796 13.335 36.0779 13.2789 36.3408 13.17C36.6038 13.061 36.8427 12.9014 37.044 12.7001C37.2453 12.4988 37.4049 12.2598 37.5138 11.9968C37.6227 11.7338 37.6787 11.4519 37.6786 11.1673C37.6786 10.8827 37.6226 10.6008 37.5137 10.3379C37.4047 10.075 37.2451 9.83603 37.0438 9.63478C36.8426 9.43353 36.6037 9.27389 36.3407 9.16497C36.0778 9.05606 35.796 9 35.5114 9Z"
      fill="#B3F5DB"
    />
    <path
      d="M35.5114 26.3359H5.16728C4.59252 26.3361 4.04133 26.5644 3.63491 26.9708C3.22849 27.3773 3.00012 27.9285 3 28.5032H3C3 29.0781 3.22832 29.6294 3.63475 30.0359C4.04119 30.4424 4.59244 30.6708 5.16728 30.6709H35.5114C35.796 30.6709 36.0779 30.6149 36.3408 30.5059C36.6038 30.397 36.8427 30.2373 37.044 30.036C37.2453 29.8347 37.4049 29.5957 37.5138 29.3327C37.6227 29.0697 37.6787 28.7879 37.6786 28.5032C37.6786 28.2186 37.6226 27.9368 37.5137 27.6738C37.4047 27.4109 37.2451 27.172 37.0438 26.9707C36.8426 26.7695 36.6037 26.6098 36.3407 26.5009C36.0778 26.392 35.796 26.3359 35.5114 26.3359"
      fill="#B3F5DB"
    />
    <path
      d="M19.1581 35.0078H5.16728C4.59248 35.0078 4.04122 35.2362 3.63478 35.6426C3.22834 36.049 3 36.6003 3 37.1751C2.99994 37.4597 3.05596 37.7416 3.16485 38.0046C3.27374 38.2676 3.43337 38.5066 3.63463 38.7079C3.83588 38.9092 4.07482 39.0688 4.3378 39.1778C4.60077 39.2867 4.88263 39.3428 5.16728 39.3428H19.1581C19.4427 39.3428 19.7246 39.2867 19.9876 39.1778C20.2506 39.0688 20.4895 38.9092 20.6908 38.7079C20.892 38.5066 21.0516 38.2676 21.1605 38.0046C21.2694 37.7416 21.3254 37.4597 21.3254 37.1751C21.3254 36.6003 21.097 36.049 20.6906 35.6426C20.2842 35.2362 19.7329 35.0078 19.1581 35.0078Z"
      fill="#B3F5DB"
    />
    <path
      d="M19.8034 34.0625L18.2969 38.3901L22.6249 36.8839C22.3646 36.2504 21.9813 35.6747 21.4969 35.1904C21.0126 34.7061 20.437 34.3227 19.8034 34.0625Z"
      fill="#34E49E"
    />
    <path
      d="M19.8047 34.055C20.4383 34.3152 21.0139 34.6986 21.4982 35.1829C21.9825 35.6673 22.3659 36.2429 22.6261 36.8765L25.939 35.723C25.5797 34.5485 24.9376 33.4802 24.0691 32.6118C23.2006 31.7433 22.1322 31.1014 20.9577 30.7422L19.8047 34.055Z"
      fill="white"
    />
    <path
      d="M41.973 19.689L41.9971 19.665L44.689 16.973C44.3298 15.7986 43.6878 14.7303 42.8194 13.8618C41.9509 12.9934 40.8826 12.3514 39.7082 11.9922L36.9922 14.7082C38.1666 15.0674 39.2349 15.7094 40.1034 16.5778C40.9718 17.4463 41.6138 18.5146 41.973 19.689Z"
      fill="white"
    />
    <path
      d="M25.9361 35.7313L41.9704 19.6971C41.6043 18.5257 40.9632 17.4589 40.1007 16.5859L24.0664 32.6202C24.9285 33.4936 25.5695 34.5602 25.9361 35.7313Z"
      fill="#34E49E"
    />
    <path
      d="M36.9913 14.7109L20.957 30.7452C22.1281 31.1122 23.1947 31.7533 24.0681 32.6154L40.1024 16.5807C39.2292 15.7183 38.1625 15.0773 36.9913 14.7109Z"
      fill="#80EEC3"
    />
  </svg>
);
