import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { IUtm, IUtms } from '@models/utm';
import { toast } from '@components/ToastNotification/ToastManager';
import { IBackendErrorMessageFormat } from '@models/errorMessage';
import { listUtms } from '@apis/utm';

type UtmsState = {
  utms: IUtms | null;
  totalCount: number;
  loading: boolean;
  page: number;
  searchQuery: string;
  selectedUtms: {
    [key: string]: IUtm;
  };
  errors?: IBackendErrorMessageFormat;
};

const initialState: UtmsState = {
  utms: null,
  totalCount: 0,
  loading: true,
  page: 0,
  selectedUtms: {},
  searchQuery: '',
};

const { actions, reducer } = createSlice({
  name: 'utm',
  initialState,
  reducers: {
    FETCH_UTMS_DONE: (
      state,
      { payload }: { payload: { data: IUtm[]; merge: boolean } },
    ) => {
      const utms = state.utms || [];
      const { data, merge } = payload;
      state.utms = merge ? [...utms, ...data] : [...data];
    },
    SET_UTMS_COUNT: (state, { payload }: { payload: number }) => {
      state.totalCount = payload;
    },
    SET_UTMS_PAGE: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
    RESET_UTMS_PAGE: (state) => {
      state.page = 0;
    },
    SELECT_OR_DESELECT_UTM: (
      state,
      { payload }: { payload: { [key: string]: IUtm } },
    ) => {
      state.selectedUtms = payload;
    },
    // TODO this is an absolute mess and should be split into separate reducers
    UPDATE_TABLE_ROW_LOCALLY: (
      state,
      {
        payload,
      }: {
        payload: { utmIds?: string[] } & (
          | { utm: IUtm; type: 'create' | 'update' }
          | { type: 'delete' | 'refresh' }
        );
      },
    ) => {
      const utms = state.utms ? [...state.utms] : [];
      const { type } = payload;
      const utmIds = payload.utmIds || [];
      if (type === 'delete') {
        const filteredUtms = utms.filter(
          (currentUtm) =>
            !(utmIds.findIndex((item) => item === currentUtm.utm_id) > -1),
        );
        state.utms = [...filteredUtms];
        state.totalCount = state.totalCount - utmIds.length;
      } else if (type === 'create') {
        utms.unshift(payload.utm);
        (state.utms = [...utms]), (state.totalCount = state.totalCount + 1);
      } else if (type === 'update') {
        const filteredUtms = utms.filter(
          (currentUtm) => currentUtm.utm_id != payload.utm.utm_id,
        );
        filteredUtms.unshift(payload.utm);
        state.utms = [...filteredUtms];
      } else {
        state.utms = [...(state.utms || [])];
      }
    },
    DESELECT_ALL_UTMS: (state) => {
      state.selectedUtms = {};
    },
    UTM_SAVE_SEARCH_QUERY: (state, { payload }: { payload: string }) => {
      state.searchQuery = payload;
      state.page = 0;
    },
    SET_UTMS_LOADING: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    RESET_UTMS: (state) => {
      Object.assign(state, initialState);
    },
  },
});

const thunks = {
  fetchUtmsSaga:
    (utmName?: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(RUtm.SET_UTMS_LOADING(true));

        const { content, totalElements, number } = await listUtms(0, utmName);

        dispatch(RUtm.SET_UTMS_COUNT(totalElements));
        dispatch(RUtm.FETCH_UTMS_DONE({ merge: false, data: content }));
        dispatch(RUtm.SET_UTMS_PAGE(number));
      } catch (errorObj: any) {
        const { errors } = errorObj;
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      } finally {
        dispatch(RUtm.SET_UTMS_LOADING(false));
      }
    },
  fetchUtmsAgainSaga:
    (utmName: string | undefined, page: number): AppThunk =>
    async (dispatch) => {
      try {
        const { content, number } = await listUtms(page, utmName);

        dispatch(RUtm.FETCH_UTMS_DONE({ merge: true, data: content }));
        dispatch(RUtm.SET_UTMS_PAGE(number));
      } catch (errorObj: any) {
        const { errors } = errorObj;
        dispatch(RUtm.RESET_UTMS_PAGE());
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RUtm = Object.assign(actions, thunks);

export default reducer;
