import React from 'react';
import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RModal } from '@redux/slices/modal';

import {
  approveTask,
  feedBackOnTask,
  fetchRichContent,
} from '@apis/richContent';
import { RRichContent } from '@redux/slices/richContent';
import { STATUS_MAP } from '@constants/status';
import { capitalizeFirstLetter } from '@utils/textTransform';

import PrimaryButton from '@components/PrimaryButton';
import TextareaWithoutBorder from '@components/TextareaWithBorder';
import { AuthorContentIcon } from '@components/Icons/AuthorContentIcon';
import { AssetPlaceholder } from '@components/Icons/AssetPlaceholder';

const TemplateSelectionFooter: React.FC<any> = ({
  permissions,
  id,
  taskAssigneeData,
  submitTemplateData,
  status,
}) => {
  const { t } = useTranslation();
  const contentType = useRef<any>(null);
  const dispatch = useDispatch();
  const [feedBackText, setFeedBackText] = useState('');
  const history = useHistory();

  const { status: authorStatus } = taskAssigneeData['AUTHOR_CONTENT'] || {
    status: STATUS_MAP['TO_DO'],
  };
  const { status: visualAssetStatus } = taskAssigneeData['VISUAL_ASSETS'] || {
    status: STATUS_MAP['TO_DO'],
  };
  const { status: reviewerStatus } = taskAssigneeData['REVIEW'] ||
    taskAssigneeData['REVIEW_VISUAL_ASSET'] ||
    taskAssigneeData['REVIEW_CONTENT'] || {
      status: STATUS_MAP['PENDING'],
    };

  const onCancelModal = () => {
    dispatch(RModal.close());
  };

  const sendFeedBack = () => {
    feedBackOnTask({
      contentId: id,
      taskId: taskAssigneeData[contentType.current]?.id,
    });
    onCancelModal();
  };

  const isAnyTaskApproved = () => {
    return (
      taskAssigneeData['AUTHOR_CONTENT'].status === STATUS_MAP['COMPLETED'] ||
      taskAssigneeData['VISUAL_ASSETS'].status === STATUS_MAP['COMPLETED']
    );
  };

  const feedBack = (type: string) => {
    contentType.current = type;
    dispatch(
      RModal.open({
        type: 'typeB',
        icon: <AuthorContentIcon />,
        danger: false,
        className: 'feedback-richcontent-template-model',
        heading:
          type === 'AUTHOR_CONTENT'
            ? capitalizeFirstLetter(
                t('taskMaster.feedback_button', {
                  for: t('taskMaster.visual_assets'),
                }),
              )
            : capitalizeFirstLetter(
                t('taskMaster.feedback_button', {
                  for: t('taskMaster.author_content'),
                }),
              ),
        supportingText: t('taskMaster.enter_comment'),
        buttons: [
          {
            text: capitalizeFirstLetter(t('global.cancel')),
            variant: 'gray',
            method: onCancelModal,
          },
          {
            text: capitalizeFirstLetter(t('forms.submit')),
            variant: 'green',
            method: sendFeedBack,
          },
        ],
        children: feedbackInput,
      }),
    );
  };
  const handlerChange = (event: any) => {
    setFeedBackText(event.target.value);
    dispatch(RRichContent.UPDATE_FEEDBACK_CONTENT(event.target.value));
  };
  const feedbackInput = (
    <TextareaWithoutBorder
      className="RichContentTemplate__feedbackTextArea"
      value={feedBackText}
      onChange={handlerChange}
    />
  );
  const doneHandler = async () => {
    await fetchRichContent({ pageNumber: 1, pageSize: 20, ids: [id] }).then(
      (response) => dispatch(RRichContent.SET_SELECTED(response.items[0])),
    );
    history.push(`/rich-contents/${id}`);
  };
  const approveTemplateData = async (type: string) => {
    contentType.current = type;
    const response = await approveTask(
      id,
      taskAssigneeData[contentType.current]?.id,
    );
    if (response?.isSuccess) {
      await fetchRichContent({ pageNumber: 1, pageSize: 20, ids: [id] }).then(
        (response) => dispatch(RRichContent.SET_SELECTED(response.items[0])),
      );
    }
    !isAnyTaskApproved()
      ? dispatch(
          RModal.open({
            type: 'typeB',
            icon:
              type === 'AUTHOR_CONTENT' ? (
                <AuthorContentIcon />
              ) : (
                <AssetPlaceholder />
              ),
            danger: false,
            heading:
              type === 'AUTHOR_CONTENT'
                ? capitalizeFirstLetter(
                    t('taskMaster.for_approved', {
                      for: t('taskMaster.author_content'),
                    }),
                  )
                : capitalizeFirstLetter(
                    t('taskMaster.for_approved', {
                      for: t('taskMaster.visual_assets'),
                    }),
                  ),
            supportingText: t('taskMaster.notify_statement'),
            buttons: [
              {
                text: capitalizeFirstLetter(t('taskMaster.continue_reviewing')),
                variant: 'gray',
                method: onCancelModal,
              },
              {
                text: capitalizeFirstLetter(t('global.done')),
                variant: 'green',
                method: doneHandler,
              },
            ],
          }),
        )
      : history.push(`/rich-contents/${id}`);
  };
  return (
    <>
      <div className="RichContentTemplate-footer">
        <div>
          {permissions['REVIEW'] &&
            process.env.REACT_APP_WORKFLOW === 'ON' &&
            status === 'READY_FOR_REVIEW' &&
            taskAssigneeData.VISUAL_ASSETS.status === 'SUBMITTED' && (
              <>
                <PrimaryButton
                  label={capitalizeFirstLetter(
                    t('rich_content.feedback_on_visual_assets'),
                  )}
                  onClick={() => feedBack('REVIEW_VISUAL_ASSET')}
                />
                <PrimaryButton
                  label={capitalizeFirstLetter(
                    t('rich_content.approve_visual_assets'),
                  )}
                  onClick={() => approveTemplateData('REVIEW_VISUAL_ASSET')}
                />
              </>
            )}
        </div>
        <div>
          {permissions['REVIEW'] &&
            status === 'READY_FOR_REVIEW' &&
            process.env.REACT_APP_WORKFLOW === 'ON' &&
            taskAssigneeData.AUTHOR_CONTENT.status === 'SUBMITTED' && (
              <>
                <PrimaryButton
                  label={capitalizeFirstLetter(
                    t('rich_content.feedback_on_authored_content'),
                  )}
                  onClick={() => feedBack('REVIEW_CONTENT')}
                />
                <PrimaryButton
                  label={capitalizeFirstLetter(
                    t('rich_content.approve_authored_content'),
                  )}
                  onClick={() => approveTemplateData('REVIEW_CONTENT')}
                />
              </>
            )}
          {(permissions.AUTHOR_CONTENT || permissions.VISUAL_ASSETS) &&
            authorStatus !== 'SUBMITTED' &&
            visualAssetStatus !== 'SUBMITTED' &&
            authorStatus !== 'COMPLETED' &&
            visualAssetStatus !== 'COMPLETED' &&
            status !== 'LIVE' &&
            status !== 'COMPLETED' && (
              <PrimaryButton
                label={capitalizeFirstLetter(t('global.save'))}
                onClick={submitTemplateData}
              />
            )}
          {permissions?.REVIEW &&
            reviewerStatus === STATUS_MAP['COMPLETED'] && (
              <PrimaryButton
                label={capitalizeFirstLetter(t('global.save'))}
                onClick={submitTemplateData}
              />
            )}
        </div>
      </div>
    </>
  );
};
export default React.memo(TemplateSelectionFooter);
