import React, { useLayoutEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import ApplicationRouter from './pages/ApplicationRouter';

import './assets/scss/global.scss';
import './assets/scss/_base.scss';
import './App.scss';

const App: React.FC = () => {
  const [realm, setRealm] = useState<string>('');
  const clientId = 'prodport';

  useLayoutEffect(() => {
    const host = window.location.hostname;
    if (host === 'localhost') {
      setRealm(process.env.REACT_APP_KC_REALM as string);
    } else {
      setRealm(`${host.split('.')[0]}-tenant`);
    }
  }, []);

  return <ApplicationRouter clientId={clientId} realm={realm} />;
};

export default Sentry.withProfiler(App);
