import React from 'react';

/**
 * Interface for Loading Group
 *
 * @interface
 */
interface LoadingGroupProps {
  numberOfCards?: number;
  component: React.ReactNode;
}

/**
 * Component to render a component n times.
 *
 * @author Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 * @param {number} numberOfCards number of times the given component should be rendered.
 * @param {React.ReactNode} component component to be rendered
 */

const LoadingGroup = ({ numberOfCards = 12, component }: LoadingGroupProps) => {
  const arr = Array.from({ length: numberOfCards });

  return (
    <>
      {arr.map((_el, index: number) => (
        <React.Fragment key={index}>{component}</React.Fragment>
      ))}
    </>
  );
};

export default LoadingGroup;
