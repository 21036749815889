import React from 'react';

interface TextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  color?: string;
  weight?: number;
  size?: number;
  block?: boolean;
  uppercase?: boolean;
  className?: string;
}
export default function Text({
  color,
  weight,
  size,
  block = true,
  uppercase,
  children,
  className,
  ...rest
}: TextProps) {
  return (
    <span
      className={className}
      style={{
        color,
        fontWeight: weight,
        fontSize: size,
        display: block ? 'block' : '',
        textTransform: uppercase ? 'uppercase' : 'none',
        lineHeight: `calc(1.21 * ${size}px)`,
        ...rest.style,
      }}
      {...rest}
    >
      {children}
    </span>
  );
}
