import React, { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import './TextareaWithBorder.scss';

interface ITextareaWithoutBorder {
  id?: string;
  className?: string;
  placeholder?: string;
  rows?: number;
  label?: string;
  labelIcon?: JSX.Element;
  value?: string;
  variant?: string;
  makeAutoFocus?: boolean;
  limit?: number;
  hasUpdatePermission?: boolean;
  onChange: Function;
  onOutsideClickHandler?: Function;
  showRemaningCharaters?: boolean;
  autoGrow?: boolean;
  error?: boolean;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

const TextareaWithoutBorder: React.FC<ITextareaWithoutBorder> = ({
  id,
  className,
  label,
  labelIcon,
  value = '',
  variant = 'primary',
  placeholder,
  rows,
  makeAutoFocus,
  limit,
  hasUpdatePermission = true,
  onChange,
  onOutsideClickHandler = () => null,
  showRemaningCharaters = true,
  autoGrow = false,
  error = false,
  onBlur,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [remainingCharacters, setRemainingCharacters] = useState(limit);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTextareaValue(value);
    updateLimit(value);
  }, [value]);

  useEffect(() => {
    autoGrow && setDynamicHeight();
  }, [textareaValue]);

  const updateLimit = (value: string) => {
    limit && setRemainingCharacters(limit - value.length);
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setTextareaValue(value);
    updateLimit(value);
    onChange(value);
  };

  const handleOutsideClick = () => {
    if (onOutsideClickHandler && id && active) {
      setActive(false);
      onOutsideClickHandler(id, textareaValue);
    }
  };

  const setDynamicHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current?.scrollHeight}px`;
    }
  };

  return (
    <div
      className={`TextareaWithoutBorder ${variant} ${
        className ? className : ''
      } ${error ? 'textarea-error' : ''}`}
    >
      <div className="TextareaWithoutBorder-labelContainer">
        {!!label && (
          <label className="TextareaWithoutBorder-labelContainer__label">
            {labelIcon && labelIcon}
            {label}
          </label>
        )}
        {limit !== undefined &&
          remainingCharacters !== undefined &&
          showRemaningCharaters && (
            <span
              className={`TextareaWithoutBorder-labelContainer__textRemaining ${
                remainingCharacters <= 0 ? 'limit-exceeded' : ''
              }`}
              title={`${id}-textLimit`}
            >
              {remainingCharacters} {t('global.characters_remaining')}
            </span>
          )}
      </div>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <textarea
          ref={textAreaRef}
          autoFocus={makeAutoFocus}
          className={`TextareaWithoutBorder-textarea ${variant} ${
            !hasUpdatePermission ? 'inactive' : ''
          } ${!hasUpdatePermission ? 'inactive' : ''}`}
          placeholder={placeholder}
          rows={rows}
          draggable={false}
          value={textareaValue}
          readOnly={!hasUpdatePermission}
          onChange={handleChange}
          onFocus={() => hasUpdatePermission && setActive(true)}
          {...(!!id && { id })}
          {...(limit && { maxLength: limit })}
          onBlur={onBlur}
          data-testid={id}
        />
      </OutsideClickHandler>
    </div>
  );
};

TextareaWithoutBorder.defaultProps = {
  value: '',
  makeAutoFocus: false,
};

export default TextareaWithoutBorder;
