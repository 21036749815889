import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Connector } from '@apis/connector';
import { getProduct } from '@apis/products';
import PrimaryButton from '@components/PrimaryButton';
import { DirectoryProduct } from '@models/product';
import { RProduct } from '@redux/slices/product';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import AllProductsView from './AllProductsView';
import RecommendedProductsView from './RecommendedProductsView';

import './style.scss';

const SelectProductView = ({
  setProduct,
}: {
  setProduct: (product: DirectoryProduct) => any;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedProduct, setSelectedProduct] = useState<DirectoryProduct>();
  const [viewAll, setViewAll] = useState(false);
  const [recommendedProducts, setRecommendedProducts] =
    useState<DirectoryProduct[]>();

  const { items } = useSelector((state) => state.products);

  useEffect(() => {
    setSelectedProduct(void 0);
    (async () => {
      if (!items) dispatch(RProduct.resetResults());
      if (!viewAll && !recommendedProducts) {
        try {
          const {
            data: { rankedProducts },
          } = await Connector.getRecommendedProducts(3);

          const products = await Promise.all(
            rankedProducts.map(async ({ id }) => {
              const product: DirectoryProduct | undefined =
                items?.find((p) => p.productId === id) ||
                (await getProduct(id));
              if (product) return product;
              else throw new Error('Product not retrievable');
            }),
          );
          setRecommendedProducts(products);
        } catch {
          // On exception, fallback to browse
          setViewAll(true);
        }
      }
    })();

    return () => {
      // Reset search in case someone search in all products view
      dispatch(RProduct.setSearchText(''));
    };
  }, [viewAll]);

  return (
    <div className="select-product-view">
      <div className="select-product-view-top">
        {viewAll ? (
          <AllProductsView
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        ) : (
          <RecommendedProductsView
            recommendedProducts={recommendedProducts}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
      </div>
      <div className="select-product-view-bottom">
        <PrimaryButton
          onClick={() => setViewAll((viewAll) => !viewAll)}
          label={capitalizeEveryFirstLetter(
            t('testFlow.step1.view' + (viewAll ? 'Recommended' : 'All')),
          )}
          variant="green"
        />

        <PrimaryButton
          onClick={() => (selectedProduct ? setProduct(selectedProduct) : 0)}
          disabled={!selectedProduct}
          label={capitalizeEveryFirstLetter(t('testFlow.step1.selectProduct'))}
        />
      </div>
    </div>
  );
};

export default SelectProductView;
