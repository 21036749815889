import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../RichContentSectionHeader';
import { VideoIcon } from '@components/Icons/VideoIcon';
import { VideoPlayer } from '@components/VideoPlayer';
import SectionFiedsFactory from '../../[id]/template/SectionFieldsFactory';

import { Attribute, IRichContentSection } from '@models/rich-content';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import './style.scss';

const RichContentVideoUploaderWithTitleAndDesc: React.FC<
  IRichContentSection
> = ({ index, length, moveSection, permissions, attributes, handleChange }) => {
  const { t } = useTranslation();
  const sectionFieldsFactory = (
    id: string,
    key: string,
    attribute: Attribute,
    characterLimits = 100,
  ) => (
    <SectionFiedsFactory
      id={id}
      key={key}
      attributeKey={key}
      sectionOrder={index}
      attribute={attribute}
      permissions={permissions}
      handleChange={handleChange}
      characterLimits={characterLimits}
    />
  );
  return (
    <>
      <div
        className={`RichContentVideoUploader ${
          permissions.AUTHOR_CONTENT ? '' : 'RichContentTemplate__readOnly'
        }`}
      >
        <SectionHeader
          title={capitalizeEveryFirstLetter(t('global.videoWithTitleAndDesc'))}
          moveSection={moveSection}
          showDropdown={permissions.AUTHOR_CONTENT}
          index={index}
          length={length}
          icon={<VideoIcon />}
        />
        <div className="RichContentImageSection">
          <div className="RichContentImageSection-heading m-4">
            {sectionFieldsFactory(
              `imageSectionHeading${index}`,
              'heading',
              attributes['heading'],
            )}
          </div>
          <div className="RichContentImageSection-heading m-4">
            {sectionFieldsFactory(
              `imageSectionDescription${index}`,
              'description',
              attributes['description'],
            )}
          </div>

          <React.Fragment>
            {attributes['video'].standardText ? (
              <div className="RichContentVideoUploader-selectedAsset">
                <VideoPlayer url={attributes['video'].standardText} />
                {sectionFieldsFactory(
                  `videoUploader${index}`,
                  'video',
                  attributes['video'],
                )}
              </div>
            ) : (
              <div className="RichContentVideoUploader__video m-4">
                {sectionFieldsFactory(
                  `videoUploaderPlaceholder${index}`,
                  'video',
                  attributes['video'],
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      </div>
    </>
  );
};
export default React.memo(RichContentVideoUploaderWithTitleAndDesc);
