import React from 'react';

export const VideoIcon = ({
  height = '24px',
  width = '24px',
  color = 'var(--emerald)',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.3333 0.666748H4.66659C2.28325 0.666748 0.333252 2.61675 0.333252 5.00008V31.0001C0.333252 33.3834 2.28325 35.3334 4.66659 35.3334H39.3333C41.7166 35.3334 43.6666 33.3834 43.6666 31.0001V5.00008C43.6666 2.61675 41.7166 0.666748 39.3333 0.666748ZM16.5833 27.7501V8.25008L31.7499 18.0001L16.5833 27.7501Z"
      fill={color}
    />
  </svg>
);
