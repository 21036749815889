import axios from 'axios';
import store from '@redux/store';

/**
 * @author Ritam Bandyopadhyay
 * @description Creating axios instances with interceptors attached for reusability.
 *
 * @use clientWithToken.httpVerb('/remaining-slug-url', dataIfAny) => Promise like axios;
 */

const hostName = window.location.hostname;

const TLD_NAME = hostName.includes('.ch') ? 'ch' : 'com'; // TOP LEVEL DOMAIN
export const BASE =
  hostName !== 'localhost'
    ? `https://${hostName.split('.')[0]}-api.prodport.${TLD_NAME}/`
    : process.env.REACT_APP_BASE_API_URL;

export const clientWithToken = axios.create({
  baseURL: BASE,
});

/** Default page size for api's */
export const PAGE_SIZE = 20;

clientWithToken.interceptors.request.use((config) => {
  const kcInstance = store.getState().keyCloak.keyCloak;
  config.headers.Authorization = `Bearer ${kcInstance.token}`;
  config.headers['X-TenantID'] = kcInstance.clientId;
  return config;
});

const onResponseSuccess = (response: any) => {
  return response;
};

export const setUpForbiddenInterceptor = (history: any) => {
  clientWithToken.interceptors.response.use(onResponseSuccess, (err) => {
    if (axios.isCancel(err)) {
      return Promise.reject(err);
    }
    const status = err.status || err.response.status;
    if (status === 403) history.push('/forbidden');
    if (status === 401) {
      store.getState().keyCloak.keyCloak.logout();
    }
    // getting arraybuffer response for downloading zip file
    // needed to convert arraybuffer response to actual JSON response
    if (err.response.config.responseType === 'arraybuffer') {
      const uint8View = new Uint8Array(err.response.data);
      const jsonString = Buffer.from(uint8View).toString('utf8');
      const parsedData = JSON.parse(jsonString);
      return Promise.reject(parsedData.errors);
    }
    return Promise.reject({
      errors: err.response?.data?.errors,
      body: err.response?.data?.body || err.response?.data || null,
      status: err.response?.status,
    });
  });
};
