import { IContext } from '@models/context';
import { IBackendErrorMessageFormat } from '@models/errorMessage';
import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { PAGE_SIZE } from '@apis/index';
import { toast } from '@components/ToastNotification/ToastManager';
import { History } from 'history';
import { ROUTES } from '@constants/routes';
import { AppThunk } from '@redux/store';
import { createContext, listContexts } from '@apis/context';

type ContextsState = {
  contexts: IContext[] | null;
  loading: boolean;
  page: number;
  searchQuery: string;
  status: ('DRAFT' | 'NEW' | 'UPDATED')[];
  selectedContexts: {
    [key: string]: IContext;
  };
  selectedContextsCount: number;
  selectedContext: IContext | null;
  showBottomMenu: boolean;
  totalCount: number;
  errors: IBackendErrorMessageFormat | null;
  hasEditPermission: boolean;
  showCreateContextPopover: boolean;
};

const initialState: DeepReadonly<ContextsState> = {
  contexts: null,
  loading: false,
  showBottomMenu: false,
  selectedContexts: {},
  selectedContext: null,
  selectedContextsCount: 0,
  page: 0,
  totalCount: 0,
  searchQuery: '',
  status: [],
  hasEditPermission: true,
  showCreateContextPopover: false,
  errors: null,
};

const { actions, reducer } = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setTotalCount: (state, { payload }: { payload: number }) => {
      state.totalCount = payload;
    },
    reset: (state) => {
      state.page = initialState.page;
      state.searchQuery = initialState.searchQuery;
      state.contexts = null;
      state.status = [];
    },
    incrementPage: (state) => {
      state.page++;
    },
    setSearch: (state, { payload }: { payload: string }) => {
      if (state.searchQuery === payload) return;
      state.page = 0;
      state.searchQuery = payload;
      state.contexts = null;
      state.selectedContext = null;
    },
    setStatus: (state, { payload }: { payload: ContextsState['status'] }) => {
      if (state.status === payload) return;
      state.page = 0;
      state.status = payload;
      state.contexts = null;
      state.selectedContext = null;
    },
    setSelected: (state, { payload }: { payload: IContext | null }) => {
      state.selectedContext = payload;
    },
    toggleSelected: (
      state,
      { payload }: { payload: DeepReadonly<IContext> },
    ) => {
      if (state.selectedContexts[payload.contextId]) {
        delete state.selectedContexts[payload.contextId];
        state.selectedContextsCount--;
        state.showBottomMenu = state.selectedContextsCount > 0;
      } else {
        // Assert mutable as it is likely from our state and we're currently in a writable mode
        state.selectedContexts[payload.contextId] = payload as DeepWritable<
          typeof payload
        >;
        state.selectedContextsCount++;
        state.showBottomMenu = true;
      }
    },
    deselectAll: (state) => {
      state.selectedContext = null;
      state.selectedContexts = {};
      state.selectedContextsCount = 0;
      state.showBottomMenu = false;
    },
    setErrors: (
      state,
      { payload }: { payload: IBackendErrorMessageFormat | null },
    ) => {
      state.errors = payload;
    },
    setBottomMenuVisible: (state, { payload }: { payload: boolean }) => {
      state.showBottomMenu = payload;
    },
    setPopoverVisible: (state, { payload }: { payload: boolean }) => {
      state.showCreateContextPopover = payload;
    },
    setHasEditPermissions: (state, { payload }: { payload: boolean }) => {
      state.hasEditPermission = payload;
    },
    addContexts: (
      state,
      {
        payload,
      }: {
        payload: IContext[];
      },
    ) => {
      const contexts = state.contexts || [];
      for (const newContext of payload) {
        if (
          !contexts.find(({ contextId }) => newContext.contextId === contextId)
        )
          contexts.push(newContext);
      }
      state.contexts = contexts;
    },
    updateContext: (state, { payload }: { payload: IContext }) => {
      const context = state.contexts?.find(
        ({ contextId }) => contextId === payload.contextId,
      );
      if (context) Object.assign(context, payload);
    },
    removeContexts: (state, { payload }: { payload: string[] }) => {
      const contexts = state.contexts || [];
      const filteredContexts = contexts.filter(
        ({ contextId }) => !payload.includes(contextId),
      );
      const removedCount = state.totalCount - filteredContexts.length;
      if (removedCount > PAGE_SIZE)
        state.page -= Math.ceil(removedCount / PAGE_SIZE);

      state.contexts = filteredContexts;
      state.totalCount = filteredContexts.length;
    },
  },
});

const thunks = {
  createContext:
    (
      context: {
        contextName: string;
        shouldDuplicate?: boolean;
        contextIdToDuplicate?: string;
      },
      history: History,
    ): AppThunk =>
    async (dispatch) => {
      try {
        const created = await createContext(context);
        dispatch(actions.setSelected(created));
        dispatch(actions.reset());
        dispatch(actions.setPopoverVisible(false));
        history.push(`${ROUTES.context.base}/${created.contextId}`);
      } catch (errorObj: any) {
        const { errors } = errorObj;
        dispatch(actions.setErrors(errors));
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects({
  name: 'Fetch current page with current search',
  dependsOn: [
    actions.incrementPage,
    actions.setSearch,
    actions.reset,
    actions.removeContexts,
    actions.setStatus,
  ],
  execute: async ({ state, dispatch }) => {
    const { page, searchQuery, status } = state.contexts;
    dispatch(actions.setLoading(true));
    try {
      const { content, totalElements } = await listContexts({
        searchText: searchQuery,
        page,
        status,
      });

      dispatch(actions.addContexts(content));
      dispatch(actions.setTotalCount(totalElements));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(actions.reset());
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    }
    dispatch(actions.setLoading(false));
  },
});

export const RContext = Object.assign(actions, thunks);

export default reducer;
