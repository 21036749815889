import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { callTimer } from '../../../heuristic.config';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { checkPermission } from '@utils/checkPermission';
import useInterval from '../../../hooks/useInterval';
import { getPath } from '@utils/routing';

import { SideBarParent, SidebarItem, Toggler } from '@components/NavItems';
import { MyAccountNavButton } from '@components/MyAccountNavButton';
import SkeletonText from '@components/SkeletonText';
import CustomToolTip from '@components/Tooltip';
import { ROUTES } from '@constants/routes';

import expandedLogo from '@assets/logos/sidebar-expanded-logo.svg';

import collapsedLogo from '@assets/logos/sidebar-logo.svg';
import showArrow from '@assets/icons/arrow-right.svg';
import closeArrow from '@assets/icons/arrow-left.svg';
import { ReactComponent as NotificationIcon } from '@assets/icons/23_notifications.svg';

import { Mixpanel } from '@utils/mixpanel';

import { ROLES_PERMISSIONS } from '@constants/permissions';

import { ReactComponent as DashboardIcon } from '@assets/icons/dashboard.svg';
import { ReactComponent as ReportingIcon } from '@assets/icons/reporting.svg';
import { ReactComponent as RichContentIcon } from '@assets/icons/brandContent.svg';
import { ReactComponent as DigitalAssetsIcon } from '@assets/icons/digitalAssets.svg';
import { ReactComponent as ProductsIcon } from '@assets/icons/products.svg';
import { ReactComponent as ContextIcon } from '@assets/icons/targets.svg';
import { ReactComponent as TaskManagerIcon } from '@assets/icons/taskManager.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';
import { ReactComponent as PagesIcon } from '@assets/icons/page.svg';
import { countNotifications } from '@apis/notifications';
import { fetchGuide } from '@apis/quickStartGuide';

import './style.scss';

const {
  dashboard,
  products,
  pages,
  digitalAssets,
  taskMaster,
  richContent,
  context,
  settings,
} = ROUTES;

const RouterLinks: ({
  id: string;
  name: string;
  route: string;
  Icon: React.FunctionComponent;
} & (
  | {
      protectedRoute: false;
      permission?: undefined;
    }
  | {
      protectedRoute: true;
      permission: string;
    }
))[] = [
  {
    id: 'dashboard',
    name: 'side_panel.dashboard',
    route: dashboard.base,
    Icon: DashboardIcon,
    protectedRoute: false,
  },
  {
    id: 'reporting',
    name: 'reporting.title',
    route: '/reporting',
    Icon: ReportingIcon,
    protectedRoute: false,
  },
  {
    id: 'products',
    name: 'side_panel.products',
    route: products.base,
    Icon: ProductsIcon,
    protectedRoute: true,
    permission: ROLES_PERMISSIONS.READ_PRODUCTS,
  },
  {
    id: 'pages',
    name: 'side_panel.pages',
    route: pages.base,
    Icon: PagesIcon,
    protectedRoute: true,
    permission: ROLES_PERMISSIONS.READ_PRODUCTS,
  },
  {
    id: 'context',
    name: 'side_panel.context',
    route: context.base,
    Icon: ContextIcon,
    protectedRoute: true,
    permission: ROLES_PERMISSIONS.READ_CONTEXT,
  },
  {
    id: 'rich-content',
    name: 'side_panel.rich_content',
    route: richContent.base,
    Icon: RichContentIcon,
    protectedRoute: true,
    permission: ROLES_PERMISSIONS.READ_RICH_CONTENT,
  },
  {
    id: 'digital-assets',
    name: 'side_panel.digital_assets',
    route: digitalAssets.base,
    Icon: DigitalAssetsIcon,
    protectedRoute: true,
    permission: ROLES_PERMISSIONS.READ_DIGITAL_ASSETS,
  },
  {
    id: 'task-master',
    name: 'side_panel.task_master',
    route: taskMaster.base,
    Icon: TaskManagerIcon,
    protectedRoute: false,
  },
  {
    id: 'settings',
    name: 'side_panel.settings',
    route: settings.base,
    Icon: SettingsIcon,
    protectedRoute: true,
    permission: ROLES_PERMISSIONS.READ_TENANT_SETTINGS,
  },
];

const redirectToStrategyBoard = async (): Promise<boolean> => {
  try {
    const response = await fetchGuide();
    return response.tasks[1]?.steps[1]?.isCompleted !== true;
  } catch {
    return false;
  }
};

const SidebarItemLoading = () => (
  <div className="SidebarItemLoading">
    <SkeletonText className="SidebarItemLoading-icon" />
    <SkeletonText className="SidebarItemLoading-label" />
  </div>
);

const SidebarLoading = () => (
  <div className="SidebarLoading">
    <div>
      {RouterLinks.map(({ route }) => (
        <SidebarItemLoading key={route} />
      ))}
    </div>
    <div>
      {[1, 2, 3].map((el) => (
        <SidebarItemLoading key={el} />
      ))}
    </div>
  </div>
);

const SideBar = (props: any) => {
  const { t } = useTranslation();
  const { history, toggle, settoggle } = props;
  const lastActiveIndex = Number(localStorage.getItem('lastActiveIndex'));
  const [activeIndex, setActiveIndex] = useState(lastActiveIndex || 0);
  const refreshInterval = callTimer.notification.refreshRate;
  const [notificationsCount, setNotificationsCount] = useState(0);
  const user = useSelector((state) => state.currentUser);
  const changeActiveIndex = (newIndex: number) => {
    localStorage.setItem('lastActiveIndex', newIndex.toString());
    setActiveIndex(newIndex);
  };

  const loggedInUserId = useSelector((state) => state.currentUser?.data?.id);
  const { guideStatus } = useSelector((state) => state.quickStartGuide);
  const getNewNotificationCount = async () => {
    const count = await countNotifications({
      userId: loggedInUserId,
      channels: ['PUSH'],
      readStatus: ['NEW'],
    });
    setNotificationsCount(count);
  };

  useEffect(() => {
    getNewNotificationCount();
  }, []);

  useInterval(async () => {
    await getNewNotificationCount();
  }, refreshInterval);

  useEffect(() => {
    let activeItem = RouterLinks.findIndex((item) =>
      getPath(history.location.pathname).includes(getPath(item.route)),
    );
    if (getPath(history.location.pathname).includes(ROUTES.startGuide)) {
      activeItem = 9;
    }
    if (
      getPath(history.location.pathname).includes(ROUTES.notifications.base)
    ) {
      activeItem = 10;
    }
    changeActiveIndex(activeItem);
  }, [history.location]);

  // Redirect to Strategy Board if New User

  const { strategyBoard } = ROUTES;
  const strategyBoardRedirect = async () => {
    try {
      const redirectToStrategyBoardUrl = strategyBoard.base;
      const shouldRedirect = await redirectToStrategyBoard();
      if (shouldRedirect) {
        Mixpanel.track('onboarding_start', {
          eventName: 'Started the onboarding flow',
          kpi: 'Activation',
          propertyType: 'event_property',
        });
        history.push(redirectToStrategyBoardUrl);
      }
    } catch {
      // TODO Uncaught
    }
  };

  useEffect(() => {
    strategyBoardRedirect();
  }, []);

  return (
    <SideBarParent className="SideBar" active={toggle}>
      {/* STYLE ADDED BY RITAM, TO MATCH HEIGHT OF PageHeading AND CENTER LOGO */}
      <div>
        <div className="d-flex justify-content-center align-items-center py-3">
          <img
            height="32px"
            src={toggle ? expandedLogo : collapsedLogo}
            alt="logo"
          />
        </div>
        <div
          onClick={settoggle}
          role="presentation"
          style={{ userSelect: 'none', cursor: 'pointer' }}
        >
          <Toggler active={toggle}>
            <div className="SideBar-toggleIcon">
              <img src={toggle ? closeArrow : showArrow} />
            </div>
          </Toggler>
        </div>
      </div>
      {user?.permissions ? (
        <div className="SideBar-itemsContainer">
          <div>
            {RouterLinks.map(
              (
                {
                  id,
                  protectedRoute = true,
                  permission = 'BASE_PERMISSION',
                  route,
                  Icon,
                  name,
                },
                index,
              ) =>
                protectedRoute ? (
                  checkPermission(user, permission) ? (
                    <CustomToolTip
                      id={`${id}-tooltip`}
                      tipText={capitalizeEveryFirstLetter(t(name))}
                      hideToolTip={toggle}
                      customPosition={{ left: 40, bottom: 30 }}
                      key={`${id}-tooltip`}
                    >
                      <Link
                        to={route}
                        key={route}
                        className="d-block"
                        id={`${id}-route`}
                      >
                        <SidebarItem
                          active={index === activeIndex}
                          show={toggle}
                        >
                          <div>
                            <Icon />
                          </div>
                          <div>{capitalizeEveryFirstLetter(t(name))}</div>
                        </SidebarItem>
                      </Link>
                    </CustomToolTip>
                  ) : null
                ) : (
                  <CustomToolTip
                    id={`${id}-tooltip`}
                    tipText={capitalizeEveryFirstLetter(t(name))}
                    hideToolTip={toggle}
                    customPosition={{ left: 40, bottom: 30 }}
                    key={`${id}-tooltip`}
                  >
                    <Link
                      id={`${id}-route`}
                      to={route}
                      key={`${id}-route`}
                      className="d-block"
                    >
                      <SidebarItem active={index === activeIndex} show={toggle}>
                        <div>
                          <Icon />
                        </div>
                        <div>{capitalizeEveryFirstLetter(t(name))}</div>
                      </SidebarItem>
                    </Link>
                  </CustomToolTip>
                ),
            )}
          </div>
          <div>
            <CustomToolTip
              tipText={capitalizeFirstLetter(t('guide.title'))}
              hideToolTip={toggle}
              id="guide-tooltip"
            >
              <Link to={ROUTES.startGuide} id="guide-route">
                <SidebarItem active={activeIndex == 9} show={toggle}>
                  <div className="SideBar-startGuide">
                    {/* This value is updated in sidebar using useRefetchGuideStatus hook */}
                    {`${guideStatus?.progress || 0}%`}
                  </div>
                  <div>{capitalizeFirstLetter(t('guide.title'))}</div>
                </SidebarItem>
              </Link>
            </CustomToolTip>
            <CustomToolTip
              tipText={capitalizeFirstLetter(t('notifications.title'))}
              hideToolTip={toggle}
              id="notification-tooltip"
            >
              <Link to="/notifications" id="notification-route">
                <SidebarItem active={activeIndex == 10} show={toggle}>
                  <div className="SideBar-bellIconContainer">
                    <NotificationIcon />
                    {!!notificationsCount && (
                      <span className="SideBar-bellIconContainer__notificationCount badge-sizzling-red">
                        {notificationsCount <= 9 ? notificationsCount : '9+'}
                      </span>
                    )}
                  </div>
                  <div>
                    {capitalizeFirstLetter(t('side_panel.notifications'))}
                  </div>
                </SidebarItem>
              </Link>
            </CustomToolTip>
            <MyAccountNavButton toggle={toggle} />
          </div>
        </div>
      ) : (
        <SidebarLoading />
      )}
    </SideBarParent>
  );
};

export default SideBar;
