import React, { useState } from 'react';

import SectionFactory from './SectionFactory';
import AddSectionModal from '../../+page/RichContentAddSelectionModal';
import useLegacyModal from '@components/ModalLayout/useModal';
import AddButtonWithSeparator from '@components/AddButtonWithSeparator';

import { IPermission, ITemplate } from '@models/rich-content';
import { Mixpanel } from '@utils/mixpanel';
import { sections } from '../../+page/defaultData';
import AddSectionList from '../../+page/AddSectionList';

const TemplateMainContent = ({
  permissions,
  templateData,
  setTemplateData,
}: {
  permissions: IPermission;
  templateData: ITemplate[];
  setTemplateData: React.Dispatch<React.SetStateAction<ITemplate[]>>;
}) => {
  const templateSelection = 'rich-content-section-selection-modal';
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const [addSectionIndex, setAddSectionIndex] = useState(0);

  const addNewSection = (sectionName: keyof typeof sections) => {
    /** Cloned section from {@link sections} so we can modify it*/
    const { preview, ...section } = sections[sectionName];
    /** Cloned template data to update */
    const newTemplateData = [...templateData];
    // Insert section at index
    newTemplateData.splice(addSectionIndex, 0, {
      ...structuredClone(section),
      sectionName,
      id: sectionName,
    });
    // Recompute order
    newTemplateData.forEach((section, index) => (section.order = index + 1));
    setTemplateData(newTemplateData);
    closeModal();
  };

  const openAddNewElement = (index: number) => {
    if (!permissions.AUTHOR_CONTENT && !permissions.VISUAL_ASSETS) return;
    setAddSectionIndex(index);
    openModal(templateSelection);
  };

  const moveSection = (position: 'up' | 'down', index: number) => {
    let data;
    if (position == 'up') {
      data = templateData[index];
      templateData[index] = {
        ...templateData[index - 1],
        order: data.order,
      };
      templateData[index - 1] = {
        ...data,
        order: templateData[index - 1].order,
      };
    } else if (position == 'down') {
      data = templateData[index];
      templateData[index] = { ...templateData[index + 1], order: data.order };
      templateData[index + 1] = {
        ...data,
        order: templateData[index + 1].order,
      };
    } else {
      const finalTemplates = templateData.filter((_, key) => key !== index);
      finalTemplates.forEach((_, index) => {
        finalTemplates[index].order = index + 1;
      });
      setTemplateData([...finalTemplates]);
      return;
    }
    setTemplateData([...templateData]);
  };

  const handleChange = (
    value: string,
    field: string,
    index: string,
    isAsset = false,
    assetIds = [],
  ) => {
    const data = JSON.parse(JSON.stringify(templateData));
    if (isAsset) {
      data[index].attributes[field].assetIds = assetIds;
    }
    data[index].attributes[field].standardText = value;
    setTemplateData([...data]);

    Mixpanel.track('rich_content_operation', {
      eventName: 'Update rich content',
      kpi: 'Takes advantage of feature',
      propertyType: 'event_property',
    });
  };

  const sectionsList = templateData.map(
    (template: ITemplate, index: number) => {
      return (
        <React.Fragment key={index}>
          <SectionFactory
            key={template.id}
            permissions={permissions}
            sectionOrder={index}
            section={template}
            totalSections={templateData.length}
            moveSection={moveSection}
            handleChange={handleChange}
          />
          {(permissions?.AUTHOR_CONTENT || permissions?.VISUAL_ASSETS) && (
            <AddButtonWithSeparator
              className="mt-1 mb-1"
              onClick={() => openAddNewElement(index + 1)}
            />
          )}
        </React.Fragment>
      );
    },
  );

  return (
    <>
      {sectionsList.length ? (
        <>
          {(permissions?.AUTHOR_CONTENT || permissions?.VISUAL_ASSETS) && (
            <AddButtonWithSeparator
              className="mt-1 mb-1"
              onClick={() => openAddNewElement(0)}
            />
          )}
          {sectionsList}
        </>
      ) : (
        <AddSectionList addNewSection={addNewSection} />
      )}
      <AddSectionModal
        showModal={templateSelection === showModalId}
        setShowModal={closeModal}
        onClose={closeModal}
        addNewSection={addNewSection}
      />
    </>
  );
};
export default TemplateMainContent;
