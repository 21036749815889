import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '@constants/routes';
import { RModal } from '@redux/slices/modal';
import { DigitalAssetBrief } from '@models/digital-asset';
import { getThumbnail } from '@utils/getThumbnail';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';

import { deleteDigitalAssets } from '@apis/digitalAssets';

import ConfirmationModal, {
  ActionButtonProps,
} from '@components/ConfirmationModal';
import useLegacyModal from '@components/ModalLayout/useModal';
import ListingCard from '@components/ListingCard';

import pdfIcon from '@assets/icons/pdf.svg';

import './style.scss';
import { RAsset } from '@redux/slices/asset';

interface DigitalAssetCardProps {
  id: string;
  asset: DeepReadonly<DigitalAssetBrief>;
  isSelected: boolean;
  showDeleteIcon?: boolean;
  showEditOption?: boolean;
  disableRedirect?: boolean;
  onChange: (asset: DigitalAssetBrief) => void;
}

const DigitalAssetCard: React.FC<DigitalAssetCardProps> = ({
  id,
  asset,
  isSelected,
  showDeleteIcon,
  showEditOption,
  disableRedirect = false,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const confirmationModalId = 'delete-confirmation-modal';
  let thumbnailType;

  switch (asset.format) {
    case 'mvp':
    case 'mp4':
    case 'mov':
      thumbnailType = 'video';
      break;

    case 'svg':
      thumbnailType = 'svg';
      break;

    case 'pdf':
      thumbnailType = 'document';
      break;

    default:
      thumbnailType = 'image';
  }

  const confirmationModal = {
    modalId: confirmationModalId,
    openModal: () => {
      openModal(confirmationModalId);
    },
    title: capitalizeFirstLetter(t('digitalAssets.deleteConfirmationSingle')),
    confirmationStatement: capitalizeFirstLetter(
      t('digitalAssets.deleteStatementSingle'),
    ),
    clickHandler: async () => {
      const response = await deleteDigitalAssets([asset.id]);
      closeModal();
      if (response.isSuccess) {
        dispatch(RAsset.removeItems([asset]));
      } else {
        const {
          body: { assetUsageResponseList },
        } = response;
        dispatch(
          RModal.open({
            type: 'typeA',
            heading: capitalizeFirstLetter(
              `${t('digitalAssets.asset')} ${t(
                'digitalAssets.canNotBeDeleted',
              )}`,
            ),
            supportingText: `${capitalizeFirstLetter(
              t('digitalAssets.single_delete_usage_error_line1'),
            )} <b>${
              assetUsageResponseList[0]?.usageCount
            } ${capitalizeEveryFirstLetter(
              t('digitalAssets.use_cases'),
            )}</b> ${t(
              'digitalAssets.single_delete_usage_error_line2',
            )} ${capitalizeFirstLetter(
              t('digitalAssets.single_delete_usage_error_line3', {
                usage: `<b>${capitalizeFirstLetter(
                  t('digitalAssets.usage'),
                )}</b>`,
              }),
            )}`,
          }),
        );
      }
    },

    actionButtons: [
      {
        text: capitalizeFirstLetter(t('global.no_cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(`${t('global.yes_delete')}`),
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  };

  return (
    <>
      <ListingCard
        id={id}
        module="digitalAsset"
        data={asset}
        assetType={thumbnailType}
        assetUrl={
          thumbnailType === 'document'
            ? pdfIcon
            : thumbnailType === 'video'
            ? getThumbnail(asset.url.replace(/(mp4)|(mov)|(mvp)/g, 'png'), 230)
            : getThumbnail(asset.url, 230)
        }
        type={asset.format}
        title={asset.title}
        isSelected={isSelected}
        showDeleteIcon={showDeleteIcon}
        showEditIcon={showEditOption}
        disableRedirect={disableRedirect}
        redirectTo={`${ROUTES.digitalAssets.base}/${asset.id}`}
        isSelectable
        onChange={onChange}
        onDeleteClick={confirmationModal.openModal}
      />
      <ConfirmationModal
        setShowModal={closeModal}
        showModal={confirmationModal.modalId === showModalId}
        title={confirmationModal.title}
        statement={confirmationModal.confirmationStatement}
        buttons={confirmationModal.actionButtons}
        clickHandler={confirmationModal.clickHandler}
        isDanger
      />
    </>
  );
};

export default DigitalAssetCard;
