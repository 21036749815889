/* eslint-disable no-debugger */
import React, { useEffect } from 'react';

import { TOAST_AUTOCLOSE_DEFAULT_TIME } from '@constants/toast';
import { toast } from './ToastManager';

import './Toast.scss';

export interface ToastNotificationProps {
  id?: string;
  message: string;
  position?: string;
  error?: boolean;
}

const positionClasses: any = {
  'top-start': 'top-0 start-0',
  'top-center': 'top-0 start-50 translate-middle-x',
  'top-end': 'top-0 end-0',
  'middle-start': 'top-50 start-0 translate-middle-y',
  'middle-center': 'top-50 start-50 translate-middle',
  'middle-end': 'top-50 end-0 translate-middle-y',
  'bottom-start': 'bottom-0 start-0',
  'bottom-center': 'bottom-0 start-50 translate-middle-x',
  'bottom-end': 'bottom-0 end-0',
};

export const ToastNotification: React.FC<ToastNotificationProps> = ({
  position = 'bottom-center',
  message,
  error = false,
}) => {
  const [show, setShow] = React.useState(true);

  const destroyToast = () => {
    setShow(false);
    toast.destroy();
  };

  useEffect(() => {
    setTimeout(() => {
      destroyToast();
    }, TOAST_AUTOCLOSE_DEFAULT_TIME);
  }, [TOAST_AUTOCLOSE_DEFAULT_TIME]);
  return show ? (
    <div
      className={`ToastNotification ${positionClasses[position]} ${
        error ? 'danger' : ''
      }`}
    >
      {message}
    </div>
  ) : (
    <></>
  );
};
