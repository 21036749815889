export const colors = {
  white: '#FFFFFF',
  oxfordBlue1: '#E8E9EB',
  oxfordBlue2: '#D0D2D7',
  oxfordBlue5: '#8A8F9C',
  dardOxfordBlue: '#141E38',
  darkblue: '#4288E8',
  lightBlue: '#ECF3FD',
  emerald: '#01DD86',
  emerald2: '#CCF8E7',
  lightEmerald: '#E6FCF3',
  lightSalmonDarkShade: '#FF966C',
  manatte: {
    shade1: '#F4F6F7',
  },
  capri: {
    shade1: '#E7F9FF',
    shade9: '#CEF3FF',
    shade10: '#0BC4FF',
  },
  turquoise: {
    shade1: '#E9FBF9',
    shade10: '#1ED7C7',
  },
  oxfordBlue: {
    shade9: '#2C354C',
    shade10: '#D9E7FA',
  },
  brightYellowCrayola: {
    shade1: '#FFF7E8',
    shade10: '#FFAF1D',
  },
  sizzlingRed: {
    shade1: '#FEEDEF',
    shade10: '#F3475C',
  },
};
