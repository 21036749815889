import { FC } from 'react';

import CreateButton from '../CreateButton';

import './AddButtonWithSeparator.scss';

interface IAddButtonWithSeparator {
  onClick: () => void;
  className?: string;
}

const AddButtonWithSeparator: FC<IAddButtonWithSeparator> = ({
  onClick,
  className = '',
}) => {
  return (
    <div className={`AddButtonWithSeparator ${className}`}>
      <CreateButton onClick={onClick} />
    </div>
  );
};

export default AddButtonWithSeparator;
