import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IRichContent, ITemplate } from '@models/rich-content';
import { capitalizeFirstLetter } from '@utils/textTransform';

import PrimaryButton from '../PrimaryButton';
import HeaderFooterModalLayout from '../Modals/HeaderFooterModalLayout';
import TemplateMainContent from '../../pages/rich-contents/[id]/template/+page';

interface IRichContentPreviewModal {
  showModal: boolean;
  closeModal: Function;
  richContentData: IRichContent;
}

const RichContentPreviewModal: FC<IRichContentPreviewModal> = ({
  showModal,
  closeModal,
  richContentData,
}) => {
  const { t } = useTranslation();
  const [templateData, setTemplateData] = useState<ITemplate[]>([]);

  return (
    <HeaderFooterModalLayout
      onClose={closeModal}
      showModal={showModal}
      setShowModal={closeModal}
      header={
        <header className="CVRichContent__modal--header">
          <h2 className="CVRichContent__modal--header-title ">
            {`${capitalizeFirstLetter(t('product_version.content_for'))} ${
              richContentData?.title
            }`}
          </h2>
        </header>
      }
      footer={
        <footer className="CVRichContent__modal--footer">
          <PrimaryButton
            label={capitalizeFirstLetter(t('global.close'))}
            onClick={() => closeModal()}
            variant="green"
            size="small"
          />
        </footer>
      }
      body={
        <div className="CVRichContent__modal--template">
          <TemplateMainContent
            templateData={templateData}
            setTemplateData={setTemplateData}
            contentList={richContentData?.contentList || []}
            permissions={{
              AUTHOR_CONTENT: false,
              VISUAL_ASSETS: false,
              REVIEW: false,
            }}
          />
        </div>
      }
    />
  );
};

export default RichContentPreviewModal;
