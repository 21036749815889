import React from 'react';

import './style.scss';

interface ISkeletonText {
  className?: string;
  color?: 'emerald' | 'default' | 'oxford-blue';
  style?: React.CSSProperties;
}

const SkeletonText: React.FC<ISkeletonText> = ({
  className,
  color = 'default',
  style,
}) => {
  return (
    <div
      className={`SkeletonText SkeletonText__${color} ${className || ''}`}
      {...(style && { style })}
    />
  );
};

export const SkeletonSpan: React.FC<ISkeletonText> = ({
  className,
  color = 'default',
  style,
}) => (
  <span
    className={`SkeletonText SkeletonText__${color} ${className || ''}`}
    {...(style && { style })}
  />
);

export default SkeletonText;
