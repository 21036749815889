import { DetailedHTMLProps, HTMLAttributes } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { DateRange } from '@models/rangeDatePicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

interface RangePickerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  startDate?: DateRange['startDate'];
  endDate?: DateRange['endDate'];
  setRange?: any;
  minDate?: Date;
}

const RangePicker = ({
  startDate,
  endDate,
  minDate,
  setRange,
}: RangePickerProps) => {
  /**
   * Triggers on change of date
   * If @param startDate is already selected, set @param endDate
   * Otherwise nullify @param endDate and set @param startDate
   * */
  const onDateChange = (e: [Date | null, Date | null]) => {
    if (e[1]) {
      setRange(e[1], 'end');
    } else {
      setRange(e[0], 'start');
      setRange(null, 'end');
    }
  };

  // current utc date
  const currentDate = moment().utc().toDate();
  return (
    <div className="RangeDatePickerNoBorder-picker--range">
      <DatePicker
        selected={startDate}
        focusSelectedMonth={false}
        startDate={startDate}
        endDate={endDate}
        monthsShown={2}
        minDate={minDate}
        selectsRange
        inline
        disabledKeyboardNavigation
        onChange={onDateChange}
        maxDate={currentDate}
        calendarClassName="border-0"
        useWeekdaysShort
      />
    </div>
  );
};
export default RangePicker;
