import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { capitalizeFirstLetter } from '@utils/textTransform';
import { ROUTES } from '@constants/routes';

import { SidebarItem } from '../NavItems';
import ConfirmationModal, { ActionButtonProps } from '../ConfirmationModal';
import useLegacyModal from '../ModalLayout/useModal';
import CustomToolTip from '../Tooltip';
import { UserIcon } from '../Icons/UserIcon';

import avatarIcon from '@assets/icons/24_avatar.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/25_logout.svg';

import './style.scss';

interface SubSidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
  toggle: boolean;
  children?: any;
}

const SubSidebarItem: React.FC<SubSidebarItemProps> = ({
  children,
  toggle,
  className,
  ...props
}) => {
  return (
    <div
      className={`SubSidebarItem ${
        !toggle ? 'SubSidebarItem__toggled' : ''
      } ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export const MyAccountNavButton: React.FC<{ toggle: boolean }> = ({
  toggle,
}) => {
  const { t } = useTranslation();
  const logoutModalId = 'logout-modal';
  const { myAccount } = ROUTES;
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const { keyCloak } = useSelector((state) => state.keyCloak);
  const user = useSelector((state) => state.currentUser.data);

  const logoutModal = {
    modalId: logoutModalId,
    title: `${capitalizeFirstLetter(t('myAccount.logout'))}?`,
    confirmationStatement: `${capitalizeFirstLetter(
      t('myAccount.logout_confirmation'),
    )} ${t('global.heuristic_commerce')}?`,
    clickHandler: keyCloak.logout,
    actionButtons: [
      {
        text: `${capitalizeFirstLetter(t('global.no_cancel'))}`,
        color: 'gray',
        type: 'cancel',
      },
      {
        text: `${capitalizeFirstLetter(t('global.yes'))}, ${t(
          'myAccount.logout',
        )}`,
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  };
  return (
    <div className="MyAccountNavButton">
      <div id="user-image">
        <SidebarItem active={false} show={true}>
          <img
            src={user && user.avatarUrl ? user.avatarUrl : avatarIcon}
            alt="profile"
            className="MyAccountNavButton__user"
          />
          <div className="MyAccountNavButton__text">
            <span>
              {user
                ? `${user.firstName} ${user.lastName}`
                : capitalizeFirstLetter(t('global.loading'))}
            </span>
          </div>
        </SidebarItem>
      </div>
      <div id="user-options">
        <CustomToolTip
          id="my-account-tooltip"
          tipText={capitalizeFirstLetter(t('myAccount.title'))}
          hideToolTip={toggle}
        >
          <Link to={myAccount.base} id="my-account-route">
            <SubSidebarItem
              className="MyAccountNavButton-userIcon mb-1"
              toggle={toggle}
            >
              <div>
                <UserIcon />
              </div>
              <div>
                <span className="MyAccountNavButton-title">
                  {capitalizeFirstLetter(t('myAccount.title'))}
                </span>
              </div>
            </SubSidebarItem>
          </Link>
        </CustomToolTip>
        <CustomToolTip
          id="myAccount-logout"
          tipText={capitalizeFirstLetter(t('myAccount.logout'))}
          hideToolTip={toggle}
        >
          <SubSidebarItem
            className="MyAccountNavButton-logoutIcon"
            toggle={toggle}
            onClick={() => {
              openModal(logoutModalId);
            }}
          >
            <div>
              <LogoutIcon />
            </div>
            <div>
              <button className="MyAccountNavButton__logout">
                {capitalizeFirstLetter(t('myAccount.logout'))}
              </button>
            </div>
          </SubSidebarItem>
        </CustomToolTip>

        <ConfirmationModal
          showModal={logoutModal?.modalId === showModalId}
          setShowModal={closeModal}
          isDanger
          title={logoutModal?.title}
          statement={logoutModal?.confirmationStatement}
          buttons={logoutModal?.actionButtons}
          clickHandler={logoutModal.clickHandler}
        />
      </div>
    </div>
  );
};
