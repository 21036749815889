import React from 'react';

export const DropdownIcon = ({
  height = '24px',
  width = '24px',
  fill = 'var(--oxford-blue-shade-4)',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6 8.62539L18 10.0254L12 16.0254L6 10.0254L7.4 8.62539L12 13.2254L16.6 8.62539Z"
      fill={fill}
    />
  </svg>
);
