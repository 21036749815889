import React from 'react';

export const VideoPlaceholder = ({
  width = '60px',
  height = '60px',
  fill = 'var(--oxford-blue-shade-1)',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.3333 12.6667H12.6666C10.2833 12.6667 8.33325 14.6167 8.33325 17.0001V43.0001C8.33325 45.3834 10.2833 47.3334 12.6666 47.3334H47.3333C49.7166 47.3334 51.6666 45.3834 51.6666 43.0001V17.0001C51.6666 14.6167 49.7166 12.6667 47.3333 12.6667ZM24.5833 39.7501V20.2501L39.7499 30.0001L24.5833 39.7501Z"
      fill="#34E49E"
    />
  </svg>
);
