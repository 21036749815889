/**
 * @description Contextual Version Page Heading Component used in editor in overview page
 * @param heading
 * @param type : [type1 ,type2]
 */
import React from 'react';
import './style.scss';

interface IWorkflowHeading {
  heading: string;
  subHeading: string;
  type?: 'type1' | 'type2';
  className?: string;
}

const WorkflowHeading: React.FC<IWorkflowHeading> = ({
  heading,
  subHeading,
  type = 'type1',
  className = '',
}) => {
  // Contextual Version Heading
  const HeadingComponent = () => (
    <h3 className="WorkflowHeading__heading">{heading}</h3>
  );
  // Heading Component with Module
  const HeadingComponentWithModule = () => (
    <div className={`WorkflowHeading ${className}`}>
      <>
        {type === 'type1' && <HeadingComponent />}
        <h5 className="WorkflowHeading__subHeading">{subHeading}</h5>
        {type === 'type2' && <HeadingComponent />}
      </>
    </div>
  );

  return <HeadingComponentWithModule />;
};

export default WorkflowHeading;
