import React from 'react';
import { useTranslation } from 'react-i18next';
import SecondarySidebar from '../../layouts/ApplicationLayout/SideBar/SecondarySidebar';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import {
  ListConfiguration,
  ListableActions,
  ListableState,
} from '@models/filter';

import SortMenuItem from './SortMenuItem';
import ProductFilterItem from './FilterItem';

type FilterSidebarProps<T extends ListConfiguration> = {
  /** Used to get the translation of the values */
  filterNamespace: string;
  listableConfiguration: DeepReadonly<T>;
  listableState: DeepReadonly<ListableState<unknown, T>>;
  listableAction: ListableActions<T>;
  /** Any filters which should not be modifiable by the users */
  fixedFilters?: Array<T['filters'][number]['name']>;
};

const FilterSidebar = <T extends ListConfiguration>({
  filterNamespace,
  listableState,
  listableConfiguration,
  listableAction,
  fixedFilters,
}: FilterSidebarProps<T>): React.ReactElement<FilterSidebarProps<T>> => {
  const { t } = useTranslation();

  const filters = listableConfiguration.filters.filter((f) => !f.hidden);

  return (
    <SecondarySidebar id="productsFilter" sidebarText="filter">
      <div className="mt-2" aria-hidden>
        <div className="pl-1 pt-2 pb-2">
          <div className="filter-sidebar__top-row">
            <span className="products-filterText">
              {t('global.filter').toUpperCase()}
            </span>
          </div>
          <p className="filter-sidebar__title mb-0">
            {capitalizeEveryFirstLetter(
              `${t('global.all')} ${t(filterNamespace + '.title')}`,
            )}
          </p>
        </div>
        <SortMenuItem
          filterNamespace={filterNamespace}
          listableState={listableState}
          listableConfiguration={listableConfiguration}
          listableAction={listableAction}
        ></SortMenuItem>
        {filters.map((filter) => (
          <ProductFilterItem<T>
            filter={filter as DeepReadonly<T['filters'][number]>}
            filterNamespace={filterNamespace}
            listableState={listableState}
            listableAction={listableAction}
            key={filter.name}
            fixed={fixedFilters?.includes(filter.name)}
          />
        ))}
        <br />
      </div>
    </SecondarySidebar>
  );
};

export default FilterSidebar;
