import React from 'react';

import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import RangeDatePickerWithoutBorder from '@components/RangeDatePickerWithoutBorder';

import './PageHeading.scss';

type PageHeadingProps = {
  title: string;
  children?: any;
  className?: string;
  datePickerState?: boolean;
  togglePickerState?: () => void | null;
};

export const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  children,
  className = '',
  datePickerState,
  togglePickerState,
}) => {
  return (
    <>
      <div className={`PageHeading ${className}`}>
        <span className="PageHeading-title">
          {capitalizeEveryFirstLetter(title)}
        </span>
        <div className="PageHeading-children">
          {children}
          {togglePickerState && (
            <RangeDatePickerWithoutBorder
              datePickerState={datePickerState}
              togglePickerState={togglePickerState}
              className="PageHeading-datepicker"
              module="Dashboard"
            />
          )}
        </div>
      </div>
    </>
  );
};
