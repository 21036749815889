import React from 'react';

import './RadioButton.scss';

const RadioButton: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  checked,
  ...props
}) => {
  return (
    <>
      <input
        type="radio"
        className={`RadioButton ${checked ? 'RadioButton__is-checked' : ''} ${
          className || ''
        }`}
        checked={checked}
        {...props}
      />
    </>
  );
};

export default RadioButton;
