import React from 'react';
import { ModalProps } from '@models/modal';
import ModalLayout from '../../ModalLayout';

import './style.scss';

export interface HeaderFooterModalLayoutProps extends ModalProps {
  header?: React.ReactElement;
  body: React.ReactElement;
  bodyId?: string;
  footer?: React.ReactElement;
  isFullScreen?: boolean;
  noBodyPadding?: boolean;
  quarterScreenModal?: boolean;
}

const HeaderFooterModalLayout: React.FC<HeaderFooterModalLayoutProps> = ({
  showModal,
  setShowModal,
  onClose,
  isFullScreen = false,
  header,
  body,
  bodyId,
  footer,
  noBodyPadding = false,
}) => {
  const modalClassName = isFullScreen
    ? 'fullScreenModal'
    : 'semiFullScreenModal';
  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={onClose}
      defaultClose
      className={`${modalClassName}`}
    >
      {header ? (
        <header className={`${modalClassName}__header`}>{header}</header>
      ) : null}
      <div
        className={`${modalClassName}__body ${noBodyPadding ? 'p-0' : ''}`}
        {...(bodyId && { id: bodyId })}
      >
        {body}
      </div>
      {footer ? (
        <footer className={`${modalClassName}__footer`}>{footer}</footer>
      ) : null}
    </ModalLayout>
  );
};

export default HeaderFooterModalLayout;
