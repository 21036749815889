import React from 'react';
import ModalLayout from '../ModalLayout';

import './ReadOnlyModal.scss';

interface ReadOnlyModalInterface {
  showModal: boolean;
  setShowModal: Function;
  modalHeader?: JSX.Element;
  modalBody: JSX.Element;
  viewType?: 'landscape' | 'portrait';
  bodyClass?: string;
  showCloseIcon?: boolean;
}

const ReadOnlyModal: React.FC<ReadOnlyModalInterface> = ({
  showModal,
  setShowModal,
  modalHeader,
  modalBody,
  viewType = 'landscape',
  bodyClass = '',
  showCloseIcon = true,
}) => {
  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      defaultClose={showCloseIcon}
      className={`ReadOnlyModal ReadOnlyModal__${viewType}`}
    >
      <div className={`ReadOnlyModal__${viewType}-header`}>{modalHeader}</div>
      <div className={`ReadOnlyModal__${viewType}-body ${bodyClass}`}>
        {modalBody}
      </div>
    </ModalLayout>
  );
};

export default ReadOnlyModal;
